import React from "react";
import { IBaseWidgetClassNames, IconSize, PageContainer, RouteWidget, SelectListGroup, SelectListItem, WidgetContainer} from "@renta-apps/athenaeum-react-components";
import {BasePageParameters, ch} from "@renta-apps/athenaeum-react-common";
import UserContext from "@/models/server/UserContext";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import BaseRole from "@/models/server/BaseRole";
import NewsCarousel from "@/components/NewsCarousel/NewsCarousel";
import {NewsItem} from "@/models/server/NewsItem";
import FleetInfoResponse from "@/models/server/Responses/FleetInfoResponse";
import FleetInfoRequest from "@/models/server/Requests/FleetInfoRequest";
import AnonymousPage from "@/models/base/AnonymousPage";
import PageDefinitions from "@/providers/PageDefinitions";
import FleetInfoWidget, { IFleetWidgetData } from "../../components/FleetInfoWidget/FleetInfoWidget";
import CustomerSitesWidget, {ICustomerSitesWidgetData} from "@/components/CustomerSitesInfoWidget/CustomerSitesWidget";
import ConstructionSitesPageParameters from "@/models/ConstructionSitesPageParameters";
import Localizer from "@/localization/Localizer";

import styles from "./Frontpage.module.scss";
import RentaEasyController from "@/pages/RentaEasyController";
import {FeatureSwitch} from "@/providers/FeatureSwitch";

export interface IFrontpageParams extends BasePageParameters {
}

interface IFrontpageState {
    newsItems: NewsItem[];
    showServicesButton: boolean;
}

interface IConstructionSiteInfo {
    constructionSitesCount: string;
    constructionSitesOpen: string;
}

export default class Frontpage extends AnonymousPage<IFrontpageParams, IFrontpageState> {

    public state: IFrontpageState = {
        newsItems: [
            // To make the widget take it's place before data is loaded
            {
                id: null,
                title: "",
                description: "",
                enabled: false,
                file: null,
                priority: -1,
            }
        ],
        showServicesButton: true,
    };

    private _roles: BaseRole[] | null = null;

    private get roleItems(): BaseRole[] {

        if (!this._roles) {

            const isPrivateUser: boolean = (!this.userContext.selectedContractId) && (!this.userContext.selectedConstructionSiteId);

            const privateUser: BaseRole = {
                id: RentaEasyConstants.emptyGuid,
                group: Localizer.privatePersonLanguageItemName,
                entityName: Localizer.privatePersonLanguageItemName,
                entityAdditionalName: null,
                roleName: Localizer.privatePersonLanguageItemName,
                isSelected: isPrivateUser,
                isBaseRole: true,
                easyPlusValidTo: null,
                easyPlusEnabled: false,
                easyPlusInvited: false,
                easyPlusValidFrom: null,
                easyPlusInvoiceReference: null,
            };

            const companies: BaseRole[] = this.userContext.user!.organizationRoles
                .map(
                    item => {
                        const companyRole = new BaseRole();
                        companyRole.id = item.contractId;
                        companyRole.entityAdditionalName = item.contract!.additionalName;
                        companyRole.entityName = item.contract!.name || "";
                        companyRole.roleName = item.roleName;
                        companyRole.group = Localizer.rolesGroupCompany;
                        companyRole.isSelected = !!this.userContext.selectedContractId && this.userContext.selectedContractId === item.id;
                        companyRole.easyPlusValidFrom = item.easyPlusValidFrom;
                        companyRole.easyPlusValidTo = item.easyPlusValidTo;
                        companyRole.easyPlusEnabled = item.easyPlusEnabled;
                        return companyRole;
                    }
                );

            const sites: BaseRole[] = this.userContext.user!.constructionSiteRoles.map(
                item => {
                    const siteRole = new BaseRole();
                    siteRole.id = item.constructionSiteId;
                    siteRole.entityAdditionalName = item.constructionSiteExternalReference;
                    siteRole.entityName = item.constructionSiteName || "";
                    siteRole.roleName = item.roleName;
                    siteRole.isSelected = !!this.userContext.selectedConstructionSiteId && this.userContext.selectedConstructionSiteId === item.id;
                    siteRole.group = Localizer.rolesGroupConstructionSite;
                    siteRole.easyPlusValidFrom = item.easyPlusValidFrom;
                    siteRole.easyPlusValidTo = item.easyPlusValidTo;
                    siteRole.easyPlusEnabled = item.easyPlusEnabled;
                    return siteRole;
                }
            );

            this._roles = [privateUser, ...companies, ...sites];

        }

        return this._roles;
    }

    private get roleListItems(): SelectListItem[] {
        return this.roleItems.map((baseRole: BaseRole): SelectListItem => {
            const name: string | null = baseRole.entityAdditionalName
                ? `${baseRole.entityName} (${baseRole.entityAdditionalName})`
                : baseRole.entityName;
            const listItem: SelectListItem = new SelectListItem(baseRole.id, name, baseRole.roleName);
            listItem.group = SelectListGroup.create(baseRole.entityName!);

            if (baseRole.entityName === Localizer.privatePersonLanguageItemName) {
                listItem.group.order = 999;
            }

            return listItem;
        });
    }

    private get selectedSiteOrContractName(): string {
        const userContext = ch.getContext() as UserContext;

        if (userContext.selectedContractName) {
            return userContext.selectedContractName;
        }

        if (userContext.selectedConstructionSiteName) {
            return userContext.selectedConstructionSiteName;
        }

        return Localizer.privatePerson;
    }

    private get hasFavoriteProducts(): boolean {
        return (this.isLoggedIn)
            && (this.userContext.user!.favoriteProductsIds)
            && (this.userContext.user!.favoriteProductsIds.length > 0);
    }

    static get linkWidgetClassNames(): IBaseWidgetClassNames {
        return {
            description: styles.linkWidgetDescription
        };
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    public get isLoggedIn(): boolean {
        return !!this.userContext.user;
    }

    public get isConstructionSiteMainUser(): boolean {
        return this.userContext.isConstructionSiteMainUser;
    }

    public get isCompanyMainUser(): boolean {
        return this.userContext.isCompanyMainUser;
    }

    public get hasCompanyOrSiteRoles(): boolean {
        return this.userContext.user!.organizationRoles.length > 0
            || this.userContext.user!.constructionSiteRoles.length > 0;
    }

    public get isPrivateUser(): boolean {
        return this.userContext.isPrivateUser;
    }

    public get isConstructionSiteUser(): boolean {
        return (this.userContext.isConstructionSiteUser || this.userContext.isSingleConstructionSiteMainUserUser);
    }

    public get contractPricesAreShown(): boolean {
        return (!!this.userContext.temporaryConstructionSiteIdForPrices || !!this.userContext.temporaryConstructionSiteIdForPrices);
    }

    private get showPrices(): boolean {
        return (
            RentaEasyController.constructionSitePricesEnabledForEndUsers()
            && this.isConstructionSiteUser
        );
    }

    private selectedConstructionSiteContractId(isMasterContract: boolean): ConstructionSitesPageParameters {
        return {
            contractId: this.userContext.selectedContractId ?? "",
            isMasterContract: isMasterContract,
            selectedChildContractId: this.userContext.selectedContractId,
        };
    }

    private async getCustomerSitesInfoAsync(): Promise<ICustomerSitesWidgetData> {

        if (this.isConstructionSiteMainUser || this.isCompanyMainUser) {
            const contractId: string = this.userContext.selectedContractId!;

            const json: IConstructionSiteInfo = await this.postAsync("/api/Companies/CustomerConstructionSitesInfo", contractId);

            return {
                selectedSiteOrContractName: this.selectedSiteOrContractName,
                constructionSitesCount: json.constructionSitesCount,
                constructionSitesOpen: json.constructionSitesOpen
            } as ICustomerSitesWidgetData;
        }
        else {
            return {} as ICustomerSitesWidgetData;
        }
    }

    private async getFleetInfoAsync(): Promise<IFleetWidgetData> {

        if (!this.isPrivateUser) {
            const request: FleetInfoRequest = this.userContext.isAdmin
                ? {
                    constructionSiteId: null,
                    contractId: null,
                }
                : {
                    constructionSiteId: this.userContext.selectedConstructionSiteId,
                    contractId: this.userContext.selectedContractId,
                };
            const response: FleetInfoResponse = await this.postAsync("/api/Fleet/ReadFleetInfo", request);

            if (response) {
                return {
                    equipmentsMaxIdleInDays: response.equipmentsMaxIdleInDays,
                    alarmCountSinceYesterday: response.alarmCountSinceYesterday,
                } as IFleetWidgetData;
            }
        }
        return {} as IFleetWidgetData;
    }

    protected get title(): string {
        return Localizer.frontPageText;
    }

    private renderRentLink(): React.ReactNode {
        return (
            <RouteWidget minimized wide
                         id={"rent_widget"}
                         classNames={Frontpage.linkWidgetClassNames}
                         route={PageDefinitions.rent.route()}
                         label={Localizer.rentButtonText}
                         description={Localizer.rentButtonDescription}
                         icon={{name: "handshake", size: IconSize.X3}}
            />
        );
    }

    private renderFleetMonitoringLink(): React.ReactNode {
        return (
            <FeatureSwitch flagName={RentaEasyConstants.featureFlagFleetMonitoring}>
                <FleetInfoWidget
                    route={PageDefinitions.fleetMonitoringPage.route()}
                    fetchData={async () => await this.getFleetInfoAsync()}
                />
            </FeatureSwitch>
        );
    }

    private renderFavoriteLink(): React.ReactNode {
        return (
            <RouteWidget minimized wide
                         id={"favorite_widget"}
                         classNames={Frontpage.linkWidgetClassNames}
                         route={PageDefinitions.rent.route({id: RentaEasyConstants.favoritePageId})}
                         label={Localizer.genericFavorite}
                         description={Localizer.genericFavoriteDescription}
                         icon={{name: "star", size: IconSize.X3}}
            />
        );
    }

    private scrollToBottom(): void {
        if (this.mobile) {
            this.JQuery("html").animate({scrollTop: 10000}, 100);
        }
    }

    private renderRoleWidget(): React.ReactNode {
        return (
            <WidgetContainer className={styles.WidgetContainer}>
                <RouteWidget minimized wide
                             id={"selected_role_widget"}
                             icon={{name: "user-helmet-safety",  size: IconSize.X3}}
                             classNames={Frontpage.linkWidgetClassNames}
                             route={PageDefinitions.selectCompany.route()}
                             label={this.selectedSiteOrContractName}
                />
            </WidgetContainer>
        );
    }

    private renderDepotsLink(): React.ReactNode {
        return (
            <RouteWidget minimized wide
                         id={"depot_widget"}
                         classNames={Frontpage.linkWidgetClassNames}
                         route={PageDefinitions.depots.route()}
                         label={Localizer.indexFindClosestOfficeText}
                         icon={{name: "map-marked", size: IconSize.X3}}
            />
        );
    }

    private renderApplyCompanyAccessLink(): React.ReactNode {
        return (
            <RouteWidget minimized wide
                         id={"apply_company_access_widget"}
                         classNames={Frontpage.linkWidgetClassNames}
                         route={PageDefinitions.applyCompanyAccount.route()}
                         label={Localizer.applyCompanyAccountText}
                         icon={{name: "building", size: IconSize.X3}}
            />
        );
    }

    private renderServicesLink(): React.ReactNode {
        if (!this.state.showServicesButton) {
            return;
        }

        return (
            <FeatureSwitch flagName={RentaEasyConstants.featureFlagCustomServices}>
                <RouteWidget minimized wide
                             id={"service_widget"}
                             classNames={Frontpage.linkWidgetClassNames}
                             route={PageDefinitions.services.route()}
                             label={Localizer.breadCrumbServices}
                             icon={{name: "cogs", size: IconSize.X3}}
                />
            </FeatureSwitch>

        );
    }

    private renderSalesToolsLink(): React.ReactNode {

        if (!this.isAdmin) {
            return;
        }

        return (
            <FeatureSwitch flagName={RentaEasyConstants.featureFlagSalesToolsFrontPageLink}>
                <RouteWidget minimized wide
                             classNames={Frontpage.linkWidgetClassNames}
                             route={PageDefinitions.salesTools.route()}
                             label={Localizer.breadCrumbSalesTools}
                             icon={{name: "calendar", size: IconSize.X3}}
                />
            </FeatureSwitch>
        );
    }

    private renderAdminLink(): React.ReactNode {
        return (
            <RouteWidget minimized wide
                         id={"admin_widget"}
                         classNames={Frontpage.linkWidgetClassNames}
                         route={PageDefinitions.admin.route()}
                         label={this.userContext.selectedContractName!}
                         icon={{name: "tools", size: IconSize.X3}}
            />
        );
    }

    private renderCompanyManagementLink(): React.ReactNode {
        return (
            <RouteWidget minimized wide
                         classNames={Frontpage.linkWidgetClassNames}
                         route={PageDefinitions.contractDetails.route({params: {id: this.userContext.selectedContractId!}})}
                         label={`${Localizer.frontPageCompanyManagement}: ${this.userContext.selectedContractName!}`}
                         icon={{name: "building", size: IconSize.X3}}
            />
        );
    }

    private renderRegisterLoginLinks(): React.ReactNode {
        return (
            <React.Fragment>
                <RouteWidget minimized wide
                             classNames={Frontpage.linkWidgetClassNames}
                             id={"login_widget"}
                             route={PageDefinitions.login.route()}
                             label={Localizer.loginText}
                             icon={{name: "sign-in-alt", size: IconSize.X3}}

                />

                <RouteWidget minimized wide
                             classNames={Frontpage.linkWidgetClassNames}
                             id={"register_widget"}
                             route={PageDefinitions.register.route()}
                             label={Localizer.createUserText}
                             icon={{name: "user-plus", size: IconSize.X3}}

                />
            </React.Fragment>
        );
    }

    private renderMyAccountLink(): React.ReactNode {
        return (
            <RouteWidget wide minimized
                         classNames={Frontpage.linkWidgetClassNames}
                         route={PageDefinitions.myAccount.route()}
                         label={Localizer.indexManageYourSettingsText}
                         icon={{name: "user", size: IconSize.X3}}
            />
        );
    }

    private renderConstructionSitesForConstructionSiteMainUserLink(): React.ReactNode {
        return (
            <CustomerSitesWidget
                route={PageDefinitions.constructionSites.route({params: this.selectedConstructionSiteContractId(false)})}
                fetchData={async () => await this.getCustomerSitesInfoAsync()}
            />

        );
    }

    private renderConstructionSitesForCompanyMainUserLink(): React.ReactNode {
        return (
            <CustomerSitesWidget
                route={PageDefinitions.constructionSites.route({params: this.selectedConstructionSiteContractId(true)})}
                fetchData={async () => await this.getCustomerSitesInfoAsync()}
            />

        );
    }

    private renderConstructionSiteLink(): React.ReactNode {
        return (
            <RouteWidget wide
                         classNames={Frontpage.linkWidgetClassNames}
                         route={PageDefinitions.constructionSite.route({params: {id: this.userContext.selectedConstructionSiteId!}})}
                         label={`${Localizer.inventoryManagementText} ${this.userContext.selectedConstructionSiteName!}`}
                         icon={{name: "tools", size: IconSize.X3}}
            />
        );
    }

    private renderPrivateOrdersLink(): React.ReactNode {
        return (
            <RouteWidget wide minimized
                         id={"private_orders_widget"}
                         classNames={Frontpage.linkWidgetClassNames}
                         route={PageDefinitions.privateOrders.route()}
                         label={Localizer.inventoryManagementPrivateUserText}
                         icon={{name: "tools", size: IconSize.X3}}
            />
        );
    }

    private renderCompanyMainUserLinks(): React.ReactNode {
        return (
            <React.Fragment>
                {this.renderRentLink()}
                {this.renderFleetMonitoringLink()}
                {(this.hasFavoriteProducts) && (this.renderFavoriteLink())}
                {this.renderConstructionSitesForCompanyMainUserLink()}
                {this.renderCompanyManagementLink()}
                {this.renderServicesLink()}
                {this.renderMyAccountLink()}
                {this.renderDepotsLink()}
                {this.renderRoleWidget()}
            </React.Fragment>
        );
    }

    private renderConstructionSiteMainUserLinks(): React.ReactNode {
        return (
            <React.Fragment>
                {this.renderRentLink()}
                {this.renderFleetMonitoringLink()}
                {(this.hasFavoriteProducts) && (this.renderFavoriteLink())}
                {this.renderConstructionSitesForConstructionSiteMainUserLink()}
                {this.renderServicesLink()}
                {this.renderMyAccountLink()}
                {this.renderDepotsLink()}
                {this.renderRoleWidget()}
            </React.Fragment>
        );
    }

    private renderConstructionUserLinks(): React.ReactNode {
        return (
            <React.Fragment>
                {this.renderRentLink()}
                {this.renderFleetMonitoringLink()}
                {(this.hasFavoriteProducts) && (this.renderFavoriteLink())}
                {this.renderConstructionSiteLink()}
                {this.renderServicesLink()}
                {this.renderMyAccountLink()}
                {this.renderDepotsLink()}
                {this.renderRoleWidget()}
            </React.Fragment>
        );
    }

    private renderPrivateUserLinks(): React.ReactNode {
        return (
            <React.Fragment>
                {this.renderRentLink()}
                {(this.hasFavoriteProducts) && (this.renderFavoriteLink())}
                {this.renderPrivateOrdersLink()}
                {this.renderServicesLink()}
                {this.renderMyAccountLink()}
                {this.renderDepotsLink()}
                {
                    (this.hasCompanyOrSiteRoles) &&
                    (
                        this.renderRoleWidget()
                    )
                }
                {
                    (!this.hasCompanyOrSiteRoles) &&
                    (
                        this.renderApplyCompanyAccessLink()
                    )
                }
            </React.Fragment>
        );
    }

    private renderAdminLinks(): React.ReactNode {
        return (
            <React.Fragment>
                {this.renderRentLink()}
                {this.renderFleetMonitoringLink()}
                {(this.hasFavoriteProducts) && (this.renderFavoriteLink())}
                {this.renderAdminLink()}
                {this.renderServicesLink()}
                {this.renderSalesToolsLink()}
                {this.renderMyAccountLink()}
                {this.renderDepotsLink()}
                {this.renderRoleWidget()}
            </React.Fragment>
        );
    }

    private renderLinksForNotRegisteredUser(): React.ReactNode {
        return (
            <React.Fragment>
                {this.renderRentLink()}
                {(this.hasFavoriteProducts) && (this.renderFavoriteLink())}
                {this.renderRegisterLoginLinks()}
                {this.renderDepotsLink()}
            </React.Fragment>
        );
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const newsItems: NewsItem[] = await this.postAsync("/api/Application/GetNewsItems");
        await this.setState({newsItems});

        if (this.isLoggedIn && !this.isAdmin) {
            const anyServiceRequestEnabled = await this.getAsync<boolean>("/api/Application/IsAnyCustomServiceRequestEnabled");
            this.setState({showServicesButton: anyServiceRequestEnabled})
        }
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.frontpage} alertClassName={styles.alert} id={"frontpageContainer"}>

                <NewsCarousel className={styles.newsCarousel}
                              newsItems={this.state.newsItems}
                />

                <WidgetContainer className={styles.widgetContainer}>

                    {
                        (!this.isLoggedIn) && this.renderLinksForNotRegisteredUser()
                    }

                    {
                        (this.isLoggedIn) &&
                        (
                            <React.Fragment>
                                {
                                    (this.isAdmin) && this.renderAdminLinks()
                                }
                                {
                                    (this.isPrivateUser) && this.renderPrivateUserLinks()
                                }
                                {
                                    (this.isConstructionSiteUser) && this.renderConstructionUserLinks()
                                }
                                {
                                    (this.isConstructionSiteMainUser) && this.renderConstructionSiteMainUserLinks()
                                }
                                {
                                    (this.isCompanyMainUser) && this.renderCompanyMainUserLinks()
                                }
                            </React.Fragment>
                        )
                    }

                </WidgetContainer>

            </PageContainer>
        );
    }
}