import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import UsersGridToolbarModel from "./UsersGridToolbarModel";
import React from "react";
import {Button, ButtonType, Checkbox, Dropdown, DropdownOrderBy, Form, IconSize, TextInput, ToolbarContainer, ToolbarRow} from "@renta-apps/athenaeum-react-components";
import styles from "./UsersGridToolbar.module.scss";
import Localizer from "@/localization/Localizer";

import RentaEasyController from "@/pages/RentaEasyController";
import PageDefinitions from "@/providers/PageDefinitions";
import DepotModel from "@/models/server/DepotModel";

interface IUsersGridToolbarProps {
    model: UsersGridToolbarModel;
    contractId?: string;
    showDepotFilter: boolean;
    hideRoleFilter?: boolean;
    hideSearch?: boolean;
    hideNotProcessed?: boolean;
    customNothingSelectedText?: string;

    onChange?(model: UsersGridToolbarModel, clear: boolean): Promise<void>;

    downloadUsersCsv(model: UsersGridToolbarModel, selectedContractId: string): Promise<void>;
}

interface IUsersGridToolbarState {
    model: UsersGridToolbarModel;
    depots: DepotModel[];
}

export default class UsersGridToolbar extends BaseComponent<IUsersGridToolbarProps, IUsersGridToolbarState> {
    private timeout: number;

    constructor(props: IUsersGridToolbarProps) {
        super(props);
        this.timeout = 0;
    }

    public state: IUsersGridToolbarState = {
        model: this.props.model,
        depots: []
    };

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        let depots: DepotModel[] = await RentaEasyController.getRentalOfficesAsync(this);

        await this.setState({depots: depots});
    }

    private async processOnChangeAsync(invoke: boolean = false, clear: boolean = false): Promise<void> {
        await this.setState({model: this.state.model});
        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this.state.model, clear);
        }
    }

    private async filterByKeyWordAsync(): Promise<void> {
        await this.processOnChangeAsync(true);
    }

    private async setKeyWordAsync(value: string): Promise<void> {
        this.state.model.keyword = value;
    }

    private async setSelectedDepotAsync(item: string | null): Promise<void> {
        this.state.model.selectedDepotId = item;
        await this.processOnChangeAsync(true);
    }

    private async setSelectedRoleAsync(item: string | null): Promise<void> {
        this.state.model.selectedRoleName = item;
        await this.processOnChangeAsync(true);
    }
    
    private async setNotProcessedAsync(notProcessed: boolean): Promise<void> {
        this.state.model.notProcessedUsers = notProcessed;
        await this.processOnChangeAsync(true);
    }

    private async downloadUsersCsvAsync(selectedContractId: string): Promise<void> {
        await this.props.downloadUsersCsv(this.state.model, selectedContractId);
    }

    public render(): React.ReactNode {

        return (
            <ToolbarContainer className={styles.toolbarContainer}>
                <ToolbarRow>

                    {
                        (this.props.showDepotFilter && this.state.depots) && (
                            <>
                                <Dropdown inline
                                          minWidth={200}
                                          items={this.state.depots}
                                          label={Localizer.shoppingCartPageLocation}
                                          className={styles.searchInputTextBox}
                                          selectedItem={this.state.model.selectedDepotId}
                                          onChange={async (sender, item) => await this.setSelectedDepotAsync(item?.id ?? null)}

                                />
                            </>
                        )
                    }
                    {
                        (!this.props.showDepotFilter) && (

                            <Button label={Localizer.companyDetailsInviteUser}
                                    id={"inviteUserButton"}
                                    className={styles.toolbarButtons}
                                    type={ButtonType.Orange}
                                    route={PageDefinitions.inviteUser.route({params: {contractId: this.props.contractId!}})}
                            />

                        )
                    }
                    <Button label={Localizer.genericDownloadCsv}
                            id={"downloadUsersCsvButton"}
                            icon={{name: "fa fa-download", size: IconSize.Large}}
                            className={styles.toolbarButtons}
                            type={ButtonType.Orange}
                            onClick={async () => await this.downloadUsersCsvAsync(this.props.contractId!)}
                    />
                </ToolbarRow>
                <ToolbarRow>
                    <Form inline submitOnEnter
                          onSubmit={async () => await this.filterByKeyWordAsync()}
                    >

                        <TextInput inline
                                   id={"searchInput"}
                                   width={"200px"}
                                   placeholder={Localizer.searchWordText}
                                   value={this.state.model.keyword}
                                   className={styles.searchInputTextBox}
                                   onChange={async (sender, value) => await this.setKeyWordAsync(value)}
                        />

                        {
                            (!this.props.hideRoleFilter) &&
                            <Dropdown inline
                                      nothingSelectedText={Localizer.adminUserManagementSelectRole}
                                      minWidth={250}
                                      className={styles.companyDropdown}
                                      orderBy={DropdownOrderBy.None}
                                      selectedItem={this.state.model.selectedRoleName}
                                      items={this.state.model.roleNamesDropdown}
                                      onChange={async (sender, item) => await this.setSelectedRoleAsync(item?.value ?? null)}
                            />
                        }

                        {
                            (!this.props.hideSearch) && (
                                <Button submit
                                        id={"submitButton"}
                                        className={styles.searchButton}
                                        label={Localizer.ordersPageSearch}
                                        type={ButtonType.Orange}
                                        icon={{name: "search"}}
                                        onClick={async () => await this.filterByKeyWordAsync()}
                                />
                            )
                        }

                        {
                            (!this.props.hideNotProcessed) &&
                            <Checkbox inline
                                      id={"notProcessedUsers"}
                                      label={Localizer.adminUserManagementFilterNotProcessed}
                                      value={this.state.model.notProcessedUsers}
                                      onChange={async (_, checked) => await this.setNotProcessedAsync(checked)}
                            />
                        }

                    </Form>
                </ToolbarRow>

            </ToolbarContainer>
        );
    }
}