import React, {useEffect, useState} from "react";
import {Button, ButtonType, Checkbox, IconSize, InlineType, Modal, ModalSize} from "@renta-apps/athenaeum-react-components";
import {NewsItem} from "@/models/server/NewsItem";
import NewsCarousel from "@/components/NewsCarousel/NewsCarousel";
import Localizer from "@/localization/Localizer";

import styles from "./NewsEditor.module.scss";
import NewsEditorForm from "@/components/NewsEditor/NewsEditorForm";
import NewsCarouselItem from "@/components/NewsCarousel/NewsCarouselItem";
import {changeNewsItemPriorityAsync, deleteNewsItemAsync, fetchNewsAsync, saveNewsItemAsync} from "@/services/NewsService";
import {CallbackResult, CallbackResultType} from "@/models/types/CallbackResult";

interface INewsEditorProps {
}

const NewsEditor = (props: INewsEditorProps) => {

    const formModalRef = React.createRef<Modal>();

    const [newsItems, setNewsItems] = useState<NewsItem[]>([]);

    const [selectedNewsItem, setSelectedNewsItem] = useState<NewsItem | null>(null);

    const fetchNewsList = () => {
        fetchNewsAsync().then((data) => {
            setNewsItems(data);
        });
    }

    const openAddForm = (index: number, after: boolean = true): void => {
        const item: NewsItem = {
            id: null,
            title: "",
            description: "",
            priority: newsItems.maxValue(item => item.priority) +1,
            enabled: true,
            file: null
        };
        setSelectedNewsItem(item);
        formModalRef.current?.openAsync();
    }

    const openEditForm = (item: NewsItem): void => {
        setSelectedNewsItem(item);
        formModalRef.current?.openAsync();
    }

    const submitNewsForm = async (item: NewsItem): Promise<CallbackResult> => {
        await saveNewsItemAsync(item);
        fetchNewsList();
        await formModalRef.current?.closeAsync();
        setSelectedNewsItem(null);
        return {status: CallbackResultType.SUCCESS, message: ""};
    }

    const deleteNews = async (item: NewsItem): Promise<void> => {
        await deleteNewsItemAsync(item);
        setSelectedNewsItem(null);
        fetchNewsList();
    }

    useEffect(() => {
        fetchNewsList();
    }, []);

    const move = async (item: NewsItem, increase: boolean): Promise<void> => {
        // Call the API to update the item's priority
        await changeNewsItemPriorityAsync(item, increase);

        // Fetch the updated list to refresh the UI
        fetchNewsList();
    }

    const canMoveUp = (item: NewsItem): boolean => {
         return (item.priority !== undefined && (item.priority !== newsItems.minValue(item => item.priority)));
    }

    const canMoveDown = (item: NewsItem): boolean => {
         return (item.priority !== undefined && (item.priority !== newsItems.maxValue(item => item.priority)));
    }

    return (
        <>
            <div className={styles.NewsEditor} data-cy={"newsItemsEditor"}>
                <div data-cy={"newsItemsCarousel"} >
                    <NewsCarousel newsItems={newsItems.filter((item) => item.enabled)} />
                </div>

                <hr/>

                <div className={styles.newsEditorOptions}>
                    <Button type={ButtonType.Orange}
                            className={styles.newsEditorAddNewBtn}
                            label={Localizer.newsEditorAddNew}
                            icon={{name: "plus", size: IconSize.Normal}}
                            onClick={async () => openAddForm(0, false)}
                            id={"newsAddNewButton"}
                    />
                </div>

                <hr/>

                <div data-cy={"newsItemsContainer"} >
                    {newsItems.map((item, index) =>
                        <div className={styles.newsItemContainer} key={index} data-cy={"newsItemContainer"}>
                            <div className={styles.carouselItemContainer}>
                                <NewsCarouselItem newsItem={item} index={index}/>
                            </div>

                            <div className={styles.newsItemOptions}>

                                <div className={styles.prioritySettings}>
                                    {
                                        canMoveUp(item) && (
                                            <Button type={ButtonType.Default}
                                                    icon={{name: "arrow-up", size: IconSize.Normal}}
                                                    onClick={async () => move(item, false)}
                                                    id={"newsItemUpButton"}
                                            />
                                        )
                                    }
                                    <div className={styles.priority}>
                                        {item.priority}
                                    </div>
                                    {
                                        canMoveDown(item) && (
                                            <Button type={ButtonType.Default}
                                                    icon={{name: "arrow-down", size: IconSize.Normal}}
                                                    onClick={async () => move(item, true)}
                                                    disabled={!canMoveDown(item)}
                                                    id={"newsItemDownButton"}
                                            />
                                        )
                                    }

                                </div>
                                <div className={styles.editOptions}>
                                    <Checkbox label={Localizer.newsEditorInputEnabled}
                                              className={styles.checkbox}
                                              value={item.enabled}
                                              readonly
                                              inline
                                              inlineType={InlineType.Right}
                                              id={"newsItemEnabled"}
                                    />

                                    <Button type={ButtonType.Orange}
                                            icon={{name: "edit", size: IconSize.Normal}}
                                            onClick={async () => openEditForm(item)}
                                            id={"newsItemEditButton"}
                                    />

                                    <Button type={ButtonType.Danger}
                                            icon={{name: "trash", size: IconSize.Normal}}
                                            onClick={async () => deleteNews(item)}
                                            confirm={Localizer.newsEditorDeleteConfirm}
                                            id={"newsItemDeleteButton"}
                                    />
                                </div>
                            </div>

                            <hr/>
                        </div>
                    )}
                </div>
            </div>
            <Modal
                   ref={formModalRef}
                   className={styles.newsEditModal}
                   id="newsItemEditFormModal"
                   size={ModalSize.ExtraLarge}
            >
                {selectedNewsItem &&
                    <NewsEditorForm item={selectedNewsItem}
                                    onSubmit={(item: NewsItem) => submitNewsForm(item)}
                                    onCancel={() => {formModalRef.current?.closeAsync(); setSelectedNewsItem(null);}}
                />}
            </Modal>
        </>
    );
}

export default NewsEditor;