import React, {useState, useEffect} from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Brush,
} from 'recharts';
import styles from './RentaLineChart.module.scss';
import {CurveType} from "recharts/types/shape/Curve";
import {NoSwipeNavigation} from "@renta-apps/athenaeum-react-components";

interface LegendItem {
    label: string;
    color: string;
}

export interface ChartData {
    date: Date;
    value: number;
    movingAverage?: number;
}

interface LineConfig {
    dataKey: string;
    dataName: string;
    stroke: string;
    strokeWidth?: number;
    gradientId?: string;
    type?: CurveType | undefined;
}

interface ReusableLineChartProps {
    data: any[];
    xKey: string;
    lines: LineConfig[];
    title?: string;
    subtitle?: string;
    legendItems?: LegendItem[];
    xAxisFormatter?: (value: string) => string;
    tooltipFormatter?: (value: number, name: string, entry: string) => string;
    brush?: boolean;
    containerClassName?: string;
}

const RentaLineChart: React.FC<ReusableLineChartProps> = ({
    data,
    xKey,
    lines,
    title,
    subtitle,
    legendItems = [],
    xAxisFormatter,
    brush = false,
    containerClassName,
}) => {
    return (
        <div className={`${styles.chartContainer} ${containerClassName}`}>
            {/* Title Section */}
            {title && (
                <div className={styles.titleContainer}>
                    <div>
                        <h5 className={styles.title}>{title}</h5>
                        {subtitle && <div className={styles.subTitle}>{subtitle}</div>}
                    </div>
                    {/* Custom Legends */}
                    {legendItems.length > 0 && (
                        <div className={styles.legendItemContainer}>
                            {legendItems.map((item, index) => (
                                <div key={index} className={styles.legendItem}>
                                    <div>{item.label}</div>
                                    <div
                                        className={styles.ball}
                                        style={{backgroundColor: item.color}}
                                    ></div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}

            {/* Chart Section */}
            <NoSwipeNavigation>
                <ResponsiveContainer width="100%"
                                     height={200}
                                     className={styles.chartContent}>
                    <LineChart data={data}
                               margin={{top: 20, right: 20, bottom: 5, left: 0}}
                    >
                        <defs>
                            {lines.map((line) =>
                                line.gradientId ? (
                                    <linearGradient key={line.gradientId}
                                                    id={line.gradientId}
                                                    x1="0"
                                                    y1="0"
                                                    x2="1"
                                                    y2="0"
                                    >
                                        <stop offset="0%" stopColor={line.stroke} stopOpacity={0}/>
                                        <stop offset="20%" stopColor={line.stroke} stopOpacity={1}/>
                                        <stop offset="100%" stopColor={line.stroke} stopOpacity={1}/>
                                    </linearGradient>
                                ) : null
                            )}
                        </defs>
                        <CartesianGrid className={styles.cartesianGrid}
                                       strokeDasharray="3 3"
                                       stroke="#e0e0e0"
                                       vertical={false}
                        />
                        <XAxis dataKey={xKey}
                               axisLine={false}
                               tickMargin={-15}
                               padding={{left: 0, right: 0}}
                               tickLine={false}
                               interval={"preserveStartEnd"}
                               minTickGap={38}
                               tick={{fontSize: 12}}
                               tickFormatter={xAxisFormatter}
                        />
                        <YAxis width={20}
                               tickFormatter={(value: any) => {
                                   //Don't show 0 on the y-axis labels
                                   if (value === 0) {
                                       return "";
                                   }
                                   return value;
                               }}
                               axisLine={false}
                               tickLine={false}
                               tick={{fontSize: 12, dx: 20}}
                        />
                        <Tooltip formatter={(value: number, name: string) => {
                            const lineConfig = lines.find((line) => line.dataKey === name);
                            const displayName = lineConfig?.dataName || name; // Use dataName if available
                            return [value.toFixed(2), displayName];
                        }}
                                 labelFormatter={(label: string) => {
                                     const date = new Date(label);
                                     return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1)
                                         .toString()
                                         .padStart(2, '0')}.${date.getFullYear()}`;
                                 }}
                        />
                        {lines.map((line) => (
                            <Line key={line.dataKey}
                                  type={line.type || 'linear'}
                                  dataKey={line.dataKey}
                                  stroke={line.gradientId ? `url(#${line.gradientId})` : line.stroke}
                                  strokeWidth={line.strokeWidth || 2}
                                  dot={false}
                            />
                        ))}
                        {brush && (
                            <Brush className={styles.brush}
                                   dataKey={xKey}
                                   height={8}
                                   padding={{top: 10}}
                                   stroke={"#fe5000"}
                                   travellerWidth={15}
                                   onSelect={undefined}
                                   dx={5}
                                   dy={5}
                                   traveller={(props: any) => {
                                       const {x, y, width, height} = props;
                                       const ballSize = 10;
                                       const cx = x + width / 2;
                                       const cy = y + height / 2;
                                       const radius = Math.min(width + ballSize, height + ballSize) / 2;

                                       return (
                                           <circle cx={cx}
                                                   cy={cy}
                                                   r={radius}
                                                   stroke="none"
                                           />);
                                   }}
                                   tickFormatter={() => ""}
                            />
                        )}
                    </LineChart>
                </ResponsiveContainer>
            </NoSwipeNavigation>
        </div>
    );
};

export default RentaLineChart;