import React from 'react';


interface StatusPageContainerProps {
    constructionSiteId: string;
}
const StatusPageContainer: React.FC<StatusPageContainerProps> = ({ constructionSiteId }) => {
    return (
        <div>
            {constructionSiteId}
        </div>
    );
};

export default StatusPageContainer;