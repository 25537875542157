import React from "react";
import {OrganizationContractModel} from "@/models/server/OrganizationContractModel";
import {PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import ListContractsPagedRequest from "@/models/server/Requests/ListContractsPagedRequest";
import {OrganizationContractLevel} from "@/models/Enums";
import PageDefinitions from "@/providers/PageDefinitions";
import RentaPage from "@/models/base/RentaPage";
import {BaseGridPageParameters} from "@/models/BaseGridParameters";
import CompaniesGrid from "@/pages/AdminCompaniesOverview/CompaniesGrid/CompaniesGrid";

export interface IAdminCompaniesOverviewParams extends BaseGridPageParameters {
    favorites?: boolean;
    showDeleted?: boolean;
}

interface IAdminCompaniesOverviewState {
}

export default class AdminCompaniesOverview extends RentaPage<IAdminCompaniesOverviewParams, IAdminCompaniesOverviewState> {
    private async getCompaniesAsync(pageNumber: number,
                                    pageSize: number,
                                    sortColumnName: string | null,
                                    sortDirection: SortDirection | null,
                                    searchTerm: string | null,
                                    showOnlyFavorites: boolean | false,
                                    showDeleted: boolean | false,
    ): Promise<IPagedList<OrganizationContractModel>> {
        const request: ListContractsPagedRequest = {
            level: OrganizationContractLevel.Company,
            sortColumnName: sortColumnName,
            sortDirection: sortDirection,
            searchTerm: searchTerm,
            pageSize: pageSize,
            pageNumber: pageNumber,
            showOnlyFavorites: showOnlyFavorites,
            showDeleted: showDeleted
        };

        return await this.postAsync("/api/Companies/GetCompaniesPagedList", request);
    }

    protected get title(): string {
        return Localizer.adminPageBusinessManagement;
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer>
                {
                    this.renderContractPricesAlert()
                }

                <PageHeader title={Localizer.adminPageRentaAdminManager}
                            subtitle={this.title}
                />


                <CompaniesGrid searchKeyword={this.typedParameters?.keyword}
                               pageNumber={this.typedParameters?.pageNumber}
                               showFavorites={this.typedParameters?.favorites}
                               showDeleted={this.typedParameters?.showDeleted}
                               sortDirection={this.typedParameters?.sortDir}
                               sortColumnName={this.typedParameters?.sortColumn}
                               renderFor={PageDefinitions.adminCompanyManagement.route()}
                               getCompanies={async (pageNumber, pageSize, sortColumnName, sortDirection, searchTerm, showOnlyFavorites, showDeleted) =>
                                   await this.getCompaniesAsync(pageNumber, pageSize, sortColumnName, sortDirection, searchTerm, showOnlyFavorites, showDeleted)}
                />
            </PageContainer>

        );
    }
}