import React, {useEffect, useMemo, useState} from 'react';
import {getConstructionSitesPowerConsumptionByExternalId} from '@/services/ConstructionSiteService';
import ConstructionSitePowerConsumption from "@/models/server/ConstructionSitePowerConsumption";
import RentaLineChart, {ChartData} from '@/components/Charts/LineChart/RentaLineChart';
import {Box, LoadingDecorator} from '@renta-apps/renta-react-components';
import styles from "./DailyConsumption.module.scss";
import {formatDate} from "@/helpers/DateHelper";
import Localizer from '@/localization/Localizer';
import {ch} from "@renta-apps/athenaeum-react-common";
import UserContext from "@/models/server/UserContext";
import EasyPlusLink from "@/components/EasyPlus/EasyPlusLink/EasyPlusLink";


const calculateMovingAverage = (
    data: ChartData[],
    windowSize: number
): ChartData[] => {
    return data.map((current, index) => {
        const windowData = data.slice(Math.max(0, index - windowSize + 1), index + 1);
        const average = windowData.reduce((sum, item) => sum + item.value, 0) / windowData.length;
        return {...current, movingAverage: average};
    });
};

interface DailyConsumptionProps {
    constructionSiteId: string;
}

const DailyConsumption: React.FC<DailyConsumptionProps> = ({constructionSiteId}) => {
    const [data, setData] = useState<ChartData[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result: ConstructionSitePowerConsumption[] =
                    await getConstructionSitesPowerConsumptionByExternalId(constructionSiteId);
                const chartData: ChartData[] = result.map(item => {
                    return {
                        value: item.consumption,
                        date: item.date,
                    };
                });
                const dataWithMovingAverage = calculateMovingAverage(chartData, 7);
                setData(dataWithMovingAverage);
            } catch (error) {
                console.error('Error fetching power consumption data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [constructionSiteId]);

    const easyPlusEnabled = useMemo(() => {
        return (ch.getContext() as UserContext)?.isEasyPlusUser ?? false;
    }, []);

    return (
        <LoadingDecorator className={styles.loadingContainer} hideConentOnLoading={true} isLoading={loading}>
            {
                !easyPlusEnabled && (
                    <Box className={styles.emptyConsumptionContainer} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <span data-cy={"powerRequireEasyPlus"}>{Localizer.constructionSiteDetailsPowerEasyPlusRequired}</span>
                        <EasyPlusLink>{Localizer.easyPlusInfoPanelReadMore}</EasyPlusLink>
                    </Box>
                )
            }
            {
                (easyPlusEnabled && data.length > 0) && (
                    <RentaLineChart
                        data={data}
                        xKey="date"
                        lines={[
                            {
                                dataKey: "value",
                                dataName: Localizer.constructionSiteDetailsPowerConsumptionChartLegend,
                                stroke: "#ff7300",
                                strokeWidth: 3,
                                gradientId: "lineGradient",
                                type: "linear",
                            },
                            {
                                dataKey: "movingAverage",
                                dataName: Localizer.genericChartMovingAverage,
                                stroke: "#8884d8",
                                strokeWidth: 3,
                                gradientId: "movingAverage",
                                type: "monotone",
                            },
                        ]}
                        title={`${data[data.length - 1]?.value.toFixed(2)} ${Localizer.constructionSiteDetailsPowerKwhPerDay}`}
                        subtitle={`${formatDate(data[data.length - 1]?.date)}`}
                        legendItems={[
                            {label: Localizer.constructionSiteDetailsPowerConsumptionChartLegend, color: "#FE5000"},
                            {label: Localizer.genericChartMovingAverage, color: "#116CEA"},
                        ]}
                        xAxisFormatter={(date) => {
                            const parsedDate = new Date(date);
                            return `${parsedDate.getDate().toString().padStart(2, '0')}.${(parsedDate.getMonth() + 1)
                                .toString()
                                .padStart(2, '0')}`;
                        }}
                        brush={true}
                    />
                )
            }
            {
                (easyPlusEnabled && data.length === 0) && (
                    <Box className={styles.emptyConsumptionContainer} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <span data-cy={"alertsRequireEasyPlus"}>{Localizer.genericNoData}</span>
                    </Box>
                )
            }
        </LoadingDecorator>
    );
};

export default DailyConsumption;