// @ts-ignore
import {pageData} from "@/helpers/CypressHelper";

describe('Desktop - Anonymous depots tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['accept_cookie'], cy.acceptCookies);
    });

    it('Open depots page and loop through depots', () => {
        cy.visit('depots');
        pageData().common.waitTopNavRender();
        cy.get('[data-cy=depot]')
            .each(($el: any) => {
                const office = $el.text();

                cy.wrap($el).click();
                cy.get('[data-cy=office_title]').should('have.text', office);
            }); // Iterate through each 'li'

    });
});