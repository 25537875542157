import Localizer from "@/localization/Localizer";
import { onRedirect } from "@/helpers/CypressHelper";

describe('Desktop - Authenticated construction site details tests', () => {

    const responseContainer = {
        "value": {
            "flags": [
                {
                    "flagName": "NEW_CONSTRUCTION_SITE_PAGE",
                    "enabled": false
                },
            ]
        },
        "context": null,
        "newVersionAvailable": false,
        "redirect": null,
        "error": null,
        "alert": null,
        "unauthorized": false,
        "isResponseContainer": true
    };

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

        cy.visit('/');
    });

    it('Should display page with construction site access friendly error', () => {

        onRedirect(() => cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee592`));

        cy.get('h1')
            .should('contain', Localizer.constructionSiteDetailsCannotBeAccessedTitle);

        cy.get('div')
            .should('contain', Localizer.constructionSiteDetailsCannotBeAccessedDescription);

        cy.get('div')
            .should('contain', Localizer.constructionSiteDetailsCannotBeAccessedWhatNext);

        cy.get('li')
            .should('contain', Localizer.genericTryLoggingInAgain);

        cy.get('a')
            .should('contain', Localizer.genericContactSupport);

    });

    it('Should display page with construction site details - old', () => {

        cy.intercept('GET', '/api/LocalFeatureFlag/FeatureFlags', { statusCode: 200, body: responseContainer });

        onRedirect(() => cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee591`));

        cy.get('h1')
            .should('contain', 'TMI JUKKA WILSKA');

        cy.get('h2')
            .should('contain', 'TESTITILAUS RENTA EASY');

    });

    it('Should display page with construction site details - new', () => {
        onRedirect(() => cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=bade07a5-2828-4856-9d06-64cf361b6c84`));

        cy.get("[data-cy=construction-site-name]")
            .should('contain', 'ConstructionSite6');

        cy.get("[data-cy=construction-site-additional-reference]")
            .should('contain', 'TestSite6ExternalReference');

        cy.get("[data-cy=google_maps_link]")
            .should('contain', 'Rillitie 8, Vantaa');
    });
});