import Localizer from "@/localization/Localizer";
import DeviceGridHelper from "@/helpers/DeviceGridHelper";

describe('Desktop - Fleet Monitoring - Return requests grid tests', () => {
    before(() => {
        cy.clearAllLocalStorage()
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });
    });

    it('should change URL when fleet monitoring button is clicked and render empty grid', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetDevicesPagedListEmpty.json' });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        DeviceGridHelper.getReturnRequestsGrid()
            .should('exist');

        // The grid is empty with a message
        DeviceGridHelper.getReturnRequestsGrid()
            .should('contain', Localizer.fleetMonitoringPageGridEmpty);
    });

    it('should render grid correctly when there are devices', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetReturnsPagedList.json' });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        DeviceGridHelper.getReturnRequestsGrid()
            .should('exist');

        // The grid has a header row and 7 data rows.
        DeviceGridHelper.getReturnRequestsGrid()
            .children('div')
            .should('have.length', 8);

        // Check header titles.
        DeviceGridHelper.getReturnRequestsGridContentByRowAndCell(0, 0)
            .should('contain', Localizer.genericConstructionSite);

        DeviceGridHelper.getReturnRequestsGridContentByRowAndCell(0, 1)
            .should('contain', Localizer.genericCompany);

        DeviceGridHelper.getReturnRequestsGridContentByRowAndCell(0, 2)
            .should('contain', Localizer.fleetMonitoringPageGridPickUpTime);

        DeviceGridHelper.getReturnRequestsGridContentByRowAndCell(0, 4)
            .should('contain', Localizer.fleetMonitoringPageGridAmount);

        DeviceGridHelper.getReturnRequestsGridContentByRowAndCell(0, 5)
            .should('contain', Localizer.fleetMonitoringPageGridDevices);

        DeviceGridHelper.getReturnRequestsGridContentByRowAndCell(0, 6)
            .should('contain', Localizer.fleetMonitoringPageGridDepot);

        DeviceGridHelper.getReturnRequestsGridContentByRowAndCell(0, 7)
            .should('contain', Localizer.fleetMonitoringPageGridStatus);

        // Check few values in the grid.
        DeviceGridHelper.getReturnRequestsGridContentByRowAndCell(1, 0)
            .should('contain', 'ABC-TIKKULA');

        DeviceGridHelper.getReturnRequestsGridContentByRowAndCell(2, 1)
            .should('contain', 'Tmi Jukka Wilska Corp');

        DeviceGridHelper.getReturnRequestsGridContentByRowAndCell(3, 2)
            .should('contain', '8/11/2023');

        DeviceGridHelper.getReturnRequestsGridContentByRowAndCell(4, 4)
            .should('contain', '4');

        DeviceGridHelper.getReturnRequestsGridContentByRowAndCell(4, 5)
            .should('contain', '2');

        DeviceGridHelper.getReturnRequestsGridContentByRowAndCell(5, 6)
            .should('contain', 'Seinäjoki');

        DeviceGridHelper.getReturnRequestsGridContentByRowAndCell(3, 7)
            .children('span')
            .eq(1)
            .should('contain', Localizer.fleetMonitoringPageGridStatusDone);
    });

    it('should expand a row and display return information', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetReturnsPagedList.json' });

        cy.intercept('POST', '/api/Fleet/GetReturnDetails', { fixture: 'getReturnDetails.json' });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        DeviceGridHelper.getReturnRequestsGrid()
            .should('exist');

        const testedRow = 2;
        const returnRequestedDate = new Date(2024, 10, 7);

        DeviceGridHelper.getReturnRequestsGridAngleIcon(testedRow)
            .click();

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridReturnAddress);

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('contain.text', 'Sponge Bob box pants street 21');

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('contain.text', '15100');

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('contain.text', 'Bear lake');

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridReturnRequestedBy);

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('contain.text', 'Viktor Orban');

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('contain.text', 'viktor.orban@mega.hu');

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('contain.text', '+358 44 777 8181');

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridReturnContactInformation);

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('contain.text', 'martti.ahtisaari@suomi.fi');

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('contain.text', '+358 44 555 8181');

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridReturnRequestedAt);

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('contain.text', returnRequestedDate.toLocaleDateString([], { year: 'numeric', month: '2-digit', day: '2-digit' }));

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .find('[data-cy="module-content-devices"]')
            .children()
            .should('have.length', 1)
            .children('div')
            .should('have.length', 1 + 3)
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageGridDevice)
            .should('contain.text', Localizer.fleetMonitoringPageGridId)
            .should('contain.text', Localizer.fleetMonitoringPageGridAmount)
            .next()
            .should('contain.text', 'Jatkojohto 15m')
            .should('contain.text', '15322')
            .should('contain.text', '24')
            .next()
            .should('contain.text', 'Akkusaksi 7,92M Jlg 2646ES Lorem Ipsum Dolor Sit Amet')
            .should('contain.text', '454522')
            .should('contain.text', '1')
            .next()
            .should('contain.text', 'Manitou Mt 932 Easy')
            .should('contain.text', '90193')
            .should('contain.text', '12')
    });

    it('should expand a row and display empty additional data', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetReturnsPagedList.json' });

        cy.intercept('POST', '/api/Fleet/GetReturnDetails', { fixture: 'getReturnDetailsEmpty.json' });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        DeviceGridHelper.getReturnRequestsGrid()
            .should('exist');

        const testedRow = 1;

        DeviceGridHelper.getReturnRequestsGridAngleIcon(testedRow)
            .click();

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridReturnAddress);

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridReturnRequestedBy);

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridReturnContactInformation);

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .should('not.contain.text', Localizer.fleetMonitoringPageGridReturnRequestedAt);

        DeviceGridHelper.getReturnRequestsGridExpandedContentByRow(testedRow)
            .find('[data-cy="module-content-devices"]')
            .should('not.exist');
    });

    it('should expand all/none rows with a single click on the header row', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetReturnsPagedList.json' });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        DeviceGridHelper.getReturnRequestsGrid()
            .should('exist');

        // Verify that the header arrow is directed down (some or none rows are expanded).
        DeviceGridHelper.getReturnRequestsGridAngleIcon(0)
            .should('not.have.class', 'rotated');

        // Click on all arrows (expand all rows).
        DeviceGridHelper.getReturnRequestsGridAngleIcon(1)
            .click();

        DeviceGridHelper.getReturnRequestsGridAngleIcon(2)
            .click();

        DeviceGridHelper.getReturnRequestsGridAngleIcon(3)
            .click();

        DeviceGridHelper.getReturnRequestsGridAngleIcon(4)
            .click();

        DeviceGridHelper.getReturnRequestsGridAngleIcon(5)
            .click();

        DeviceGridHelper.getReturnRequestsGridAngleIcon(6)
            .click();

        DeviceGridHelper.getReturnRequestsGridAngleIcon(7)
            .click();

        // Verify that the header arrow is directed up now (all rows are expanded).
        DeviceGridHelper.getReturnRequestsGridAngleIcon(0)
            .invoke('attr', 'class')
            .then((classList) => {
                expect(classList).to.contain('rotated');
            });

        // Click the header arrow and verify if all arrows are down too (all rows are closed).
        DeviceGridHelper.getReturnRequestsGridAngleIcon(0)
            .click();
    });
});