import {Button, ButtonType, Checkbox, Form, InlineType, Modal, NumberInput, OneColumn, TextInput, Tags, Tag, InputActionResultType, InputActionResult} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import React, {useEffect, useState} from "react";
import CreateProductRequest from "@/models/server/CreateProductRequest";
import {createProductAsync} from "@/services/CatalogService";
import {FeatureSwitch} from "@/providers/FeatureSwitch";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

type NewProductModalProps = {
    isOpen: boolean;
    currentCategoryId: string;
    onClose(success: boolean): void;
}

const NewProductModal: React.FC<NewProductModalProps> = (props: NewProductModalProps) => {

    const _modalRef: React.RefObject<Modal> = React.createRef();

    const [newProductName, setNewProductName] = useState<string>("");
    const [newProductExternalId, setNewProductExternalId] = useState<string>("");
    const [newProductSalesItem, setNewProductSalesItem] = useState<boolean>(false);
    const [newProductRentalBasis, setNewProductRentalBasis] = useState<number>(0);
    const [newProductTags, setNewProductTags] = useState<Tag[]>([]);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const createProduct = async (): Promise<void> => {
        const request: CreateProductRequest = {
            categoryId: props.currentCategoryId!,
            externalId: newProductExternalId,
            productName: newProductName,
            salesItem: newProductSalesItem,
            rentalBasis: newProductRentalBasis,
            tags: newProductTags.map(value => value.label)
        };

        const createdProduct = await createProductAsync(request);

        props.onClose(!!createdProduct);
    }

    const addNewProductTag = (tagValue: string): InputActionResult => {
        if (newProductTags.find(tag => tag.label.toLowerCase() === tagValue.toLowerCase())) {
            return {status: InputActionResultType.INVALID, message: Localizer.newProductModalDuplicatedTagValidationMessageText};
        }
        setNewProductTags([...newProductTags, {id: tagValue, label: tagValue}]);
        return {status: InputActionResultType.SUCCESS};
    }

    const deleteProductTag = (tagToDelete: Tag): void => {
        newProductTags.remove(tagToDelete);
        setNewProductTags([...newProductTags]);
    }

    const cleanup = (): void => {
        setNewProductSalesItem(false);
        setNewProductRentalBasis(0);
        setNewProductExternalId("");
        setNewProductName("");
        setNewProductTags([]);
    }

    useEffect(() => {
        if (props.isOpen) {
            _modalRef.current?.openAsync();
        } else {
            cleanup();
            _modalRef.current?.closeAsync();
        }
    }, [props.isOpen]);

    useEffect(() => {
        const isFormValid = newProductName.length > 0 && newProductExternalId.length > 0 && (newProductTags.length === 0 || !newProductTags.some((v, i) => newProductTags.indexOf(v) < i));
        setIsFormValid(isFormValid);
    }, [newProductName, newProductExternalId, newProductTags]);

    return (
        props.isOpen ? (
            <Modal ref={_modalRef}
                   title={Localizer.catalogEditProductNewProduct}
                   onClose={async () => props.onClose(false)}
            >
                <Form id="createProduct" onSubmit={async (_, _data) => createProduct()}>
                    <OneColumn>
                        <TextInput required
                                   id="newProductName"
                                   label={Localizer.catalogEditProductProductName}
                                   value={newProductName}
                                   onChange={async (_, value) => setNewProductName(value)}
                        />

                        <TextInput required
                                   id="newProductExternalId"
                                   label={Localizer.catalogEditProductExternalId}
                                   value={newProductExternalId}
                                   onChange={async (_, value) => setNewProductExternalId(value)}
                        />

                        <NumberInput id="newProductRentalBasis"
                                     label={Localizer.catalogProductRentalBasis}
                                     value={newProductRentalBasis}
                                     onChange={async (_, value) => setNewProductRentalBasis(newProductRentalBasis)}
                        />

                        <Tags items={newProductTags}
                              onDelete={(item) => deleteProductTag(item)}
                              onAdd={async (string: string) => addNewProductTag(string)}
                              label={Localizer.newProductModalTagsLabel}
                              localizations={{
                                    addNewTagTitle: Localizer.newProductModalAddNewTagTitle,
                                    copyTagsTitle: Localizer.newProductModalCopyTagsTitle,
                                    deleteTagTitle: Localizer.newProductModalDeleteTagTitle,
                                    submitAddNewTagTitle: Localizer.newProductModalAddNewTagTitle
                                }}
                        />
                    </OneColumn>

                    <FeatureSwitch flagName={RentaEasyConstants.featureFlagSalesItems}>
                        <OneColumn className="py-3">
                            <Checkbox inline
                                      inlineType={InlineType.Right}
                                      id="newProductSalesItem"
                                      label={Localizer.catalogEditProductSalesItem}
                                      value={newProductSalesItem}
                                      onChange={async (sender, checked) => setNewProductSalesItem(checked)}
                            />
                        </OneColumn>
                    </FeatureSwitch>

                    <OneColumn className={"mt-2"}>
                        <Button submit block
                                id={`add_product_submit`}
                                type={ButtonType.Orange}
                                label={Localizer.genericSave}
                                disabled={!isFormValid}
                        />
                    </OneColumn>
                </Form>
            </Modal>
        ) : null
    );
}

export default NewProductModal;