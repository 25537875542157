import React from "react";
import {
    PageContainer,
    PageHeader,
    PageRow, RouteWidget,
    WidgetContainer
} from "@renta-apps/athenaeum-react-components";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import RentaPage from "@/models/base/RentaPage";
import Localizer from "@/localization/Localizer";
import styles from "./PricingTool.module.scss";
import PageDefinitions from "@/providers/PageDefinitions";

export interface IPricingToolParams extends BasePageParameters {
}

interface IPricingToolState {}

export default class PricingTool extends RentaPage<IPricingToolParams, IPricingToolState> {

    public state: IPricingToolState = {};

    protected get title(): string {
        return Localizer.admin;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public renderComponents(): React.ReactNode {
        return (
            <div>
                <div className="row">
                    <WidgetContainer className={styles.widgetContainer}>
                        <RouteWidget minimized wide
                                     classNames={{
                                         description: styles.linkWidgetDescription
                                     }}
                                     route={PageDefinitions.pricingToolTemplates.route()}
                                     label={Localizer.pricingToolTemplates}
                                     id="pricingToolTemplates"
                        />
                        <RouteWidget minimized wide
                                     classNames={{
                                         description: styles.linkWidgetDescription
                                     }}
                                     route={PageDefinitions.pricingToolDiscounts.route()}
                                     label={Localizer.pricingToolDiscounts}
                                     id="pricingToolDiscounts"
                        />
                    </WidgetContainer>
                </div>
            </div>
        );
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer className={styles.pricingTool}>
                <PageHeader title={Localizer.pricingToolPricingToolHeader} className={styles.header}
                />

                <PageRow>
                    {
                        <div className="col">
                            {
                                <div>
                                    {
                                        (this.renderComponents())
                                    }
                                </div>
                            }
                        </div>
                    }
                </PageRow>
            </PageContainer>
        );
    }
}