import Localizer from "@/localization/Localizer";
import { pageData } from "@/helpers/CypressHelper";
import DeviceGridHelper from "@/helpers/DeviceGridHelper";

describe('Desktop - Fleet Monitoring - Filters tests - company user', () => {
    before(() => {
        cy.clearAllLocalStorage()
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });
    });

    it('should not display company search input for company without children', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', { fixture: 'constructionSiteInfos.json' });

        cy.intercept('POST', 'api/Companies/GetContractData').as("GetContractData");

        cy.visit('/fleet-monitoring');

        cy.waitSuccess('@GetContractData');

        DeviceGridHelper.getDropdown("filter-by-company-dropdown").should("not.be.visible");
    });

    it('should display company search dropdown for company with children', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', { fixture: 'constructionSiteInfos.json' });

        cy.intercept('POST', 'api/Companies/GetContractData', { fixture: 'getContractDataWithChildren.json' }).as("GetContractData");

        cy.visit('/fleet-monitoring');

        cy.waitSuccess('@GetContractData');

        pageData().common.waitTopNavRender();

        DeviceGridHelper.getDropdown("filter-by-company-dropdown").get('[data-cy="dropdownLabel"]')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsCompany);

        DeviceGridHelper.getDropdown("filter-by-company-dropdown").should("be.visible");
    });

    it('should set company dropdown with the value obtain from URL', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', req => {
            if (req.body.companies.length > 1) {
                req.alias = 'postRequestFiltered';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetDevicesPagedListEmpty.json'});
        });

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', { fixture: 'constructionSiteInfos.json' });

        cy.intercept('POST', 'api/Companies/GetContractData', { fixture: 'getContractDataWithChildren.json' });

        cy.visit(`/fleet-monitoring?companies=${JSON.stringify([{name:"TMI JUKKA WILSKA 1"}])}`);

        DeviceGridHelper.checkTags(["TMI JUKKA WILSKA 1"]);
        
        cy.wait('@postRequest').then(() => {
            DeviceGridHelper.checkDropdownValue("filter-by-company-dropdown", Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 1));

            DeviceGridHelper.getDropdown("filter-by-company-dropdown").click();

            DeviceGridHelper.clickDropdownListItem("filter-by-company-dropdown", 2);

            DeviceGridHelper.checkTags(["TMI JUKKA WILSKA 1", "TMI JUKKA WILSKA 2"]);
            
            cy.wait('@postRequestFiltered').then(({ request }) => {
                // Assert that the request body contains the changed value
                expect(request.body).to.have.nested.property('companies')
                expect(request.body.companies).to.have.length(2);
                expect(request.body.companies[0].name === 'TMI JUKKA WILSKA 1');
                expect(request.body.companies[1].name === 'TMI JUKKA WILSKA 2 (30495)');

                DeviceGridHelper.checkDropdownValue("filter-by-company-dropdown", Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 2));

                DeviceGridHelper.checkTags(['TMI JUKKA WILSKA 1', 'TMI JUKKA WILSKA 2 (30495)']);
            });
        });
    });
});

describe('Desktop - Fleet Monitoring - Filters tests - admin user', () => {
    before(() => {
        cy.clearAllLocalStorage()
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});
    });

    it('should display company search input for admin', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', req => {
            if (req.body.companies.length) {
                req.alias = 'postRequestFiltered';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetDevicesPagedListEmpty.json'});
        });

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', { fixture: 'constructionSiteInfos.json' });

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDropdown("filter-by-company-dropdown")
            .get('[data-cy="dropdownLabel"]')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsCompany);

        DeviceGridHelper.getDropdown("filter-by-company-dropdown").should("be.visible");

        DeviceGridHelper.getDropdown("filter-by-company-dropdown").click();

        DeviceGridHelper.typeDropdownInput("filter-by-company-dropdown", "tmi");

        DeviceGridHelper.clickDropdownListItem("filter-by-company-dropdown", 5);

        DeviceGridHelper.checkDropdownValue("filter-by-company-dropdown", Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 1));

        DeviceGridHelper.checkTags(["TMI JUKKA WILSKA (29276)"]);

        cy.wait('@postRequestFiltered').then(({ request }) => {
            expect(request.body).to.have.nested.property('companies')
            expect(request.body.companies).to.have.length(1);
            expect(request.body.companies[0].name === 'TMI JUKKA WILSKA (29276)');
            expect(request.body.companies[0].id === '31eaa6c0-42fa-40c2-a891-bcd16c367409'); 
        });
    });

    it('should set company input with the value obtain from URL', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', req => {
            if (req.body.companies.length) {
                req.alias = 'postRequestFiltered';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({ fixture: 'fleetGetDevicesPagedList.json' });
        });

        cy.visit(`/fleet-monitoring?companies=${JSON.stringify([{name:"TMI JUKKA"}])}`);

        DeviceGridHelper.checkTags(["TMI JUKKA"]);

        cy.wait('@postRequestFiltered').then(() => {
            DeviceGridHelper.checkDropdownValue("filter-by-company-dropdown", Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 1));

            DeviceGridHelper.checkTags(["TMI JUKKA"]);
        });
    });
});