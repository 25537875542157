export enum AuthType {
    Email,

    Phone
}

export enum AlarmMediaType {
    None = 0,

    Email = 1,

    Sms = 2,

    EmailAndSms = 3,
}

export enum IdentityProvider {
    Signicat,

    Microsoft,
}

export enum CustomerEventQueueOperationType {
    Added,

    Modified,

    Deleted,
}

export enum FuelSensorType {
    TrackUnit = 0,
    Pindora = 1,
}

export enum AlarmType {
    TrackUnitDeviceStarts = 0,

    TrackUnitDeviceStops = 1,

    TrackUnitBatteryVoltageGreaterThan = 2,

    TrackUnitBatteryVoltageLessThan = 3,

    TrackUnitFuelLevelGreaterThan = 4,

    TrackUnitFuelLevelLessThan = 5,

    TrackUnitMovementBasedTheft = 6,

    PindoraFluidLevelLessThan = 7,

    DeviceUnusedFor3Days = 8,

    PindoraFluidLevelGreaterThan = 9,
}

export enum EnvironmentDeviceType {
    Unknown,
    Temperature,
    Pressure,
    Particles,
    Structure,
    Gateway
}

export enum InfoScreenType {
    EnvironmentControl,
}

export enum SavePasswordResultStatus {
    Success,

    WeakPassword,

    WrongCurrent
}

export enum LoginResultStatus {
    Success,

    NotFound,

    Deleted,

    Locked,

    /// <summary>
    /// Token security stamp does not correspond to user security stamp
    /// </summary>
    TokenInvalidSecurityStamp,

    /// <summary>
    /// Token already used
    /// </summary>
    TokenUsed,

    /// <summary>
    /// Token invalid or expired
    /// </summary>
    TokenInvalidOrExpired,

    /// <summary>
    /// First time login with Signicat
    /// </summary>
    NewSsoUser,

    /// <summary>
    ///  User has already bound Signicat identifier
    /// </summary>
    SsoLoginSuccess,

    /// <summary>
    /// Special case for Sweden
    /// User has already completed strong authentication earlier, but now tries to login via email
    /// </summary>
    OnlySsoLogin,

    /// <summary>
    ///  Tried to perform strong authentication binding with used identifier
    /// </summary>
    IdentifierAlreadyInUse,

    /// <summary>
    /// Successful strong authentication binding initiated from MyAccount page
    /// </summary>
    SsoBindSuccess
}

export enum InvitationStatus {
    /// <summary>
    /// The status is unknown, default fallback.
    /// </summary>
    Unknown = 0,

    /// <summary>
    /// The user has not interacted with the invitation email or started account creation.
    /// </summary>
    Sent = 1,

    /// <summary>
    /// The user opened the invitation email but has not completed account creation.
    /// </summary>
    LinkOpened = 2,

    /// <summary>
    /// The user has completed the account registration process, including setting a password and accepting agreements.
    /// </summary>
    Registered = 3,
}

export enum InvitationType {
    /// <summary>
    /// Set password by Admin
    /// </summary>
    Invitation,

    /// <summary>
    /// Reset password by Admin
    /// </summary>
    ResetPassword,

    /// <summary>
    /// Reset password by User
    /// </summary>
    ForgotPassword
}

export enum DailyRentalType {
    FiveDayRent = 0,

    SevenDayRent = 1,

    SixDayRent = 2
}

export enum RentType {
    Daily,

    Weekly,

    Monthly
}

export enum InputType {
    Text,

    Number,

    Dropdown,

    Date,

    DateRange,

    Checkbox,

    TextArea,

    LocationPicker,

    FileInput,

    /**
     * Defines contact person for the plan
     */
    CustomerSelect
}

export enum ServiceType {
    Refuel = 0,

    Maintenance = 1,

    Charging = 2,

    Custom = 3
}

export enum FileType {
    MainPicture,
    Picture,
    Pdf,
    Other,
    Excel,
    YouTubeVideo,
}

export enum ServiceRequestStatus {
    Received = 0,

    InProgress = 1,

    Processed = 2,

    Rejected = 3
}

export enum ConstructionSiteFilterRentedOrReturned {
    PastWeek,

    PastTwoWeeks,

    PastMonth
}

export enum ConstructionSiteSchedules {
    Daily,

    Weekly,

    Monthly
}

export enum EquipmentStatus {
    Rented,

    PartiallyReturned,

    Returned,
}

export enum EquipmentSortByType {
    Name = 0,

    AmountDesc = 1,

    AmountAsc = 2,

    Category = 3,

    ContractIdDesc = 4,

    ContractIdAsc = 5,

    OrdererDesc = 6,

    OrdererAsc = 7,

    RentalStartDateDesc = 8,

    RentalStartDateAsc = 9,

    RentalEndDateDesc = 10,

    RentalEndDateAsc = 11
}

export enum InvoiceType {
    Normal,

    Credit
}

export enum ScheduleAction {
    ConstructionSiteEquipmentList = 0,

    ConstructionSiteSync = 1,

    ProductPricesUpdate = 2,

    AutomaticCatalogUpdate = 3,

    OrderStatusUpdate = 4,

    DepotsSync = 9,
}

export enum InvoiceRowType {
    RentalObject,

    Info
}

export enum TogglerPosition {
    Header,

    Bottom
}

export enum ProductType {
    Rental = 0,

    Sales = 1
}

export enum PriceType {
    Default = 0,

    Offer = 1,

    Hidden = 2,
}

export enum MinimumRentalDays {
    OneDay = 1,

    SevenDays = 7,

    FourteenDays = 14,

    ThirtyDays = 30,
}

export enum OrderType {
    Delivery = 0,

    Pickup = 1
}

export enum OrganizationContractLevel {
    Operator = 0,

    Company = 1,

    SubCompany = 2 //TODO Think of better name
}

export enum CacheEnums {

}

export enum ConstructionSiteFilterRented {

}

export enum OrderStatus {
    Unconfirmed = 0,

    Ordered,

    Delivered,

    Processing,

    InProgress,

    Partially,

    Rejected
}

export enum ContractOrdering {

}

export enum ConstructionSitePermission {
    DirectOrders = 1 << 0,

    PricesVisible = 1 << 2,
}

export enum AttributeType {
    Text,
    PsiDocument,
    PsiSite,
    Manual,
    Url,
}

/**
 * State of a network request.
 */
export enum RequestState {
    NotSending = 0,
    Sending = 1,
    Success = 2,
}

export enum NotificationMediaType {
    Email,
    Sms,
    EmailAndSms
}

export enum EmissionFilterTimeFrame {
    Daily,
    Weekly,
    Monthly,
}

export enum EmissionType {
    Transport,

    OilMaintenance,

    Energy,

    Production,

    EndOfLife,
}

export enum DayOfWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
}

export enum SaveOrderRequestStatus {
    Success,
    Error
}

export enum RoleType {
    ConstructionSite,
    Contract,
}

export enum NewSsoBindStatus {
    Success,
    AlreadyCompleted
}

export enum UserAgreementType {
    PrivacyStatement = 1,
    TermsOfService = 2
}

export enum PlanType {
    Custom = 1
}

export enum PlanStatus {
    Received = 0,

    InProgress = 1,

    Processed = 2,

    Rejected = 3
}

export enum SendRequestTo {
    Admin = 0,
    Depot = 1,
    Both = 2,
}

export enum PricingToolDiscountStatus {
    Draft,      // new template/discount
    Awaiting,   // marked as awaiting for approval
    Approved,   // reviewed and accepted by supervisor
    Active,     // published - active discounts settings
    Inactive,   // deactivated old discounts settings
    Rejected,   // rejected by supervisor
}