import React, {useEffect, useMemo, useState} from "react";
import {Box, Dropdown, DropdownMode, IDropdownItem, Input, Tags, Tag} from "@renta-apps/renta-react-components";
import Localizer from "@/localization/Localizer";
import {ViewControlProps} from "@/pages/FleetMonitoring/ViewControl/ViewControl";
import {searchCompanyNames} from "@/services/CompanyService";
import {searchProductGroupNames} from "@/services/ProductService";
import {searchConstructionSiteNames} from "@/services/ConstructionSiteService";
import {ConstructionSiteName} from "@/models/server/ConstructionSiteName";
import styles from "./FiltersForm.module.scss";
import {getDepots} from "@/services/DepotsService";
import {CompanyName} from "@/models/server/CompanyName";
import {getConstructionSiteNames} from "@/services/FleetService";
import SelectedFilter from "@/models/server/Requests/SelectedFilter";
import isEqual from "lodash.isequal";

enum FilterType {
    DEVICE_NAMES = "device_names",
    COMPANIES = "companies",
    CONSTRUCTION_SITES = "construction_sites",
    PRODUCT_GROUPS = "product_groups",
    STATUSES = "statuses",
    DEPOTS = "depots",
}

interface FiltersFormProps extends ViewControlProps {
    vertical?: boolean;
    children?: React.ReactNode;
    className?: string;
    idPrefix?: string;
}

const FiltersForm: React.FC<FiltersFormProps> = ({
    filters,
    userRoleConstructionSiteId,
    userRoleContractId,
    userRoleIsAdmin,
    sortByItems,
    onFilterAndSort,
    vertical,
    children,
    className,
    idPrefix = '',
    userCompaniesList,
}) => {
    const [deviceName, setDeviceName] = useState<string>('');
    const [deviceNames, setDeviceNames] = useState<string[]>(filters.deviceNames);
    const [sortBy, setSortBy] = useState(filters.sortBy);
    const [sortOrder, setSortOrder] = useState(filters.sortOrder);

    const [companies, setCompanies] = useState<IDropdownItem[]>(
        filters.companies.map(item => ({value: item.id, name: item.name || ''}))
    );
    const [constructionSites, setConstructionSites] = useState<IDropdownItem[]>(
        filters.constructionSites.map(item => ({value: item.id, name: item.name || ''}))
    );
    const [depots, setDepots] = useState<IDropdownItem[]>(
        filters.depots.map(item => ({value: item.id, name: item.name || ''}))
    );
    const [productGroups, setProductGroups] = useState<IDropdownItem[]>(
        filters.productGroups.map(item => ({value: item.id, name: item.name || ''}))
    );
    const [statuses, setStatuses] = useState<IDropdownItem[]>(
        filters.statuses.map(item => ({value: item.id, name: item.name || ''}))
    );

    const [combinedFilterValues, setCombinedFilterValues] = useState<Tag[]>([]);

    const [userRoleCompany, setUserRoleCompany] = useState<IDropdownItem | undefined>(undefined);
    const [companiesList, setCompaniesList] = useState<IDropdownItem[]>([]);
    const [constructionSitesList, setConstructionSitesList] = useState<IDropdownItem[]>([]);
    const [depotsList, setDepotsList] = useState<IDropdownItem[]>([]);
    const [productGroupsList, setProductGroupsList] = useState<IDropdownItem[]>([]);

    const noCompanyFilter = useMemo(() => {
        return !userRoleIsAdmin && (!!userRoleConstructionSiteId || userRoleCompany);
    }, [userRoleCompany, userRoleConstructionSiteId, userRoleIsAdmin]);

    const formClassName = useMemo(() => {
        return `${styles.filtersForm} ${className} ${vertical ? styles.vertical : ''} ${
            noCompanyFilter ? styles.noCompanyFilter : ''} ${userRoleIsAdmin ? styles.admin : ''}`;
    }, [className, userRoleIsAdmin, noCompanyFilter, vertical]);

    const sortOrderItems: IDropdownItem[] = [
        {name: Localizer.enumSortDirectionDesc, value: "Desc"},
        {name: Localizer.enumSortDirectionAsc, value: "Asc"},
    ];

    const filterByStatusItems: IDropdownItem[] = [
        {name: Localizer.fleetMonitoringPageFiltersStatusAtDepot, value: "Depot"},
        {name: Localizer.fleetMonitoringPageFiltersStatusInRent, value: "IsRented"},
        {name: Localizer.fleetMonitoringPageFiltersStatusReturnRequested, value: "IsReturnRequested"},
    ];

    useEffect(() => {
        loadContractDataList(userRoleContractId, userRoleIsAdmin).catch();
    }, [userRoleContractId, userRoleIsAdmin, userCompaniesList]);

    useEffect(() => {
        loadDepotsList().catch();
    }, [userRoleIsAdmin]);

    useEffect(() => {
        if (companies.length === 0) {
            return;
        }

        searchConstructionSiteNamesInternal('').catch();
    }, [companies]);

    useEffect(() => {
        setCombinedFilterValues([
            ...deviceNames.map((item) => (
                {id: item, label: item, tooltip: Localizer.fleetMonitoringPageFiltersTooltipNameOrId, type: FilterType.DEVICE_NAMES}
            )),
            ...companies.map((item) => (
                {id: item.value?.toString() || "", label: item.name, tooltip: Localizer.genericCompany, type: FilterType.COMPANIES}
            )),
            ...constructionSites.map((item) => (
                {id: item.value?.toString() || "", label: item.name, tooltip: Localizer.genericConstructionSite, type: FilterType.CONSTRUCTION_SITES}
            )),
            ...productGroups.map((item) => (
                {id: item.value?.toString() || "", label: item.name, tooltip: Localizer.fleetMonitoringPageGridProductGroup, type: FilterType.PRODUCT_GROUPS}
            )),
            ...statuses.map((item) => (
                {id: item.value?.toString() || "", label: item.name, tooltip: Localizer.fleetMonitoringPageGridStatus, type: FilterType.STATUSES}
            )),
            ...depots.map((item) => (
                {id: item.value?.toString() || "", label: item.name, tooltip: Localizer.fleetMonitoringPageGridDepot, type: FilterType.DEPOTS}
            )),
        ]);
        onFilterInternal(deviceNames, companies, constructionSites, depots, productGroups, statuses, sortBy, sortOrder);
    }, [deviceNames, companies, constructionSites, depots, productGroups, statuses, sortBy, sortOrder]);

    useEffect(() => {
        if (isEqual(deviceNames, filters.deviceNames)) {
            setDeviceNames(filters.deviceNames);
        }
        
        const newCompanies = filters.companies.map(item => ({value: item.id, name: item.name || ''}));
        if (!isEqual(companies, newCompanies)) {
            setCompanies(newCompanies);
        }
        
        const newConstructionSites = filters.constructionSites.map(item => ({value: item.id, name: item.name || ''}));
        if (userRoleIsAdmin && newCompanies.length === 0) {
            setConstructionSites([]);
        } else if (!isEqual(constructionSites, newConstructionSites)) {
            setConstructionSites(newConstructionSites);
        }
        
        const newDepots = filters.depots.map(item => ({value: item.id, name: item.name || ''}));
        if (!isEqual(depots, newDepots)) {
            setDepots(newDepots);
        }
        
        const newProductGroups = filters.productGroups.map(item => ({value: item.id, name: item.name || ''}));
        if (!isEqual(productGroups, newProductGroups)) {
            setProductGroups(newProductGroups);
        }
        
        const newStatuses = filters.statuses.map(item => ({value: item.id, name: item.name || ''}));
        if (!isEqual(statuses, newStatuses)) {
            setStatuses(newStatuses);
        }
        
        if (sortBy !== filters.sortBy || sortOrder !== filters.sortOrder) {
            setSortBy(filters.sortBy);
            setSortOrder(filters.sortOrder);
        }
    }, [filters]);
    
    useEffect(() => {
        if (userRoleIsAdmin && companies.length === 0) {
            setConstructionSites([]);
        }
    }, [userRoleIsAdmin, companies]);

    const onFilterInternal = (
        deviceNames: string[] | undefined,
        companies: IDropdownItem[] | undefined,
        constructionSites: IDropdownItem[] | undefined,
        depots: IDropdownItem[] | undefined,
        productGroups: IDropdownItem[] | undefined,
        statuses: IDropdownItem[] | undefined,
        sortField: string = sortBy,
        sortDirection: string = sortOrder,
    ) => {
        onFilterAndSort({
            companies: companies?.map((item) => ({id: item.value, name: item.name} as SelectedFilter)) ?? [],
            constructionSites: constructionSites?.map((item) => ({id: item.value, name: item.name} as SelectedFilter)) ?? [],
            depots: depots?.map((item) => ({id: item.value, name: item.name} as SelectedFilter)) ?? [],
            deviceNames: deviceNames ?? [],
            productGroups: productGroups?.map((item) => ({id: item.value, name: item.name} as SelectedFilter)) ?? [],
            sortBy: sortField,
            sortOrder: sortDirection,
            statuses: statuses?.map((item) => ({id: item.value, name: item.name} as SelectedFilter)) ?? [],
        });
    };

    const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        if (!deviceName) {
            return;
        }

        setDeviceNames(prev => [...new Set([...prev, deviceName])]);
        setDeviceName('');
    };

    const searchCompanyNamesInternal = async (value: string): Promise<void> => {
        try {
            const searchTerm = value.trim();
            if (searchTerm.length < 3) {
                setCompaniesList([]);
                return;
            }
            const companyNames = await searchCompanyNames(searchTerm);
            setCompaniesList((companyNames ?? []).map(mapCompany));
        } catch (error) {
            console.error('Error searching company names:', error);
            setCompaniesList([]);
        }
    };

    const mapCompany = (company: CompanyName): IDropdownItem => {
        let name = company.name;
        let description = company.additionalName;

        if (company.devicesCount > 0) {
            description = getDevicesCountDescription(company.devicesCount, description);
        }

        return {name, value: company.id, description};
    };

    const searchProductGroupNamesInternal = async (value: string): Promise<void> => {
        try {
            const searchTerm = value.trim();
            if (searchTerm.length < 3) {
                setProductGroupsList([]);
                return;
            }
            const productGroupNames = await searchProductGroupNames(searchTerm);
            setProductGroupsList((productGroupNames ?? []).map((group) => (
                {name: group.name, value: group.externalId}
            )));
        } catch (error) {
            console.error('Error searching product group names:', error);
            setProductGroupsList([]);
        }
    };

    const searchConstructionSiteNamesInternal = async (value: string): Promise<void> => {
        try {
            const contractId = userRoleContractId;
            const searchTerm = value.trim();
            if (!contractId) {
                setConstructionSitesList([]);
                return;
            }
            let constructionSiteNames = [];
            if (userRoleCompany) {
                constructionSiteNames = await searchConstructionSiteNames(searchTerm ?? '', contractId);
            } else {
                constructionSiteNames = await getConstructionSiteNames(searchTerm, companies, 50);
            }
            setConstructionSitesList((constructionSiteNames ?? []).map(mapConstructionSite));
        } catch (error) {
            console.error('Error searching construction site names:', error);
            setConstructionSitesList([]);
        }
    };

    const mapConstructionSite = (site: ConstructionSiteName): IDropdownItem => {
        let name = Localizer.fleetMonitoringPageFiltersNoConstructionSiteName;
        let description: string | null = null;
        if (site.name && site.externalReference) {
            name = site.name;
            description = site.externalReference;
        } else if (site.name) {
            name = site.name;
        } else if (site.externalReference) {
            name = site.externalReference;
        }

        if (site.devicesCount > 0) {
            description = getDevicesCountDescription(site.devicesCount, description);
        }

        return {name, value: site.externalId, description};
    };

    const getDevicesCountDescription = (devicesCount: number, description: string | null): string => {
        const devicesCountText = Localizer.get(Localizer.fleetMonitoringPageFiltersDevicesCountLanguageItemName, devicesCount);

        return !description ? `(${devicesCountText})` : `${description} (${devicesCountText})`;
    };

    const loadDepotsList = async () => {
        if (!userRoleIsAdmin) {
            setDepotsList([]);
            return;
        }

        try {
            const depotNames = await getDepots();
            const depotsDropdownItems = depotNames.map((group) => (
                {name: group.name!, value: group.externalId.toString()}
            ));
            setDepotsList(depotsDropdownItems);
        } catch (error) {
            console.error('Error loading depot data:', error);
        }
    };

    const loadContractDataList = async (userRoleContractId: string | null, userRoleIsAdmin: boolean) => {
        if (userRoleIsAdmin || !userRoleContractId) {
            setCompaniesList([]);
            return;
        }

        try {
            if (userCompaniesList.length === 1) {
                setUserRoleCompany(userCompaniesList[0]);
                setCompanies([]);
                setCompaniesList([]);

                const constructionSiteNames = await searchConstructionSiteNames('', String(userCompaniesList[0]?.value!), 100);
                setConstructionSitesList((constructionSiteNames ?? []).map(mapConstructionSite));
            } else {
                setCompaniesList(userCompaniesList);
            }
        } catch (error) {
            console.error('Error loading contract data:', error);
        }
    };

    const handleSortByChange = (item: IDropdownItem) => {
        const newSortBy = item.value!.toString();
        setSortBy(newSortBy);
    };

    const handleSortOrderChange = (item: IDropdownItem) => {
        const newSortOrder = item.value!.toString();
        setSortOrder(newSortOrder);
    };

    const toggleStatusFilterItem = (label: string, id?: string) => {
        if (statuses.some((status) => status.name === label)) {
            setStatuses(prev => prev.filter((status) => status.name !== label));
        } else {
            setStatuses(prev => [...prev, {value: id, name: label}]);
        }
    };

    const toggleDeviceNamesItem = (label: string) => {
        if (deviceNames.some((name) => name === label)) {
            setDeviceNames(prev => prev.filter((name) => name !== label));
        } else {
            setDeviceNames(prev => [...prev, label]);
        }
    };

    const toggleCompanyFilterItem = (label: string, id?: string) => {
        if (companies.some((company) => company.name === label)) {
            setCompanies(prev => prev.filter((company) => company.name !== label));
        } else {
            setCompanies(prev => [...prev, {value: id, name: label}]);
        }
    };

    const toggleProductGroupFilterItem = (label: string, id?: string) => {
        if (productGroups.some((group) => group.name === label)) {
            setProductGroups(prev => prev.filter((group) => group.name !== label));
        } else {
            setProductGroups(prev => [...prev, {name: label, value: id}]);
        }
    };

    const toggleDepotFilterItem = (label: string, id?: string) => {
        if (depots.some((depot) => depot.name === label)) {
            setDepots(prev => prev.filter((depot) => depot.name !== label));
        } else {
            setDepots(prev => [...prev, {value: id, name: label}]);
        }
    };

    const toggleConstructionSiteFilterItem = (label: string, id?: string) => {
        if (constructionSites.some((site) => site.name === label)) {
            setConstructionSites(prev => prev.filter((site) => site.name !== label));
        } else {
            setConstructionSites(prev => [...prev, {value: id, name: label}]);
        }
    };

    const toggleFilterItem = (tag: Tag) => {
        const tagType = tag.type;
        const tagId = tag.id;
        const tagName = tag.label;
        switch (tagType) {
            case FilterType.DEVICE_NAMES:
                toggleDeviceNamesItem(tagName);
                break;
            case FilterType.COMPANIES:
                toggleCompanyFilterItem(tagName, tagId);
                break;
            case FilterType.CONSTRUCTION_SITES:
                toggleConstructionSiteFilterItem(tagName, tagId);
                break;
            case FilterType.PRODUCT_GROUPS:
                toggleProductGroupFilterItem(tagName, tagId);
                break;
            case FilterType.STATUSES:
                toggleStatusFilterItem(tagName, tagId);
                break;
            case FilterType.DEPOTS:
                toggleDepotFilterItem(tagName, tagId);
                break;
            default:
                console.error(`Unknown tag type: ${tagType}`);
        }
    };

    const clearFilters = () => {
        setDeviceNames([]);
        setCompanies([]);
        setConstructionSites([]);
        setProductGroups([]);
        setStatuses([]);
        setDepots([]);
    };

    const hasTags = () => {
        return deviceNames.length > 0 || companies.length > 0 || constructionSites.length > 0 || productGroups.length > 0 || statuses.length > 0 || depots.length > 0;
    };
    
    const formatSelectedLabel = (selectedCount: number) => {
        return Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, selectedCount);
    };

    return (
        <>
            <form className={formClassName}
                  onSubmit={(event) => handleSubmit(event)}
                  autoComplete="off"
            >
                <div className={`${styles.row} row ${styles.dropdownsContainer}`}>
                    <div className={`${styles.inputContainer} col-lg-4 col-sm-6 ${styles.col}`}
                         id="filterByNameWrapper"
                    >
                        <Input id={`${idPrefix}filter-by-name`}
                               value={deviceName}
                               label={Localizer.fleetMonitoringPageFiltersLabelsName}
                               iconName="magnifyingGlass"
                               iconButton
                               iconButtonType="submit"
                               inputProperties={{
                                   name: "deviceName",
                                   placeholder: deviceNames.length ? formatSelectedLabel(deviceNames.length) : undefined,
                               }}
                               onChange={setDeviceName}
                               onBlur={handleSubmit}
                        />
                    </div>

                    <div className={`${styles.inputFilter} col-lg-4 col-sm-6 col-12 ${styles.col} ${styles.hideOnMobile} ${styles.companyFilterContainer}`}
                         id="filterByCompanyDropdownWrapper"
                    >
                        {userRoleIsAdmin ? (
                            <Dropdown id={`${idPrefix}filter-by-company-dropdown`}
                                      items={companiesList ?? []}
                                      label={Localizer.fleetMonitoringPageFiltersLabelsCompany}
                                      selectedItem={companies}
                                      onChange={(items: IDropdownItem[]) => setCompanies(items.map(item => ({value: item.value, name: item.name})))}
                                      onSearch={searchCompanyNamesInternal}
                                      multiSelect
                                      allowEmpty
                                      searchable
                                      allowUserInput
                                      mode={DropdownMode.FILTER}
                                      formatSelectedLabel={formatSelectedLabel}
                            />
                        ) : (
                            <Dropdown id={`${idPrefix}filter-by-company-dropdown`}
                                      items={companiesList ?? []}
                                      label={Localizer.fleetMonitoringPageFiltersLabelsCompany}
                                      selectedItem={companies}
                                      onChange={(items: IDropdownItem[]) => setCompanies(items.map(item => ({value: item.value, name: item.name})))}
                                      multiSelect
                                      allowEmpty
                                      searchable
                                      allowUserInput
                                      mode={DropdownMode.FILTER}
                                      formatSelectedLabel={formatSelectedLabel}
                            />
                        )}
                    </div>

                    <div className={`${styles.inputFilter} col-lg-4 col-sm-6 col-12 ${styles.col} ${styles.hideOnMobile}`}
                         id="filterByProductGroupDropdownWrapper"
                    >
                        <Dropdown id={`${idPrefix}filter-by-product-group-dropdown`}
                                  items={productGroupsList ?? []}
                                  label={Localizer.fleetMonitoringPageFiltersLabelsProductGroup}
                                  selectedItem={productGroups}
                                  onChange={(items: IDropdownItem[]) => setProductGroups(items.map(item => ({value: item.value, name: item.name})))}
                                  onSearch={searchProductGroupNamesInternal}
                                  multiSelect
                                  allowEmpty
                                  searchable
                                  allowUserInput
                                  mode={DropdownMode.FILTER}
                                  formatSelectedLabel={formatSelectedLabel}
                        />
                    </div>

                    <div className={`${styles.inputFilter} col-lg-4 col-sm-6 col-12 ${styles.col} ${styles.hideOnMobile}`}
                         id="filterByConstructionSiteDropdownWrapper"
                    >
                        <Dropdown id={`${idPrefix}filter-by-construction-site-dropdown`}
                                  items={constructionSitesList ?? []}
                                  label={Localizer.fleetMonitoringPageFiltersLabelsConstructionSite}
                                  selectedItem={constructionSites}
                                  onChange={(items: IDropdownItem[]) => setConstructionSites(items.map(item => ({value: item.value, name: item.name})))}
                                  onSearch={!userRoleCompany ? searchConstructionSiteNamesInternal : null}
                                  disabled={!companies.length && !userRoleCompany}
                                  multiSelect
                                  allowEmpty
                                  searchable
                                  allowUserInput
                                  mode={DropdownMode.FILTER}
                                  formatSelectedLabel={formatSelectedLabel}
                        />
                    </div>

                    {userRoleIsAdmin && (
                        <div className={`${styles.inputFilter} col-lg-4 col-sm-6 col-12 ${styles.col} ${styles.hideOnMobile}`}
                             id="filterByStatusDropdownWrapper"
                        >
                            <Dropdown id={`${idPrefix}filter-by-status-dropdown`}
                                      items={filterByStatusItems ?? []}
                                      label={Localizer.fleetMonitoringPageFiltersLabelStatus}
                                      selectedItem={statuses}
                                      onChange={(items: IDropdownItem[]) => setStatuses(items.map(item => ({value: item.value, name: item.name})))}
                                      multiSelect
                                      allowEmpty
                                      formatSelectedLabel={formatSelectedLabel}
                            />
                        </div>
                    )}
                    {userRoleIsAdmin && (
                        <div className={`${styles.inputFilter} col-lg-4 col-sm-6 col-12 ${styles.col} ${styles.hideOnMobile}`}
                             id="filterByDepotDropdownWrapper"
                        >
                            <Dropdown id={`${idPrefix}filter-by-depot-dropdown`}
                                      items={depotsList ?? []}
                                      label={Localizer.fleetMonitoringPageFiltersLabelsDepot}
                                      selectedItem={depots}
                                      onChange={(items: IDropdownItem[]) => setDepots(items.map(item => ({value: item.value, name: item.name})))}
                                      multiSelect
                                      allowEmpty
                                      searchable
                                      formatSelectedLabel={formatSelectedLabel}
                            />
                        </div>
                    )}

                    <div className={`${styles.inputFilter} ${styles.col} ${styles.showOnMobile}`}>
                        {children}
                    </div>
                </div>

                {hasTags() && (
                    <Box className={`${styles.filterTags} row`}>
                        {combinedFilterValues.length ?
                            <Tags className={styles.tagsWrapper}
                                  items={combinedFilterValues}
                                  onClick={(tag: Tag) => toggleFilterItem(tag)}
                                  onDelete={(tag: Tag) => toggleFilterItem(tag)}
                                  onDeleteAll={() => clearFilters()}
                                  localizations={{deleteAllTagsTitle: Localizer.fleetMonitoringPageFiltersTagsRemoveAll}}
                            /> : null
                        }
                    </Box>
                )}

                <div className={`${styles.row} row`}>
                    <div className={`${styles.sortField} col-xl-4 col-sm-6 col-6 ${styles.col}`}>
                        <Dropdown id={`${idPrefix}sort-by`}
                                  items={sortByItems}
                                  label={Localizer.fleetMonitoringPageFiltersLabelsSortBy}
                                  selectedItem={sortBy}
                                  onChange={handleSortByChange}
                        />
                    </div>

                    <div className={`${styles.sortField} col-xl-4 col-sm-6 col-6 ${styles.col}`}>
                        <Dropdown id={`${idPrefix}sort-order`}
                                  items={sortOrderItems}
                                  label={Localizer.fleetMonitoringPageFiltersLabelsSortOrder}
                                  selectedItem={sortOrder}
                                  onChange={handleSortOrderChange}
                        />
                    </div>
                </div>
            </form>
        </>
    );
};

export default FiltersForm;