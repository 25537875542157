import React from "react";
import Localizer from "@/localization/Localizer";
import styles from "./Admin.module.scss";
import {Link, PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import PageDefinitions from "@/providers/PageDefinitions";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import RentaPage from "@/models/base/RentaPage";
import {FeatureSwitch} from "@/providers/FeatureSwitch";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

export interface IAdminParams extends BasePageParameters {
}

interface IAdminState {
}

export default class Admin extends RentaPage<IAdminParams, IAdminState> {

    public state: IAdminState = {};

    protected get title(): string {
        return Localizer.admin;
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer className={styles.admin}>
                <PageHeader title={Localizer.adminPageRentaAdminManager}
                            subtitle={this.userContext.username}
                />

                <PageRow>
                    <div className="col">
                        <div className={styles.menu} id={"admin_links"}>
                            <Link className={styles.menuLink}
                                  route={PageDefinitions.adminUserManagement.route()}>
                                {Localizer.adminPageUserManagement}
                            </Link>

                            <Link className={styles.menuLink}
                                  route={PageDefinitions.adminCompanyManagement.route()}>
                                {Localizer.adminPageBusinessManagement}
                            </Link>

                            <Link className={styles.menuLink}
                                  route={PageDefinitions.adminConstructionSiteManagement.route()}>
                                {Localizer.adminPageConstructionsitesManagement}
                            </Link>

                            <Link className={styles.menuLink}
                                  route={PageDefinitions.adminConstructionSitesMap.route()}>
                                {Localizer.adminConstructionSitesMapTitle}
                            </Link>

                            <Link className={styles.menuLink}
                                  route={PageDefinitions.adminDeviceTracking.route()}>
                                {Localizer.breadCrumbProductLocation}
                            </Link>

                            <Link className={styles.menuLink}
                                  route={PageDefinitions.adminLocations.route()}>
                                {Localizer.adminPageLocations}
                            </Link>

                            {
                                (this.userContext.isAdminWithAdminRole) &&
                                (
                                    <React.Fragment>
                                        <Link className={styles.menuLink}
                                              route={PageDefinitions.adminEasyPlusManagement.route()}>
                                            {Localizer.easyPlusManagementPageTitle}
                                        </Link>
                                        <Link className={styles.menuLink}
                                              route={PageDefinitions.adminProductManagement.route()}>
                                            {Localizer.genericProducts}
                                        </Link>

                                        <Link className={styles.menuLink}
                                              route={PageDefinitions.adminNewsEditor.route()}>
                                            {Localizer.newsEditorPageTitle}
                                        </Link>

                                        <Link className={styles.menuLink}
                                              route={PageDefinitions.adminAddSupportRequestTypes.route()}>
                                            {Localizer.supportRequestTypeSupportRequestTypes}
                                        </Link>
                                        <FeatureSwitch flagName={RentaEasyConstants.featureFlagAlarms}>
                                            <Link className={styles.menuLink}
                                                  route={PageDefinitions.adminAlarms.route()}>
                                                {Localizer.alarmsPageTitle}
                                            </Link>
                                        </FeatureSwitch>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}