import React, {useMemo} from "react";
import { GeoLocation } from "@renta-apps/athenaeum-toolkit";

interface GoogleMapsLinkProps {
    className?: string;
    location: GeoLocation | null;
}

const GoogleMapsLink: React.FC<GoogleMapsLinkProps> = ({ className, location }) => {
    const googleMapsLink = React.useMemo(() => {
        if (!location) return null;

        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        const query = location.address && location.city
            ? `${encodeURIComponent(location.address)},${encodeURIComponent(location.city)}`
            : `${location.lat},${location.lon}`;

        if (isMobile) {
            // Use geo: URI scheme for mobile with lat/lon fallback.
            return location.address && location.city
                ? `https://www.google.com/maps/search/?api=1&query=${query}`
                : `geo:${location.lat},${location.lon}`;
        }

        // Use Google Maps URL for desktop.
        return `https://www.google.com/maps?q=${query}`;
    }, [location]);

    const locationText = useMemo(() => {
        if (!location) {
            return '-';
        }

        const {city, address, lat, lon} = location;
        return city && address ? `${address}, ${city}` : lat && lon ? `${lat}, ${lon}` : '-';
    }, [location]);

    if (!locationText || locationText === '-') {
        return <span data-cy="construction-site-location">-</span>;
    }

    return googleMapsLink ? (
        <a href={googleMapsLink} data-cy="google_maps_link" className={`athenaeum-link-link ${className ?? ''}`}>
            {locationText}
        </a>
    ) : (
        <span data-cy="construction-site-location">{locationText}</span>
    );
};

export default GoogleMapsLink;