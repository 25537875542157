import React from "react";
import {PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import {ConstructionSiteModel} from "@/models/server/ConstructionSiteModel";
import ListConstructionSitesPagedRequest from "@/models/server/Requests/ListConstructionSitesPagedRequest";
import RentaPage from "@/models/base/RentaPage";
import ConstructionSitesGrid from "@/pages/AdminConstructionSitesOverview/ConstructionSitesGrid/ConstructionSitesGrid";
import PageDefinitions from "@/providers/PageDefinitions";
import { BaseGridPageParameters } from "@/models/BaseGridParameters";

export interface IAdminConstructionSitesOverviewParams extends BaseGridPageParameters {
    favorites?: boolean;
}

interface IAdminConstructionSitesOverviewState {
    isLoading: boolean;
}

export default class AdminConstructionSitesOverview extends RentaPage<IAdminConstructionSitesOverviewParams, IAdminConstructionSitesOverviewState> {

    public state: IAdminConstructionSitesOverviewState = {
        isLoading: false,
    };

    private async getConstructionSitesAsync(pageNumber: number,
                                            pageSize: number,
                                            sortColumnName: string | null,
                                            sortDirection: SortDirection | null,
                                            searchTerm: string | null,
                                            showOnlyFavorites: boolean | false
    ): Promise<IPagedList<ConstructionSiteModel>> {

        const request: ListConstructionSitesPagedRequest = {
            sortColumnName: sortColumnName,
            sortDirection: sortDirection,
            searchTerm: searchTerm,
            pageSize: pageSize,
            pageNumber: pageNumber,
            showOnlyFavorites: showOnlyFavorites
        };

        return await this.postAsync("/api/ConstructionSites/GetPagedConstructionSites", request);
    }

    protected get title(): string {
        return Localizer.adminPageConstructionsitesManagement;
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer>
                <PageHeader title={Localizer.adminPageRentaAdminManager}
                            subtitle={this.title}
                />

                <ConstructionSitesGrid
                    getConstructionSites={async (pageNumber, pageSize, sortColumnName, sortDirection, searchTerm, showOnlyFavorites) =>
                        await this.getConstructionSitesAsync(pageNumber, pageSize, sortColumnName, sortDirection, searchTerm, showOnlyFavorites)}
                    searchKeyword={this.typedParameters?.keyword}
                    pageNumber={this.typedParameters?.pageNumber}
                    pageSize={this.typedParameters?.pageSize}
                    showFavorites={this.typedParameters?.favorites}
                    sortDirection={this.typedParameters?.sortDir}
                    sortColumnName={this.typedParameters?.sortColumn}
                    renderFor={PageDefinitions.adminConstructionSiteManagement.route()}
                />
            </PageContainer>
        );
    }
}