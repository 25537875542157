import React, {useEffect, useMemo, useRef, useState} from "react";
import styles from "./FleetMonitoringContainer.module.scss";

import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import {IDropdownItem, Pagination} from "@renta-apps/renta-react-components";
import Localizer from "@/localization/Localizer";
import {ReturnRequestModel} from "@/models/server/ReturnRequestModel";
import {ReturnDetailsModel} from '@/models/server/ReturnDetailsModel';
import {getReturnDetailsAsync, getReturnsDetailsAsync, getReturnsPagedListAsync} from "@/services/FleetService";
import UserInteractionDataStorage, {DataStorageType} from "@/providers/UserInteractionDataStorage";

import ViewControl, {FleetMonitoringFilters} from "./ViewControl/ViewControl";
import ReturnRequestGrid from "./ReturnGrid/ReturnGrid";
import {FleetMonitoringFiltersAndPagination} from "./FleetMonitoringContainer";
import {getContractData} from "@/services/CompanyService";
import {OrganizationContractModel} from "@/models/server/OrganizationContractModel";
import isEqual from "lodash.isequal";

interface IReturnRequestsProps {
    urlParams?: FleetMonitoringFiltersAndPagination;
    userRoleConstructionSiteId: string | null;
    userRoleContractId: string | null;
    userRoleIsAdmin: boolean;
    onFilterParamsChange: (filters: FleetMonitoringFiltersAndPagination) => void;
}

const ReturnRequests: React.FC<IReturnRequestsProps> = (props: IReturnRequestsProps) => {
    const defaultPageNumber = 1;
    const defaultPageSize = 25;

    // Store user filter selections.
    const [paginationPageNumber, setPaginationPageNumber] = useState<number>(defaultPageNumber);
    const [paginationPageSize, setPaginationPageSize] = useState<number>(defaultPageSize);
    const [sortAndFilters, setSortAndFilters] = useState<FleetMonitoringFilters | undefined>(undefined);

    // Other state variables.
    const [filteredReturnRequests, setFilteredReturnRequests] = useState<ReturnRequestModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [totalItemCount, setTotalItemCount] = useState<number>(1);

    const [userCompaniesList, setUserCompaniesList] = useState<IDropdownItem[]>([]);
    
    const dataGridRef = useRef<HTMLDivElement>(null);

    const USER_DATA_STORAGE_KEY = useMemo(() => {
        return `ReturnRequestFilters-${props.userRoleContractId}-${props.userRoleConstructionSiteId}`;
    }, [props.userRoleContractId, props.userRoleConstructionSiteId]);

    useEffect(() => {
        let initialParams = props.urlParams;
        if (!initialParams) {
            initialParams = UserInteractionDataStorage.get(USER_DATA_STORAGE_KEY, new FleetMonitoringFiltersAndPagination(), DataStorageType.Page);
        }

        const initialFilters = {
            companies: initialParams!.companies ?? [],
            constructionSites: initialParams!.constructionSites ?? [],
            depots: initialParams!.depots ?? [],
            deviceNames: initialParams!.deviceNames ?? [],
            productGroups: initialParams!.productGroups ?? [],
            sortBy: initialParams!.sortBy ?? 'PickupTime',
            sortOrder: initialParams!.sortOrder ?? 'Asc',
            statuses: initialParams!.statuses ?? [],
        }
        const pageNumber = initialParams!.pageNumber === undefined ? defaultPageNumber : Number(initialParams!.pageNumber);
        const pageSize = initialParams!.pageSize === undefined ? defaultPageSize : Number(initialParams!.pageSize);

        loadContractDataList(props.userRoleContractId, props.userRoleIsAdmin).catch();
        
        setSortAndFilters(initialFilters);
        setPaginationPageNumber(pageNumber);
        setPaginationPageSize(pageSize);

        loadReturnRequests(initialFilters, pageNumber, pageSize, false).catch();
    }, []);

    const sortByItems: IDropdownItem[] = [
        {
            name: Localizer.fleetMonitoringPageFiltersSortByPickupTime,
            value: "PickupTime",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByStatus,
            value: "Status",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByCompany,
            value: "CustomerName",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByConstructionSite,
            value: "ConstructionSiteName",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByDepot,
            value: "Depot",
        },
    ];

    const handleOnFilter = (filters: FleetMonitoringFilters) => {
        if (isEqual(filters, sortAndFilters)) {
            return;
        }

        setSortAndFilters(filters);
        setPaginationPageNumber(1);
        saveUserSelection(filters, 1, paginationPageSize);
        loadReturnRequests(filters, 1, paginationPageSize, false).catch();
    }

    const handleOnPageNumberChange = (pageNumber: number) => {
        setPaginationPageNumber(pageNumber);
        saveUserSelection(sortAndFilters!, pageNumber, paginationPageSize);
        loadReturnRequests(sortAndFilters!, pageNumber, paginationPageSize, true).catch();
    };

    const handleOnPageSizeChange = (pageSize: number) => {
        setPaginationPageSize(pageSize);
        saveUserSelection(sortAndFilters!, paginationPageNumber, pageSize);
        loadReturnRequests(sortAndFilters!, paginationPageNumber, pageSize, true).catch();
    };

    const loadReturnRequests = async (filters: FleetMonitoringFilters, pageNumber: number, pageSize: number, scrollToTop: boolean) => {
        setIsLoading(true);

        try {
            const filteredReturnRequests: IPagedList<ReturnRequestModel> = await getReturnsPagedListAsync(
                pageNumber,
                pageSize,
                (props.userRoleIsAdmin || !props.userRoleContractId) ? undefined : props.userRoleContractId,
                (props.userRoleIsAdmin || !props.userRoleConstructionSiteId) ? undefined : props.userRoleConstructionSiteId,
                filters.sortBy,
                SortDirection[filters.sortOrder as keyof typeof SortDirection],
                filters.deviceNames,
                filters.companies,
                filters.constructionSites,
                filters.depots,
                filters.productGroups,
                filters.statuses,
            );
            setFilteredReturnRequests(filteredReturnRequests.items);

            // Extract pagination properties.
            const { totalItemCount } = filteredReturnRequests;
            setTotalItemCount(totalItemCount);

        } catch (error) {
            console.error('Error loading devices:', error);
        } finally {
            setIsLoading(false);
            if (scrollToTop && dataGridRef.current) {
                // 45 - height of the top nav bar
                window.scrollTo({ top: dataGridRef.current.offsetTop - 45, behavior: 'smooth' });
            }
        }
    };

    const loadContractDataList = async (userRoleContractId: string | null, userRoleIsAdmin: boolean) => {
        if (userRoleIsAdmin || !userRoleContractId) {
            setUserCompaniesList([]);
            return;
        }

        try {
            const contractData = await getContractData(userRoleContractId);
            const companiesList = [mapCompanyToDropdownItem(contractData.organizationContract!)]
                .concat(contractData.organizationContract?.children?.map(mapCompanyToDropdownItem) ?? []);

            setUserCompaniesList(companiesList);
        } catch (error) {
            console.error('Error loading contract data:', error);
        }
    };

    const mapCompanyToDropdownItem = (company: OrganizationContractModel): IDropdownItem => {
        return {
            name: `${company.name ?? ''} ${company.customerNumber ? `(${company.customerNumber})` : ''}`,
            value: company.contractId,
        };
    };


    const loadReturnRequestDetails = async (id: string): Promise<ReturnDetailsModel> => {
        try {
            return await getReturnDetailsAsync(id);
        } catch (error) {
            console.error('Error loading return request details:', error);
            throw error;
        }
    };

    const loadReturnRequestsDetails = async (ids: string[]): Promise<ReturnDetailsModel[]> => {
        try {
            return await getReturnsDetailsAsync(ids);
        } catch (error) {
            console.error('Error loading return requests details:', error);
            throw error;
        }
    };

    const saveUserSelection = (filters: FleetMonitoringFilters, pageNumber: number, pageSize: number) => {
        const params = new FleetMonitoringFiltersAndPagination(
            filters.companies,
            filters.constructionSites,
            filters.depots,
            filters.productGroups,
            filters.statuses,
            filters.deviceNames,
            pageNumber,
            pageSize,
            filters.sortBy,
            filters.sortOrder
        );
        props.onFilterParamsChange(params);

        UserInteractionDataStorage.set(USER_DATA_STORAGE_KEY, params, DataStorageType.Page);
    };

    return (
        <div className={styles.devices}>
            <div id="container" className={styles.container}>
                {sortAndFilters && (
                    <ViewControl
                        userRoleConstructionSiteId={props.userRoleConstructionSiteId}
                        userRoleContractId={props.userRoleContractId}
                        userRoleIsAdmin={props.userRoleIsAdmin}
                        onFilterAndSort={handleOnFilter}
                        filters={sortAndFilters}
                        sortByItems={sortByItems}
                        userCompaniesList={userCompaniesList}
                    />
                )}

                <ReturnRequestGrid
                    returnRequests={filteredReturnRequests}
                    returnRequestDetails={(id: string) => loadReturnRequestDetails(id)}
                    returnRequestsDetails={(ids: string[]) => loadReturnRequestsDetails(ids)}
                    isLoading={isLoading}
                    gridRef={dataGridRef}
                />

                <Pagination
                    pageNumber={paginationPageNumber}
                    pageSize={paginationPageSize}
                    totalItemCount={totalItemCount}
                    onPageNumberChange={(pageNumber: number) => handleOnPageNumberChange(pageNumber)}
                    onPageSizeChange={(pageSize: number) => handleOnPageSizeChange(pageSize)}
                />
            </div>
        </div>
    );
};

export default ReturnRequests;