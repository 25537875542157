import React, {useEffect, useMemo, useState} from "react";
import styles from "./SubscribeToAlertsModal.module.scss";

import {ApplicationContext, ch} from "@renta-apps/athenaeum-react-common";
import {Modal, ModalSize, Spinner} from "@renta-apps/athenaeum-react-components";
import {Button, ButtonType, Checkbox} from '@renta-apps/renta-react-components';

import {validatePhoneNumber} from "@/helpers/Validators";
import Localizer from "@/localization/Localizer";
import {AlarmMediaType, AlarmType} from "@/models/Enums";
import AlarmModel from "@/models/server/AlarmModel";
import {DeviceModel} from "@/models/server/DeviceModel";
import UserContext from "@/models/server/UserContext";
import {createDeviceUnusedAlarmAsync, createFuelAlarmAsync, createTheftAlarmAsync, createVoltageAlarmAsync, deleteAlarmAsync} from "@/services/AlarmService";
import {saveUserAlarmMediaType} from "@/services/UserService";
import {IDeletedAlarm, INewAlarm} from "../FleetMonitoringContainer";
import AlertsConfigResponse from "@/models/server/Responses/AlertsConfigResponse";
import CustomSlider from "@/components/CustomeSlider/CustomSlider";

type SubscribeToAlertsModalProps = {
    isOpen: boolean;
    alertsConfig: AlertsConfigResponse;
    selectedDevices: DeviceModel[];
    onClose(): void;
    removeDeletedAlarms: (deletedAlarms: IDeletedAlarm[]) => void;
    saveNewAlarms: (newAlarms: INewAlarm[]) => void;
}

const SubscribeToAlertsModal: React.FC<SubscribeToAlertsModalProps> = ({isOpen, alertsConfig, selectedDevices, onClose, removeDeletedAlarms, saveNewAlarms}) => {
    const CHECKBOX_PARTIAL = "partial";
    const _modalRef: React.RefObject<Modal> = React.createRef();

    const [isFuelChecked, setIsFuelChecked] = useState<boolean | typeof CHECKBOX_PARTIAL>(false);
    const [isBatteryChecked, setIsBatteryChecked] = useState<boolean | typeof CHECKBOX_PARTIAL>(false);
    const [isTheftChecked, setIsTheftChecked] = useState<boolean | typeof CHECKBOX_PARTIAL>(false);
    const [isIdleChecked, setIsIdleChecked] = useState<boolean | typeof CHECKBOX_PARTIAL>(false);
    const [isSmsNotificationChecked, setIsSmsNotificationChecked] = useState<boolean>(false);
    const [isEmailNotificationChecked, setIsEmailNotificationChecked] = useState<boolean>(false);

    const [numberOfDevices, setNumberOfDevices] = useState(0);
    const [numberOfDevicesFuel, setNumberOfDevicesFuel] = useState(0);
    const [numberOfDevicesBattery, setNumberOfDevicesBattery] = useState(0);
    const [numberOfDevicesTheft, setNumberOfDevicesTheft] = useState(0);
    const [numberOfDevicesIdle, setNumberOfDevicesIdle] = useState(0);

    const [fuelPercentage, setFuelPercentage] = useState<number>(20); // Default to 20

    const [isBeingSaved, setIsBeingSaved] = useState(false);

    const context: ApplicationContext = ch.getContext();
    const userContext = context as UserContext;

    const {alarmMediaType, constructionSiteId, contractId, isPhoneNumberNumberInvalid} = useMemo(() => {

        return {
            alarmMediaType: userContext.user?.alarmMediaType ?? null,
            constructionSiteId: userContext.selectedConstructionSiteId,
            contractId: userContext.selectedContractId,
            isPhoneNumberNumberInvalid: !!validatePhoneNumber(userContext.user?.phoneNumber ?? null),
        };
    }, [userContext]);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        const numberOfFuelConfigured = selectedDevices.filter(device => configuredAlertByType(device.configuredAlerts, AlarmType.TrackUnitFuelLevelLessThan)).length;
        const numberOfBatteryConfigured = selectedDevices.filter(device => configuredAlertByType(device.configuredAlerts, AlarmType.TrackUnitBatteryVoltageLessThan)).length;
        const numberOfTheftConfigured = selectedDevices.filter(device => configuredAlertByType(device.configuredAlerts, AlarmType.TrackUnitMovementBasedTheft)).length;
        const numberOfIdleConfigured = selectedDevices.filter(device => configuredAlertByType(device.configuredAlerts, AlarmType.DeviceUnusedFor3Days)).length;

        const numberOfDevicesFuel = selectedDevices.filter(device => DeviceModel.supportsFuelAlerts(device)).length;
        const numberOfDevicesBattery = selectedDevices.filter(device => DeviceModel.supportsBatteryAlerts(device)).length;
        const numberOfDevicesTheft = selectedDevices.filter(device => DeviceModel.supportsTheftAlerts(device)).length;
        const numberOfDevicesIdle = selectedDevices.filter(device => DeviceModel.supportsIdleAlerts(device)).length;

        setIsFuelChecked(setIsChecked(numberOfFuelConfigured, numberOfDevicesFuel));
        setIsBatteryChecked(setIsChecked(numberOfBatteryConfigured, numberOfDevicesBattery));
        setIsTheftChecked(setIsChecked(numberOfTheftConfigured, numberOfDevicesTheft));
        setIsIdleChecked(setIsChecked(numberOfIdleConfigured, numberOfDevicesIdle));

        setNumberOfDevices(selectedDevices.length);
        setNumberOfDevicesFuel(numberOfDevicesFuel);
        setNumberOfDevicesBattery(numberOfDevicesBattery);
        setNumberOfDevicesTheft(numberOfDevicesTheft);
        setNumberOfDevicesIdle(numberOfDevicesIdle);

        setAlarmType(alarmMediaType);

    }, [alarmMediaType, selectedDevices, isOpen]);

    useEffect(() => {
        if (isOpen) {
            _modalRef.current?.openAsync();
        }
        else {
            _modalRef.current?.closeAsync();
        }
    }, [_modalRef, isOpen]);

    const configuredAlertByType = (configuredAlerts: AlarmModel[], type: AlarmType) => {
        return configuredAlerts.find(alert => alert.alarmType === type);
    };

    const handleFuelAlert = async (device: DeviceModel, deviceFuelAlert: AlarmModel | undefined): Promise<AlarmModel | null> => {
        if (DeviceModel.supportsFuelAlerts(device) && isFuelChecked && !deviceFuelAlert) {
            return await createFuelAlarmAsync(device.rentaId, device.fuelSensorType!, constructionSiteId, contractId, fuelPercentage);
        }
        return null;
    };
    const handleBatteryAlert = async (device: DeviceModel, deviceBatteryAlert: AlarmModel | undefined): Promise<AlarmModel | null> => {
        if (DeviceModel.supportsBatteryAlerts(device) && isBatteryChecked && !deviceBatteryAlert) {
            return await createVoltageAlarmAsync(device.rentaId, constructionSiteId, contractId);
        }

        return null;
    };

    const handleTheftAlert = async (device: DeviceModel, deviceTheftAlert: AlarmModel | undefined): Promise<AlarmModel | null> => {
        if (DeviceModel.supportsTheftAlerts(device) && isTheftChecked && !deviceTheftAlert) {
            return await createTheftAlarmAsync(device.rentaId, constructionSiteId, contractId);
        }

        return null;
    };

    const handleIdleAlert = async (device: DeviceModel, deviceIdleAlert: AlarmModel | undefined): Promise<AlarmModel | null> => {
        if (DeviceModel.supportsIdleAlerts(device) && isIdleChecked && !deviceIdleAlert) {
            return await createDeviceUnusedAlarmAsync(device.rentaId, constructionSiteId, contractId);
        }

        return null;
    };

    const handleNotificationSettings = async (): Promise<void> => {
        if (isSmsNotificationChecked && isEmailNotificationChecked) {
            await saveUserAlarmMediaType(AlarmMediaType.EmailAndSms);
        }
        else if (isSmsNotificationChecked) {
            await saveUserAlarmMediaType(AlarmMediaType.Sms);
        }
        else if (isEmailNotificationChecked) {
            await saveUserAlarmMediaType(AlarmMediaType.Email);
        }
        else {
            await saveUserAlarmMediaType(AlarmMediaType.None);
        }
    };

    const isSingleDeviceMode = () => {
        return numberOfDevices === 1;
    };

    const isFuelSupported = () => {
        return numberOfDevicesFuel === 1 || !isSingleDeviceMode();
    };

    const isBatterySupported = () => {
        return numberOfDevicesBattery === 1 || !isSingleDeviceMode();
    };

    const isTheftSupported = () => {
        return numberOfDevicesTheft === 1 || !isSingleDeviceMode();
    };

    const isIdleSupported = () => {
        return numberOfDevicesIdle === 1 || !isSingleDeviceMode();
    };

    const saveChangesAsync = async () => {
        setIsBeingSaved(true);

        const newAlarms: INewAlarm[] = [];
        const deletedAlarms: IDeletedAlarm[] = [];

        for (const device of selectedDevices) {
            const deviceFuelAlert = configuredAlertByType(device.configuredAlerts, AlarmType.TrackUnitFuelLevelLessThan);
            const deviceBatteryAlert = configuredAlertByType(device.configuredAlerts, AlarmType.TrackUnitBatteryVoltageLessThan);
            const deviceTheftAlert = configuredAlertByType(device.configuredAlerts, AlarmType.TrackUnitMovementBasedTheft);
            const deviceIdleAlert = configuredAlertByType(device.configuredAlerts, AlarmType.DeviceUnusedFor3Days);

            const newFuelAlarm = await handleFuelAlert(device, deviceFuelAlert);
            if (newFuelAlarm) {
                newAlarms.push({configuredAlert: newFuelAlarm, deviceId: device.rentaId});
            }

            const newBatteryAlarm = await handleBatteryAlert(device, deviceBatteryAlert);
            if (newBatteryAlarm) {
                newAlarms.push({configuredAlert: newBatteryAlarm, deviceId: device.rentaId});
            }

            const newTheftAlarm = await handleTheftAlert(device, deviceTheftAlert);
            if (newTheftAlarm) {
                newAlarms.push({configuredAlert: newTheftAlarm, deviceId: device.rentaId});
            }

            const newIdleAlarm = await handleIdleAlert(device, deviceIdleAlert);
            if (newIdleAlarm) {
                newAlarms.push({configuredAlert: newIdleAlarm, deviceId: device.rentaId});
            }

            if (DeviceModel.supportsFuelAlerts(device) && !isFuelChecked && deviceFuelAlert) {
                await deleteAlarmAsync(deviceFuelAlert.id);
                deletedAlarms.push({alarmId: deviceFuelAlert.id, deviceId: device.rentaId});
            }

            if (DeviceModel.supportsBatteryAlerts(device) && !isBatteryChecked && deviceBatteryAlert) {
                await deleteAlarmAsync(deviceBatteryAlert.id);
                deletedAlarms.push({alarmId: deviceBatteryAlert.id, deviceId: device.rentaId});
            }

            if (DeviceModel.supportsTheftAlerts(device) && !isTheftChecked && deviceTheftAlert) {
                await deleteAlarmAsync(deviceTheftAlert.id);
                deletedAlarms.push({alarmId: deviceTheftAlert.id, deviceId: device.rentaId});
            }

            if (DeviceModel.supportsIdleAlerts(device) && !isIdleChecked && deviceIdleAlert) {
                await deleteAlarmAsync(deviceIdleAlert.id);
                deletedAlarms.push({alarmId: deviceIdleAlert.id, deviceId: device.rentaId});
            }
        }

        await handleNotificationSettings();

        removeDeletedAlarms(deletedAlarms);
        saveNewAlarms(newAlarms);

        setIsBeingSaved(false);
        onClose();
    };

    const setAlarmType = (alarmMediaType: AlarmMediaType | null) => {
        switch (alarmMediaType) {
            case AlarmMediaType.Email:
                setIsEmailNotificationChecked(true);
                setIsSmsNotificationChecked(false);
                break;
            case AlarmMediaType.Sms:
                setIsEmailNotificationChecked(false);
                setIsSmsNotificationChecked(true);
                break;
            case AlarmMediaType.EmailAndSms:
                setIsEmailNotificationChecked(true);
                setIsSmsNotificationChecked(true);
                break;
            default:
                setIsEmailNotificationChecked(false);
                setIsSmsNotificationChecked(false);
                break;
        }
    };

    const setIsChecked = (numberOfConfigured: number, numberOfSupported: number) => {
        // If the user has alerts active for all selected equipment, show the checkbox as checked.
        // If user has alerts active for some selected equipment, show the checkbox as partially selected.
        // If user has no alerts active for the selected equipment, show the checkbox as unchecked.
        return numberOfConfigured === 0 ? false : numberOfConfigured === numberOfSupported ? true : CHECKBOX_PARTIAL;
    };
    let rowIndex = 0;
    return (
        isOpen ? (
            <Modal
                preventEsc preventClosingOnOutsideClick preventClosingOnInsideClick
                ref={_modalRef}
                id="subscribeToAlertsModal"
                size={ModalSize.Auto}
                title={Localizer.fleetMonitoringPageSubscribeToAlertsModalTitle.toUpperCase()}
                onClose={async () => {
                    onClose();
                }}
            >
                <div className={styles.mainContainer}>
                    <div className={`${styles.activateAlerts} ${isBeingSaved ? styles.transparentForm : ''}`}>
                        <div className={styles.titleArea}>
                            {isSingleDeviceMode() ? (
                                <span className={styles.title}>
                                    {Localizer.fleetMonitoringPageSubscribeToAlertsModalHeaderTextPieceOfEquipment}
                                </span>
                            ) : (
                                <>
                                    <span className={styles.title}>
                                        {Localizer.fleetMonitoringPageSubscribeToAlertsModalHeaderTextSelectedEquipment}
                                    </span>
                                    <span className={styles.subTitle}>
                                        {Localizer.fleetMonitoringPageSubscribeToAlertsModalSubHeaderText}
                                    </span>
                                </>
                            )}
                        </div>
                        <div className={styles.dataTable}>
                            <div className={styles.dataRows}>
                                {isFuelSupported() && (
                                    <div className={`${styles.dataRow} ${rowIndex++ % 2 === 0 ? styles.odd : ''}`}>
                                        <div className={styles.checkboxCell}>
                                            <Checkbox
                                                id="fuelCheckbox"
                                                checked={isFuelChecked}
                                                onChange={(value: boolean) => setIsFuelChecked(value)}
                                                disabled={isBeingSaved}
                                            />
                                        </div>
                                        <div className={styles.firstRow}>{Localizer.fleetMonitoringPageSubscribeToAlertsModalFuelLow}</div>
                                        <div className={`${styles.additionalInfo} ${!isFuelChecked ? styles.noPadding: ''} `}>
                                            {!isSingleDeviceMode() && (
                                                <span>
                                                    {Localizer.get(
                                                        Localizer.fleetMonitoringPageSubscribeToAlertsModalSupportedOfSelected,
                                                        numberOfDevicesFuel,
                                                        numberOfDevices
                                                    )}
                                                </span>
                                            )}

                                            {isFuelSupported() && isFuelChecked && (
                                                <div className={styles.sliderContainer}>
                                                    <label htmlFor="fuelPercentageInput"><b>{Localizer.fleetMonitoringPageSubscribeToAlertsModalFuelLowDescription}</b></label>
                                                    <CustomSlider
                                                        min={10}
                                                        max={90}
                                                        step={5}
                                                        value={fuelPercentage}
                                                        onChange={(value: number) => setFuelPercentage(value)}
                                                        disabled={isBeingSaved}
                                                        unit="%"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}



                                {isBatterySupported() && (
                                    <div className={`${styles.dataRow} ${rowIndex++ % 2 === 0 ? styles.odd : ''}`}>
                                        <div className={styles.checkboxCell}>
                                            <Checkbox
                                                id="batteryCheckbox"
                                                checked={isBatteryChecked}
                                                onChange={(value: boolean) => setIsBatteryChecked(value)}
                                                disabled={isBeingSaved}
                                            />
                                        </div>
                                        <div className={styles.firstRow}>{Localizer.fleetMonitoringPageSubscribeToAlertsModalBatteryLow}</div>
                                        <div className={styles.additionalInfo}>
                                            <span data-cy="battery-low-description">
                                                {Localizer.fleetMonitoringPageSubscribeToAlertsModalBatteryLowDescription}
                                            </span>
                                            {!isSingleDeviceMode() && (
                                                <span>
                                                    {Localizer.get(
                                                        Localizer.fleetMonitoringPageSubscribeToAlertsModalSupportedOfSelected,
                                                        numberOfDevicesBattery,
                                                        numberOfDevices
                                                    )}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {isTheftSupported() && (
                                    <div className={`${styles.dataRow} ${rowIndex++ % 2 === 0 ? styles.odd : ''}`}>
                                        <div className={styles.checkboxCell}>
                                            <Checkbox
                                                id="theftCheckbox"
                                                checked={isTheftChecked}
                                                onChange={(value: boolean) => setIsTheftChecked(value)}
                                                disabled={isBeingSaved}
                                            />
                                        </div>
                                        <div className={styles.firstRow}>{Localizer.fleetMonitoringPageSubscribeToAlertsModalOffHours}</div>
                                        <div className={styles.additionalInfo}>
                                            <span>{Localizer.fleetMonitoringPageSubscribeToAlertsModalWorkHours}</span>
                                            {!isSingleDeviceMode() && (
                                                <span>
                                                    {Localizer.get(
                                                        Localizer.fleetMonitoringPageSubscribeToAlertsModalSupportedOfSelected,
                                                        numberOfDevicesTheft,
                                                        numberOfDevices
                                                    )}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {isIdleSupported() && (
                                    <div className={`${styles.dataRow} ${rowIndex++ % 2 === 0 ? styles.odd : ''}`}>
                                        <div className={styles.checkboxCell}>
                                            <Checkbox
                                                id="idleCheckbox"
                                                checked={isIdleChecked}
                                                onChange={(value: boolean) => setIsIdleChecked(value)}
                                                disabled={isBeingSaved}
                                            />
                                        </div>
                                        <div className={styles.firstRow}>{Localizer.fleetMonitoringPageSubscribeToAlertsModalIdle}</div>
                                        <div className={styles.additionalInfo}>
                                            <span data-cy="idle-description">
                                                {Localizer.fleetMonitoringPageSubscribeToAlertsModalIdleDescription}
                                            </span>
                                            {!isSingleDeviceMode() && (
                                                <span>
                                                    {Localizer.get(
                                                        Localizer.fleetMonitoringPageSubscribeToAlertsModalSupportedOfSelected,
                                                        numberOfDevicesIdle,
                                                        numberOfDevices
                                                    )}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.alertsSettings} ${isBeingSaved ? styles.transparentForm : ''}`}>
                        <div className={styles.alertsSettingsTitle}>
                            <span className={styles.title}>{Localizer.fleetMonitoringPageSubscribeToAlertsModalGlobalAlertSettings}</span>
                            <span className={styles.subTitle}>{Localizer.fleetMonitoringPageSubscribeToAlertsModalChangingSettings}</span>
                        </div>
                        <div className={styles.formFields}>
                            <div className={styles.checkboxNotification}>
                                <Checkbox
                                    id="smsNotifications"
                                    label={Localizer.fleetMonitoringPageSubscribeToAlertsModalReceiveSms}
                                    checked={isSmsNotificationChecked}
                                    onChange={(value: boolean) => setIsSmsNotificationChecked(value)}
                                    disabled={isPhoneNumberNumberInvalid}
                                />
                                {isPhoneNumberNumberInvalid && <div className={styles.invalidPhoneNumber}>{Localizer.fleetMonitoringPageSubscribeToAlertsModalInvalidPhoneNumber}</div>}
                            </div>
                            <div className={styles.checkboxNotification}>
                                <Checkbox
                                    id="emailNotifications"
                                    label={Localizer.fleetMonitoringPageSubscribeToAlertsModalReceiveEmail}
                                    checked={isEmailNotificationChecked}
                                    onChange={(value: boolean) => setIsEmailNotificationChecked(value)}
                                />
                            </div>
                        </div>
                    </div>

                    {!isBeingSaved && <div id="subscribeToAlertsModalActionButtons" className={styles.activeButtons}>
                        <Button type={ButtonType.Secondary} onClick={() => {
                            onClose();
                        }}>{Localizer.componentModalCancel}</Button>
                        <Button type={ButtonType.Primary} onClick={async () => {
                            await saveChangesAsync();
                        }}>{Localizer.componentModalSaveChanges}</Button>
                    </div>
                    }

                    {isBeingSaved && <div id="subscribeToAlertsModalActionLoader" className={styles.activeButtons}>
                        <div className={styles.spinnerContainer}>
                            <Spinner noShading/>
                        </div>
                        <div className={styles.textContainer}>
                            <span>{Localizer.fleetMonitoringPageSubscribeToAlertsModalPleaseWait}</span>
                        </div>
                    </div>
                    }
                </div>
            </Modal>
        ) : null
    );
};

export default SubscribeToAlertsModal;