import React from "react";
import {
    Accordion,
    Button,
    ButtonType,
    Checkbox,
    ConfirmationDialog,
    Icon,
    IconSize,
    IconStyle,
    Inline,
    InlineType,
    INumberInputModel,
    IStringInputModel, Link,
    Modal,
    NumberInput,
    NumberInputBehaviour,
    OneColumn,
    SelectListItem,
    TextInput,
    ThreeColumns,
    TwoColumns
} from "@renta-apps/athenaeum-react-components";
import {AlertModel, BaseAsyncComponent, ch, IBaseAsyncComponentState, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import ProductModel from "@/models/server/ProductModel";
import ProductAvailabilityRequest from "@/models/server/ProductAvailabilityRequest";
import ShoppingCartProductModel from "@/models/server/ShoppingCartProductModel";
import FavoriteProductRequest from "@/models/server/FavoriteProductRequest";
import UserContext from "@/models/server/UserContext";
import {ProductType} from "@/models/Enums";
import ProductConfirmationModal, {ProductConfirmationResult} from "@/components/ProductConfirmationModal/ProductConfirmationModal";
import PageDefinitions from "@/providers/PageDefinitions";
import InlineTooltip from "@/components/InlineTooltip/InlineTooltip";
import ImageProvider from "@/providers/ImageProvider";
import ProductTagModel from "@/models/server/ProductTagModel";
import Cloner from "@/helpers/Cloner";
import GetRelatedProductsRequest from "@/models/server/GetRelatedProductsRequest";
import RelatedProductsResponse from "@/models/server/Responses/RelatedProductsResponse";
import ProductModelInfoList from "@/components/ProductModelInfoList/ProductModelInfoList";
import ProductVatButtons from "@/components/ProductVatButtons/ProductVatButtons";
import RentaEasyController from "@/pages/RentaEasyController";
import PriceHelper from "@/helpers/PriceHelper";
import Localizer from "@/localization/Localizer";
import styles from "@/components/Product/Product.module.scss";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import UnleashHelper from "@/helpers/UnleashHelper";
import Co2DefinitionModal from "@/components/Co2DefinitionModal/Co2DefinitionModal";
import Prices from "@/components/Prices/Prices";
import AttachedProducts from "@/components/AttachedProducts/AttachedProducts";
import {FeatureSwitch} from "@/providers/FeatureSwitch";
import ChangeProductParentCategoryModal from "@/components/Product/Modals/ChangeProductParentCategoryModal";
import ChangeDiscountModal from "@/components/Product/Modals/ChangeDiscountModal";
import CategoryAttributeKeyModel from "@/models/server/CategoryAttributeKeyModel";

interface IProductProps {
    product: ShoppingCartProductModel;
    depotId: string | null;
    depotExternalId: string | null;
    depotName: string | null;
    customerPrices: boolean;
    confirmation: ProductConfirmationModal;
    editMode?: boolean;
    pricingToolMode: boolean;
    discountId?: string | null;
    categoryKeys: CategoryAttributeKeyModel[];

    onProductDeleted(value: ShoppingCartProductModel): Promise<void>;

    onProductMoved(value: ShoppingCartProductModel): Promise<void>;

    onProductDiscountChangeAsync?(product: ProductModel): Promise<void>;

    onItemChange(item: ShoppingCartProductModel): Promise<void>;
}

interface IProductState extends IBaseAsyncComponentState<boolean> {
    showCustomerPrices: boolean;
    favoriteProducts: string[];
    amount: number;
    alertModel: AlertModel | null;
    categoriesSelectList: SelectListItem[];
    discountPercentage: number;
    isChangeParentModalOpen: boolean;
    isDiscountModalOpen: boolean;
}

export default class Product extends BaseAsyncComponent<IProductProps, IProductState, {}> {
    private readonly _moveProductModalRef: React.RefObject<Modal> = React.createRef();
    private readonly _deleteProductModalRef: React.RefObject<ConfirmationDialog> = React.createRef();
    private readonly _editCo2DefinitionsModalRef: React.RefObject<Co2DefinitionModal> = React.createRef();

    private authenticatedUsersOnly = UnleashHelper.isEnabled(RentaEasyConstants.featureFlagPriceForAuthenticatedUsersOnly);

    public state: IProductState = {
        isLoading: false,
        data: null,
        showCustomerPrices: this.props.customerPrices,
        favoriteProducts: this.favoriteProducts,
        amount: 1,
        alertModel: null,
        categoriesSelectList: [],
        discountPercentage: 0.0,
        isChangeParentModalOpen: false,
        isDiscountModalOpen: false
    };

    private _amount: INumberInputModel = {value: 1};
    private _relatedProducts: IStringInputModel = {value: this.product?.relatedProducts?.map(o => o.externalId).join(',') ?? ""};

    public isAsync(): boolean {
        return true;
    }

    protected async fetchDataAsync(): Promise<boolean> {
        const endpoint: string = this.getEndpoint();

        if (!this.userContext.isAdmin && this.product.showAsAvailable) {
            return true;
        }
        const request: ProductAvailabilityRequest = {
            rentalObjectId: this.product.rentalObjectId!,
            rentalObjectNumber: this.product.rentalObjectNumber!,
            depotExternalId: this.props.depotExternalId,
            productType: this.product.productType
        };

        return await this.postAsync(endpoint, request);
    }

    public get hasUserContext(): boolean {
        return !!this.userContext;
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    protected getEndpoint(): string {
        return "/api/Product/ProductDepotAvailability";
    }

    private get favoriteProducts(): string[] {
        return (this.hasUserContext && this.userContext.user)
        && (this.userContext.user.favoriteProductsIds !== undefined)
        && (this.userContext.user.favoriteProductsIds !== null)
            ? this.userContext.user.favoriteProductsIds
            : [];
    }

    private get isFavorite(): boolean {
        return (this.hasUserContext)
            && (this.userContext.user!.favoriteProductsIds !== undefined)
            && (this.userContext.user!.favoriteProductsIds !== null)
            && (this.userContext.user!.favoriteProductsIds.includes(this.product.id));
    }

    private get isAvailable(): boolean {
        return !!this.state.data;
    }

    private get shoppingCartProduct(): ShoppingCartProductModel {
        if (this.props.product) {
            return this.props.product;
        }

        throw new Error("Shopping cart product is null or undefined");
    }

    private get hasShoppingCartProduct(): boolean {
        return (this.props.product !== undefined) && (this.props.product !== null);
    }

    private get product(): ProductModel {
        if (this.shoppingCartProduct.product) {
            return this.shoppingCartProduct.product;
        }

        throw new Error("Product is null or undefined");
    }

    private get hasProduct(): boolean {
        return (this.hasShoppingCartProduct) && (this.shoppingCartProduct.product !== undefined) && (this.shoppingCartProduct.product !== null);
    }

    private get imageSrc(): string {
        return ImageProvider.getProductThumbnailSrc(this.product);
    }

    private get hasDiscountedPrices(): boolean {
        return ((this.product.customerDailyPrice !== undefined) && (this.product.customerDailyPrice !== null)) ||
            ((this.product.customerMonthlyPrice !== undefined) && (this.product.customerMonthlyPrice !== null));
    }

    private get vat(): number {
        return this.state.showCustomerPrices
            ? 0
            : PriceHelper.environmentVat;

    }

    private get salesItemsEnabled(): boolean {
        return UnleashHelper.isEnabled(RentaEasyConstants.featureFlagSalesItems);
    }

    private async navigateToProductDetails(): Promise<void> {

        if (this.props.pricingToolMode) {
            return;
        }

        const params = this.props.editMode ? { editMode: this.props.editMode } : undefined;

        await PageRouteProvider.redirectAsync(
            PageDefinitions.productDetails.route({
                id: this.product.url!,
                params,
            })
        );

    }

    private async openDiscountModalAsync(): Promise<void> {
        this.setState({isDiscountModalOpen: true});
    }

    private async closeDiscountModalAsync(status: boolean): Promise<void> {
        this.setState({isDiscountModalOpen: false});
        if (status) {
            await this.props.onItemChange(this.props.product);
        }
    }

    private async showCustomerPrices(customerPrices: boolean): Promise<void> {
        this.setState({showCustomerPrices: customerPrices});
    }

    private async addOrDeleteFavoriteAsync(id: string, isAlreadyInFavorites: boolean): Promise<void> {

        const endpoint: string = RentaEasyController.getFavoriteOperationEndpoint(isAlreadyInFavorites);

        const request: FavoriteProductRequest = {
            productId: id,
            userEmail: this.userContext.username
        };

        const favoriteProducts: string[] = await this.postAsync(endpoint, request);

        this.setState({favoriteProducts: favoriteProducts});
    }

    private async saveProductDetailsAsync(): Promise<void> {
        await this.setState({
            isLoading: true
        });

        const editedProduct: ProductModel = Cloner.clone(this.product);
        editedProduct.relatedProducts = this._relatedProducts.value.split(",").map((externalId) => {
            const model = new ProductModel();
            model.externalId = externalId.trim();
            model.id = (this.product.relatedProducts ?? []).find(acc => acc.externalId === model.externalId)?.id ?? RentaEasyConstants.emptyGuid;
            return model;
        });

        await this.postAsync(`/api/Admin/SaveProduct`, editedProduct);

        if (editedProduct.attachedProducts?.some(product => product.id === RentaEasyConstants.emptyGuid)) {
            this.product.attachedProducts = await this.getAsync<ProductModel[]>(`/api/Admin/GetAttachedProducts?productId=${this.product.id}`);
        }

        await this.setState({
            isLoading: false
        });
    }

    private async addProductToCartAsync(): Promise<void> {

        if (this.shoppingCartProduct.relatedProducts.length <= 0) {

            const getRelatedProductsRequest: GetRelatedProductsRequest = {
                productId: this.shoppingCartProduct.product?.id ?? "",
                contractId: null // Filled in controller level
            };

            const response: RelatedProductsResponse = await RentaEasyController.getRelatedProductsAsync(getRelatedProductsRequest);
            const relatedProducts: ShoppingCartProductModel[] = RelatedProductsResponse.Transform(response.products);
            this.shoppingCartProduct.relatedProducts.push(...relatedProducts);
        }

        const confirmationResult: ProductConfirmationResult = await this.props.confirmation.confirmAsync(this.shoppingCartProduct, this.state.amount);

        if (confirmationResult === ProductConfirmationResult.GoToShoppingCart) {
            await PageRouteProvider.redirectAsync(PageDefinitions.shoppingCart.route());
        }
    }

    private async onAmountChangeHandlerAsync(value: number): Promise<void> {
        this.setState({amount: value});
    }

    private async setExternalIdAsync(value: string): Promise<void> {
        this.product.externalId = value;
    }

    private async setProductNameAsync(value: string): Promise<void> {
        this.product.name = value;
    }

    private async setUnitPriceAsync(item: ProductModel, value: number): Promise<void> {
        item.unitPrice = value;
    }

    private async setUnitTypeAsync(item: ProductModel, value: string): Promise<void> {
        item.unitType = value;
    }

    private async setWeeklyPriceAsync(item: ProductModel, value: number): Promise<void> {
        item.weeklyPrice = value;
    }

    private async setMonthlyPriceAsync(item: ProductModel, value: number): Promise<void> {
        item.monthlyPrice = value;
    }

    private async setDailyPriceAsync(item: ProductModel, value: number): Promise<void> {
        item.dailyPrice = value;
    }

    private async setIsSalesItemAsync(isSalesItem: boolean): Promise<void> {
        this.product.isSalesItem = isSalesItem;
        this.product.productType = (isSalesItem)
            ? ProductType.Sales
            : ProductType.Rental;
    }

    private async setAlwaysAvailableAsync(item: ProductModel, input: boolean): Promise<void> {
        item.showAsAvailable = input;
    }

    private async setHiddenAsync(item: ProductModel, input: boolean): Promise<void> {
        item.hidden = input;
    }

    private async setIsRentaFuture(item: ProductModel, input: boolean): Promise<void> {
        item.isRentaFuture = input;
    }

    private async setShowDescriptionAsync(item: ProductModel, input: boolean): Promise<void> {
        item.showDescription = input;
    }

    private async setTagValueAsync(item: ProductTagModel, value: string): Promise<void> {
        item.tag = value;
    }

    private async deleteProductTagAsync(productTag: ProductTagModel): Promise<void> {
        this.product.productTags?.remove(productTag);
        await this.reRenderAsync();
    }

    private async addNewProductTagAsync(): Promise<void> {
        let newTag: ProductTagModel = {
            tag: null,
            id: null,
            isProductTagModel: true
        };

        if (!this.product.productTags) {
            this.product.productTags = [];
        }
        this.product.productTags.push(newTag);

        await this.reRenderAsync();
    }

    private async setAttachedProductNumberAsync(item: ProductModel, value: string): Promise<void> {
        item.externalId = value;
    }

    private async setAttachedProductQuantityAsync(item: ProductModel, value: number): Promise<void> {
        item.quantity = value;
    }

    private async deleteAttachedProductAsync(product: ProductModel): Promise<void> {
        this.product.attachedProducts?.remove(product);
        await this.reRenderAsync();
    }

    private async addNewAttachedProductAsync(): Promise<void> {
        let attachedProduct = new ProductModel();
        attachedProduct.quantity = 1;

        if (!this.product.attachedProducts) {
            this.product.attachedProducts = [];
        }
        this.product.attachedProducts.push(attachedProduct);

        await this.reRenderAsync();
    }

    private async openMoveProductModalAsync(): Promise<void> {
        this.setState({isChangeParentModalOpen: true});
    }

    private async closeMoveProductModalAsync(status: boolean): Promise<void> {
        this.setState({isChangeParentModalOpen: false});
        if (status) {
            await this.props.onProductMoved(this.shoppingCartProduct);
        }
    }

    private async deleteProductAsync(): Promise<void> {
        const confirmed = await this.confirm(`${Localizer.categoryManagementDeleteProductConfirmation}?`);

        if (confirmed) {
            const productId = this.product.id;

            const url: string = `/api/Admin/DeleteProduct`;

            const success: boolean = await this.postAsync(url, productId);

            if (success) {
                await this.props.onProductDeleted(this.shoppingCartProduct);
            }
        }
    }

    private async confirm(message: string): Promise<boolean> {
        const dialog: ConfirmationDialog = this._deleteProductModalRef.current!;
        return await dialog.confirmAsync(message);
    }

    private renderCo2DefinitionsModal(): React.ReactNode {
        if (this.product.id) {
            return (
                <Co2DefinitionModal productId={this.product.id!}
                                    entityName={this.product.name ?? ""}
                                    ref={this._editCo2DefinitionsModalRef}
                />
            );
        }
    }

    public render(): React.ReactNode {

        const isSalesItem: boolean = (this.product.isSalesItem);

        const renderEditMode = () => {
            return <div>
                <TwoColumns>
                    <TextInput value={this.product.name ?? undefined}
                               label={Localizer.catalogEditProductProductName}
                               onChange={async (_: TextInput, value: string) => await this.setProductNameAsync(value)}
                    />

                    <TextInput value={this.product.externalId ?? undefined}
                               label={Localizer.catalogEditProductExternalId}
                               width={"200px"}
                               onChange={async (_: TextInput, value: string) => await this.setExternalIdAsync(value)}
                    />
                </TwoColumns>
                <TwoColumns>
                    <NumberInput value={this.product.unitPrice!}
                                 label={Localizer.catalogEditProductUnitPrice}
                                 step={0.01}
                                 onChange={async (input, value) => await this.setUnitPriceAsync(this.product, value)}
                    />
                    <TextInput value={this.product.unitType ?? undefined}
                               label={Localizer.catalogEditProductUnitType}
                               width={"200px"}
                               onChange={async (_: TextInput, value: string) => await this.setUnitTypeAsync(this.product, value)}
                    />
                </TwoColumns>
                <ThreeColumns>
                    <NumberInput value={this.product.dailyPrice!} label={Localizer.catalogEditProductDailyPrice}
                                 step={0.01}
                                 onChange={async (input, value) => await this.setDailyPriceAsync(this.product, value)}

                    />
                    <NumberInput value={this.product.weeklyPrice!} label={Localizer.catalogEditProductWeeklyPrice}
                                 step={0.01}
                                 onChange={async (input, value) => await this.setWeeklyPriceAsync(this.product, value)}

                    />
                    <NumberInput value={this.product.monthlyPrice!} label={Localizer.catalogEditProductMonthlyPrice}
                                 step={0.01}
                                 onChange={async (input, value) => await this.setMonthlyPriceAsync(this.product, value)}
                    />
                </ThreeColumns>
                <OneColumn>
                    <TextInput
                        id={"related_products_edit"}
                        model={this._relatedProducts}
                        label={Localizer.catalogEditProductRelatedProducts}
                    />
                </OneColumn>
                <OneColumn>
                    <Button id="attached_product_add"
                            className={styles.addTagButton}
                            type={ButtonType.Orange}
                            icon={{name: "plus"}}
                            label={Localizer.catalogEditProductAddNewAttachedProductButtonLabel}
                            onClick={async () => this.addNewAttachedProductAsync()}
                    />


                </OneColumn>
                <OneColumn>
                    {
                        this.product.attachedProducts?.map((attachedProduct, index) => (
                            <Inline key={`product_tag_${index}`} className={styles.tagsContainer}>
                                <TextInput required
                                           id={`attached_product_number_${index}`}
                                           value={attachedProduct.externalId}
                                           placeholder={Localizer.catalogEditProductProductNumberInputPlaceholder}
                                           width="200px"
                                           onChange={async (_: TextInput, value: string) => await this.setAttachedProductNumberAsync(attachedProduct, value)}
                                />
                                <NumberInput required
                                             value={attachedProduct.quantity}
                                             min={1}
                                             max={RentaEasyConstants.attachedProductsMaxAmount}
                                             className={styles.quantityInput}
                                             onChange={async (_, value) => await this.setAttachedProductQuantityAsync(attachedProduct, value)}
                                />

                                <Button style={{marginLeft: "5px"}}
                                        type={ButtonType.Orange}
                                        icon={{name: "trash"}}
                                        onClick={async () => await this.deleteAttachedProductAsync(attachedProduct)}
                                />
                            </Inline>
                        ))
                    }

                </OneColumn>
                <div className={"athenaeum-layout-row row"}>
                    <div className={`${styles.checkboxesContainer}`}>
                        <FeatureSwitch flagName={RentaEasyConstants.featureFlagSalesItems}>
                            <Checkbox inline inlineType={InlineType.Right}
                                      label={Localizer.catalogEditProductSalesItem}
                                      className={styles.checkbox}
                                      value={this.product.isSalesItem}
                                      onChange={async (input, value) => await this.setIsSalesItemAsync(value)}
                            />
                        </FeatureSwitch>

                        <Checkbox inline inlineType={InlineType.Right}
                                  label={Localizer.catalogEditProductShowAlwaysAvailable}
                                  className={styles.checkbox}
                                  value={this.product.showAsAvailable}
                                  onChange={async (input, value) => await this.setAlwaysAvailableAsync(this.product, value)}
                        />

                        <Checkbox inline inlineType={InlineType.Right}
                                  label={Localizer.catalogEditProductShowDescription}
                                  className={styles.checkbox}
                                  value={this.product.showDescription}
                                  onChange={async (input, value) => await this.setShowDescriptionAsync(this.product, value)}
                        />

                        <Checkbox inline inlineType={InlineType.Right}
                                  label={Localizer.categoryManagementHide}
                                  value={this.product.hidden}
                                  onChange={async (input, value) => await this.setHiddenAsync(this.product, value)}
                        />

                        <Checkbox inline inlineType={InlineType.Right}
                                  label={Localizer.categoryManagementIsRentaFuture}
                                  value={this.product.isRentaFuture}
                                  onChange={async (input, value) => await this.setIsRentaFuture(this.product, value)}
                        />
                    </div>
                </div>
                <OneColumn>
                    <Button id={`co2Definitions`}
                            className={styles.addTagButton}
                            type={ButtonType.Orange}
                            icon={{name: "smog"}}
                            label={Localizer.catalogEditProductDefaultEmissionButtonLabel}
                            onClick={async () => this._editCo2DefinitionsModalRef.current?.openAsync()}
                    />
                </OneColumn>
                <OneColumn>
                    <Button id={`addTagButton`}
                            className={styles.addTagButton}
                            type={ButtonType.Orange}
                            icon={{name: "tag"}}
                            label={Localizer.newProductModalAddNewTagTitle}
                            onClick={async () => this.addNewProductTagAsync()}
                    />


                </OneColumn>
                <OneColumn>
                    {
                        this.product.productTags?.map((productTag, index) => (
                            <Inline key={`product_tag_${index}`} className={styles.tagsContainer}>
                                <TextInput required
                                           value={productTag.tag!}
                                           width={"200px"}
                                           onChange={async (_: TextInput, value: string) => await this.setTagValueAsync(productTag, value)}
                                />

                                <Button style={{marginLeft: "5px"}}
                                        id={`deleteTagButton`}
                                        type={ButtonType.Orange}
                                        icon={{name: "trash"}}
                                        onClick={async () => await this.deleteProductTagAsync(productTag)}
                                />
                            </Inline>
                        ))
                    }

                </OneColumn>
                <div>
                    <div className="d-flex gap-2">
                        <Button id={`saveButton`}
                                type={ButtonType.Orange}
                                icon={{name: "save"}}
                                label={Localizer.genericSave}
                                onClick={async () => this.saveProductDetailsAsync()}
                        />
                        <Button id={`moveButton`}
                                type={ButtonType.Orange}
                                icon={{name: "exchange"}}
                                label={Localizer.catalogEditProductMove}
                                onClick={async () => this.openMoveProductModalAsync()}
                        />

                        <Button id={`deleteButton`}
                                type={ButtonType.Danger}
                                icon={{name: "trash"}}
                                label={Localizer.categoryManagementDelete}
                                onClick={async () => this.deleteProductAsync()}
                        />
                    </div>
                </div>
            </div>;
        };

        return (
            ((this.salesItemsEnabled) || (!this.salesItemsEnabled && !isSalesItem)) && (this.hasProduct) &&
            (
                <>
                    <div id={`product_${this.product.id}`}
                         className={this.css(styles.productBox)}
                    >
                        {this.props.pricingToolMode && (
                            <div className={styles.discountArea} id="editProductDiscount"
                                 onClick={async (e: React.MouseEvent) => {
                                     e.preventDefault();
                                     e.stopPropagation();
                                     await this.openDiscountModalAsync();
                                 }}
                            >
                                <div>
                                    <Icon name={"tag"} size={IconSize.Large} className={styles.tagIcon}/>
                                </div>
                                <div className={this.product.editDiscount ? styles.strikeThrough : ""}>
                                    {(this.product.discountPercentage ?? 0) + " %"}
                                </div>
                                <div>
                                    <Icon name={"pen"} size={IconSize.Large} className={styles.penIcon}/>
                                </div>
                            </div>
                        )}
                        <div className="row m-0">
                            <div className="col-12 col-md-4 d-flex flex-column gap-2">
                                {!this.props.pricingToolMode &&
                                    <div className={styles.availabilityRow}>
                                        {(this.product.isRentaFuture) && (
                                            <div className={styles.rentaFutureIcon}>
                                                <img src={'../images/renta-future.png'} alt={"Renta Future"}/>
                                            </div>
                                        )}
                                        <div className={styles.infoIcon}
                                             onClick={async () => await this.navigateToProductDetails()}
                                        >
                                        <span className={styles.availabilityText}>
                                            {Localizer.productDetailsAvailability}: {this.props.depotName}
                                        </span>
                                            {(!this.isLoading) && (
                                                <Icon name="circle" size={IconSize.X2}
                                                      customStyle={{color: this.isAvailable ? "#65BA01" : "rgb(255, 206, 14)"}}
                                                      onClick={async () => await this.navigateToProductDetails()}
                                                />
                                            )}
                                        </div>
                                    </div>
                                }

                                <div className={this.css("flex-1 cursor-pointer d-flex", styles.imageContainer)} onClick={async () => await this.navigateToProductDetails()}>
                                    {(this.product.imageReference) && (
                                        <img src={this.imageSrc}
                                             className="flex-1"
                                             style={{maxWidth: '100%', maxHeight: '100%', objectFit: 'contain'}}
                                             alt={this.product.name ?? undefined}
                                             onError={({currentTarget}: any) => {
                                                 currentTarget.onerror = null;
                                                 currentTarget.src = "/images/placeholder.jpg";
                                             }}

                                        />
                                    )}
                                </div>

                            </div>

                            <div className="col-12 col-md-8 d-flex flex-column gap-3 px-2 py-4">
                                {this.props.editMode && (
                                    <>
                                        {renderEditMode()}
                                    </>
                                )}

                                {this.props.pricingToolMode && this.product.name}

                                {!this.props.editMode && (
                                    <>
                                        {!this.props.pricingToolMode && (
                                            <div id={`product_name_${this.product.id}`} className="d-flex justify-content-between">
                                                <Link key={"product-details-link"}
                                                      className={styles.productLink}
                                                      route={PageDefinitions.productDetails.route({id: this.product.url!})}
                                                >
                                                    <h2>{this.product.name}</h2>
                                                </Link>
                                                <div>
                                                    {(this.userContext.user) && (
                                                        <InlineTooltip
                                                            text={(this.isFavorite) ? Localizer.genericRemoveFromFavorites : Localizer.genericAddToFavorites}
                                                            icon={
                                                                <Icon
                                                                    id={`favorite_product_${this.product.id}`}
                                                                    name="fa-star"
                                                                    className="cursor-pointer"
                                                                    customStyle={{color: this.isFavorite ? "#ffc040" : "black", fontWeight: this.isFavorite ? 800 : 400}}
                                                                    style={this.isFavorite ? IconStyle.Solid : IconStyle.Regular}
                                                                    onClick={async () => await this.addOrDeleteFavoriteAsync(this.product.id, this.isFavorite)}
                                                                />
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {
                                            this.product.hidden && (
                                                <div className={styles.hiddenProductContainer}>
                                                    <Icon name={"eye-slash"}
                                                          size={IconSize.X3}
                                                          className={styles.hiddenIcon}
                                                    />
                                                </div>
                                            )
                                        }
                                        {((this.shoppingCartProduct.product?.infos?.length ?? 0) > 0) && (
                                            <div id={`productInfoContainer_${this.product.id}`}>
                                                <Accordion className={"new-accordion-style-v2 p-0"}
                                                           classNames={{header: styles.accordionHeader, contentContainer: styles.accordionContainer, headerContainer: styles.accordionHeaderContainer}}
                                                           header={Localizer.productDetailsTechnicalInfo}
                                                           togglerIcon={""}
                                                >
                                                    <ProductModelInfoList id={`productModelInfoList_${this.product.id}`}
                                                                          infos={this.shoppingCartProduct.product?.infos ?? []}
                                                                          categoryKeys={this.props.categoryKeys}/>
                                                </Accordion>
                                            </div>
                                        )}

                                        <div className="d-flex flex-column flex-lg-row gap-4">
                                            <div className="flex-1">
                                                {(!this.isAuthenticated && this.authenticatedUsersOnly) &&
                                                    Localizer.productDetailsPriceForAuthenticatedUsersOnly
                                                }
                                                {(this.isAuthenticated || !this.authenticatedUsersOnly) && (
                                                    <>
                                                        <Prices displayWeeklyPriceTooltip displayDiscountText inlinePriceRows
                                                                className={styles.pricesContainer}
                                                                product={this.product}
                                                                vat={this.vat}
                                                        />
                                                        {(!this.hasDiscountedPrices) && (
                                                            <ProductVatButtons
                                                                environmentVatValue={PriceHelper.environmentVat}
                                                                isZeroVatSelected={this.state.showCustomerPrices}
                                                                isEnvVatSelected={!this.state.showCustomerPrices}
                                                                onVatChangeAsync={async (value) => await this.showCustomerPrices(value === 0)}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            {!this.props.pricingToolMode && (
                                                <div className="flex-1 d-flex flex-column align-items-end justify-content-end gap-2">
                                                    <NumberInput className={this.css("w-100", styles.maxWidth250, styles.numberInput, "renta-easy-number-input", "renta-easy-number-input-product")}
                                                                 model={this._amount}
                                                                 min={1}
                                                                 max={100000}
                                                                 increaseIcon="plus"
                                                                 decreaseIcon="minus"
                                                                 behaviour={NumberInputBehaviour.Restricted}
                                                                 onChange={async (_, value) => await this.onAmountChangeHandlerAsync(value)}
                                                    />
                                                    <Button id={`addToCart`}
                                                            className={this.css("w-100", styles.maxWidth250)}
                                                            style={{height: "3.3em"}}
                                                            type={ButtonType.Orange}
                                                            label={Localizer.productDetailsAddToShoppingCart}
                                                            icon={{name: "shopping-cart", size: IconSize.Normal}}
                                                            onClick={() => this.addProductToCartAsync()}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}

                            </div>
                        </div>

                        {
                            <div id="attached_products_container">
                                {
                                    !this.props.editMode && (
                                        <AttachedProducts attachedProducts={this.product.attachedProducts!}
                                                          className={styles.attachedProductsContainer}
                                                          mainProductCount={this.state.amount}
                                        />
                                    )
                                }
                            </div>
                        }

                    </div>

                    {this.props.editMode && (
                        <>
                            {this.renderCo2DefinitionsModal()}

                            {this.product?.category?.id && (
                                <ChangeProductParentCategoryModal
                                    isOpen={this.state.isChangeParentModalOpen}
                                    productId={this.product.id}
                                    currentCategoryId={this.product.category.id}
                                    onClose={(success: boolean) => {
                                        this.closeMoveProductModalAsync(success);
                                    }}
                                />
                            )}
                        </>
                    )}

                    {this.props.pricingToolMode && (
                        <ChangeDiscountModal
                            isOpen={this.state.isDiscountModalOpen}
                            discountId={this.props.discountId}
                            item={this.product}
                            onClose={(success: boolean) => {
                                this.closeDiscountModalAsync(success);
                            }}
                            vat={PriceHelper.environmentVat}
                        />
                    )}

                    <ConfirmationDialog ref={this._deleteProductModalRef}/>

                </>
            )
        );
    }
}