import React from "react";
import {Button, ButtonContainer, ButtonType, CellModel, ColumnDefinition, EmailInput, Form, Grid, Link, Modal, PageContainer, PageHeader, PageRow, TextInput} from "@renta-apps/athenaeum-react-components";
import DepotCountApiModel from "@/models/server/DepotCountApiModel";
import {ActionType, BasePageParameters, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {OrderStatus} from "@/models/Enums";
import OrderState from "@/models/server/OrderState";
import PageDefinitions from "@/providers/PageDefinitions";
import Cloner from "@/helpers/Cloner";
import RentaPage from "@/models/base/RentaPage";
import styles from "./AdminLocations.module.scss";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";
import InlineTooltip from "@/components/InlineTooltip/InlineTooltip";

export interface IAdminLocationsParams extends BasePageParameters {
    keyword?: string;
}

interface IAdminLocationsState {
    locations: DepotCountApiModel[];
    editedLocation: DepotCountApiModel | null;
    keyword: string;
}

export default class AdminLocations extends RentaPage<IAdminLocationsParams, IAdminLocationsState> {

    // Fields

    public state: IAdminLocationsState = {
        locations: [],
        editedLocation: null,
        keyword: this.typedParameters?.keyword ?? ""
    };

    private readonly _locationGridRef: React.RefObject<Grid<DepotCountApiModel>> = React.createRef();
    private readonly _emailModalRef: React.RefObject<Modal> = React.createRef();
    private readonly _gridColumns: ColumnDefinition[] = [
        {
            header: Localizer.productDetailLocation,
            accessor: nameof<DepotCountApiModel>(location => location.name),
        },
        {
            header: Localizer.adminLocationsEmails,
            accessor: nameof<DepotCountApiModel>(location => location.orderEmails),
            render: (cell: CellModel<DepotCountApiModel>) => this.renderEmailsCell(cell.model),
            actions: [
                {
                    title: Localizer.serviceDefinitionsEdit,
                    icon: "pen",
                    type: ActionType.Edit,
                    callback: async (cell: CellModel<any>) => await this.openEmailModalAsync(cell.model),
                }
            ],
        },
        {
            header: Localizer.enumOrderStatusDelivered,
            group: Localizer.ordersPageOrders,
            render: (cell: CellModel<DepotCountApiModel>) => this.renderOrdersCell(cell.model, OrderStatus.Delivered),
        },
        {
            header: Localizer.enumOrderStatusProcessing,
            group: Localizer.ordersPageOrders,
            render: (cell: CellModel<DepotCountApiModel>) => this.renderOrdersCell(cell.model, OrderStatus.InProgress),
        },
        {
            header: Localizer.enumOrderStatusOrdered,
            group: Localizer.ordersPageOrders,
            render: (cell: CellModel<DepotCountApiModel>) => this.renderOrdersCell(cell.model, OrderStatus.Ordered),
        },
        {
            header: Localizer.enumOrderStatusPartially,
            group: Localizer.ordersPageOrders,
            render: (cell: CellModel<DepotCountApiModel>) => this.renderOrdersCell(cell.model, OrderStatus.Partially),
        },
        {
            header: Localizer.enumOrderStatusRejected,
            group: Localizer.ordersPageOrders,
            render: (cell: CellModel<DepotCountApiModel>) => this.renderOrdersCell(cell.model, OrderStatus.Rejected),
        },
        {
            header: Localizer.enumOrderStatusDelivered,
            group: Localizer.breadCrumbReturns,
            render: (cell: CellModel<DepotCountApiModel>) => this.renderReturnsCell(cell.model, OrderStatus.Delivered),
        },
        {
            header: Localizer.enumOrderStatusProcessing,
            group: Localizer.breadCrumbReturns,
            render: (cell: CellModel<DepotCountApiModel>) => this.renderReturnsCell(cell.model, OrderStatus.Processing),
        },
        {
            header: Localizer.enumOrderStatusOrdered,
            group: Localizer.breadCrumbReturns,
            render: (cell: CellModel<DepotCountApiModel>) => this.renderReturnsCell(cell.model, OrderStatus.Ordered),
        },
        {
            header: Localizer.enumOrderStatusPartially,
            group: Localizer.breadCrumbReturns,
            render: (cell: CellModel<DepotCountApiModel>) => this.renderReturnsCell(cell.model, OrderStatus.Partially),
        },
        {
            header: Localizer.enumOrderStatusRejected,
            group: Localizer.breadCrumbReturns,
            render: (cell: CellModel<DepotCountApiModel>) => this.renderReturnsCell(cell.model, OrderStatus.Rejected),
        },
    ];

    // Properties

    private get editedLocation(): DepotCountApiModel | null {
        return this.state.editedLocation;
    }

    private get locations(): DepotCountApiModel[] {
        return this.state
            .locations
            .sort(
                (model1, model2) =>
                    (model1.name ?? "").localeCompare(model2.name ?? ""));
    }

    // Methods

    private renderEmailsCell(location: DepotCountApiModel): React.ReactNode {
        return (
            <div className={styles.emailRows}
            >
                {
                    location?.orderEmails?.map((email: string | null) => (
                        <p key={email}>
                            {email}
                        </p>
                    ))
                }
            </div>
        );
    }

    private async openEmailModalAsync(locationToEdit: DepotCountApiModel): Promise<void> {
        const editedLocation = Cloner.clone(locationToEdit);

        if (!((editedLocation.orderEmails?.length ?? 0) > 0)) {
            editedLocation.orderEmails = [];
        }

        await this.setState({editedLocation});

        await this._emailModalRef.current!.openAsync();
        await this.assureEmptyEmailInputAsync();
    }

    private async closeEmailModalAsync(save: boolean): Promise<void> {

        if (save) {
            const originalDepot: DepotCountApiModel = this.locations.find((location) => location.id === this.editedLocation!.id)!;
            originalDepot.orderEmails = this.editedLocation!.orderEmails;

            await this.postAsync(`/api/Admin/UpdateDepotEmails?depotId=${originalDepot.id}`, originalDepot.orderEmails);
        }

        await this.setState({
            editedLocation: null,
        });

        await this._emailModalRef.current!.closeAsync();
    }

    private renderOrdersCell(depotCountApiModel: DepotCountApiModel, orderStatus: OrderStatus): React.ReactNode {
        const orderState: OrderState | undefined = depotCountApiModel.orderStates.find((orderState) => orderState.state === orderStatus);

        const splittedStatusName = EnumProvider.getOrderStatusName(orderStatus).split(".");
        const status = splittedStatusName[splittedStatusName.length - 1];

        return (orderState?.count)
            ?
            (
                <Link className={styles.link}
                      route={PageDefinitions.adminOrders.route({
                          params: {
                              depotId: depotCountApiModel.id,
                              state: status
                          }
                      })}
                >
                    {orderState.count}
                </Link>
            )
            : null;
    }

    private renderReturnsCell(location: DepotCountApiModel, returnStatus: OrderStatus): React.ReactNode {
        const returnState: OrderState | undefined = location.returnStates.find((orderState) => orderState.state === returnStatus);

        const splittedStatusName = EnumProvider.getOrderStatusName(returnStatus).split(".");
        const status = splittedStatusName[splittedStatusName.length - 1];

        return (returnState?.count)
            ?
            (
                <Link className={styles.link}
                      route={PageDefinitions.adminReturns.route({
                          params: {
                              depotId: location.id,
                              state: status
                          }
                      })}
                >
                    {returnState.count}
                </Link>
            )
            : null;
    }

    private async onEmailChangeAsync(index: number, email: string | null): Promise<void> {
        if (!email) {
            this.editedLocation!.orderEmails?.removeAt(index);
            await this.reRenderAsync();
        }
        else {
            if (this.editedLocation!.orderEmails!.length < index) {
                this.editedLocation!.orderEmails?.push(email);
            }
            else {
                this.editedLocation!.orderEmails![index] = email;
            }
        }

        await this.assureEmptyEmailInputAsync();
    }

    private async assureEmptyEmailInputAsync(): Promise<void> {
        if (this.editedLocation!.orderEmails?.every((email) => !!email) ?? true) {
            this.editedLocation!.orderEmails!.push("");
            await this.reRenderAsync();
        }
    }

    protected get title(): string {
        return Localizer.adminPageLocations;
    }

    private async setKeyWordAsync(keyword: string): Promise<void> {

        const route: PageRoute = this.route;

        if (keyword) {
            route.parameters = {
                keyword,
            };
        }
        else {
            route.parameters = null;
        }

        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(route);

        await this.setState({
            keyword
        });
    }

    private async filterLocationsAsync(): Promise<void> {
        if (this._locationGridRef.current) {
            await this._locationGridRef.current!.reloadAsync();
        }
    }

    private async getLocationsAsync(): Promise<DepotCountApiModel[]> {

        const locations: DepotCountApiModel[] = await this.postAsync(`/api/Admin/GetLocationsDetails`, this.state.keyword);

        await this.setState({
            locations
        });

        return locations
            .sort(
                (model1, model2) =>
                    (model1.name ?? "").localeCompare(model2.name ?? ""));
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer fullWidth
                           className={styles.adminLocations}
            >
                <PageHeader title={Localizer.adminPageRentaAdminManager}
                            subtitle={this.title}
                />

                {
                    (
                        <PageRow className={styles.locationsGrid}>
                            <Form inline
                                  onSubmit={async () => await this.filterLocationsAsync()}
                            >
                                <TextInput className={styles.searchBar}
                                           label={Localizer.ordersPageSearch}
                                           width={"200px"}
                                           title={Localizer.companiesOverviewFindByNameOrCustomerNumber}
                                           value={this.state.keyword!}
                                           onChange={async (sender, value) => await this.setKeyWordAsync(value)}
                                />

                                <Button submit
                                        className={styles.searchButton}
                                        label={Localizer.ordersPageSearch}
                                        type={ButtonType.Orange}
                                        icon={{name: "search"}}
                                />

                                <InlineTooltip text={Localizer.adminPageLocationsSearchTooltip}
                                />
                            </Form>

                            <Grid responsive
                                  optimization
                                  version2Styles
                                  id={"adminLocations"}
                                  ref={this._locationGridRef}
                                  columns={this._gridColumns}
                                  stickyHeader={true}
                                  className={styles.verticalEvenBackgroundLines}
                                  noDataText={Localizer.componentDropdownNoDataLanguageItemName}
                                  fetchData={async () => await this.getLocationsAsync()}
                            />
                        </PageRow>
                    )
                }

                <Modal ref={this._emailModalRef}
                       title={Localizer.adminLocationsEmails}
                       className={styles.adminLocationsEmailModal}
                >
                    <Form onSubmit={async () => await this.closeEmailModalAsync(true)}>
                        {
                            this.editedLocation?.orderEmails?.map((email, index) => (
                                <div key={index}
                                     className={styles.emailModalRow}
                                >
                                    <EmailInput value={email!}
                                                onChange={async (_, email) => await this.onEmailChangeAsync(index, email)}
                                    />

                                    <Button type={ButtonType.Danger}
                                            icon={{name: "trash"}}
                                            onClick={async () => await this.onEmailChangeAsync(index, null)}
                                    />
                                </div>
                            ))
                        }

                        <ButtonContainer>
                            <Button submit
                                    type={ButtonType.Primary}
                                    label={Localizer.genericSave}
                            />

                            <Button type={ButtonType.Danger}
                                    label={Localizer.formCancel}
                                    onClick={async () => await this.closeEmailModalAsync(false)}
                            />
                        </ButtonContainer>
                    </Form>
                </Modal>
            </PageContainer>
        );
    }
}