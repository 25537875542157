import {onRedirect} from "@/helpers/CypressHelper";
import Localizer from "@/localization/Localizer";

describe('Desktop - Not found tests', () => {

    Cypress.on('uncaught:exception', (err, runnable) => {
        return false;
    });

    describe('Not logged in user - not found tests', () => {

        before(() => {

        });

        beforeEach(() => {
            cy.session(['accept_cookie'], cy.acceptCookies);
        });


        it('Open product with existing id', () => {
            cy.intercept('POST', 'api/Product/ProductData', {fixture: 'product'}).as('productData');
            onRedirect(() => cy.visit('tuote/mock-url'));

            cy.waitSuccess("@productData");

            cy.url().should('not.contain', 'NotFound');
            cy.url().should('include', 'tuote/mock-url');
            cy.get('.athenaeum-page-container-container').should('contain', 'AJETTAVA NIVELPUOMINOSTIN');
        });

        it('Opens admin page and is redirect to login page', () => {
            onRedirect(() => cy.visit('adminschedules'));

            cy.url().should('contain', Localizer.pageRoutesLogin);
        });

        it('Open product with non-existing id', () => {
            cy.intercept('POST', 'api/Product/ProductData').as('productData');
            onRedirect(() => cy.visit('product/non-existing-product'));

            cy.waitSuccess("@productData");

            cy.url().should('include', 'NotFound');
            cy.get('.athenaeum-page-container-container')
                .find("svg")
                .find('image').eq(0).should('have.attr', 'xlink:href', '/images/404-top-no-truck.svg');
            cy.get('.athenaeum-page-container-container')
                .find('img').eq(0)
                .should('have.attr', 'src', '/images/Oops-sign.svg');
        });

    });
    describe('Private user - not found tests', () => {

        before(() => {

        });

        beforeEach(() => {
            cy.session(['company_role_session'], () => {
                cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
            });
        });

        it('Opens admin page and is redirect to not found page', () => {
            onRedirect(() => cy.visit('adminschedules'));

            cy.url().should('contain', 'NotFound');
        });
    });
    describe('Admin user - not found tests', () => {

        before(() => {

        });

        beforeEach(() => {
            cy.session(['private_user_session'], () => {
                cy.loginAndSelectPrivateRole("juhani.sihvonen@weare.fi");
            });
        });

        it('Opens admin page and is redirect to select role page', () => {
            onRedirect(() => cy.visit('adminschedules'));

            cy.url().should('contain', Localizer.pageRoutesSelectCompany);
        });
    });
});