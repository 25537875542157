import React, {lazy, Suspense} from "react";
import {AlertModel, BaseAsyncComponent, ch, IBaseAsyncComponentState, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, Icon, IconSize, InlineType, Switch, TextInput} from "@renta-apps/athenaeum-react-components";
import {Tooltip, TooltipPosition} from "@renta-apps/renta-react-components";
import ProductModel from "@/models/server/ProductModel";
import CategoryModel from "@/models/server/CategoryModel";
import ShoppingCartProductModel from "@/models/server/ShoppingCartProductModel";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import BreadCrumbItem from "@/models/BreadCrumbItem";
import CatalogRequest from "@/models/server/CatalogRequest";
import SaveProductToCartRequest from "@/models/server/SaveProductToCartRequest";
import Product from "@/components/Product/Product";
import CategoryItem from "@/components/Catalog/CategoryItem/CategoryItem";
import FavoriteCategoryItem from "@/components/Catalog/FavoriteCategoryItem/FavoriteCategoryItem";
import RentaFutureCategoryItem from "@/components/Catalog/RentaFutureCategoryItem/RentaFutureCategoryItem";
import ProductConfirmationModal from "@/components/ProductConfirmationModal/ProductConfirmationModal";
import {CatalogBreadCrumb} from "@/components/Catalog/CatalogBreadCrumb";
import RentaEasyController from "@/pages/RentaEasyController";
import UserContext from "@/models/server/UserContext";
import PriceHelper from "@/helpers/PriceHelper";
import CategoryAttributeKeyModel from "@/models/server/CategoryAttributeKeyModel";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "@/localization/Localizer";
import CatalogDataModel from "@/models/CatalogDataModel";
import {FeatureSwitch} from "@/providers/FeatureSwitch";
import Co2DefinitionModel from "@/models/server/Co2DefinitionModel";
import Co2DefinitionModal from "@/components/Co2DefinitionModal/Co2DefinitionModal";
import {CustomHtmlHeaders, HtmlHeadHelper} from "@/helpers/HtmlHeadHelper";

import {
    fetchCategoryDataAsync,
    fetchDataAsync,
    fetchFavoriteProductsAsync,
    fetchRentaFutureProductsAsync,
    moveCategoryPriorityDownAsync,
    moveCategoryPriorityUpAsync,
    moveProductPriorityDownAsync,
    moveProductPriorityUpAsync,
} from "@/services/CatalogService";

import NewProductModal from "@/components/Catalog/Modals/NewProductModal";
import NewCategoryModal from "@/components/Catalog/Modals/NewCategoryModal";
import EditAttributesModal from "@/components/Catalog/Modals/EditAttributesModal";

import styles from "./Catalog.module.scss";
import {DiscountType} from "@/models/server/Models/PricingTool/DiscountType";
import CategoryEditHelp from "@/components/Catalog/CategoryItemEdit/CategoryEditHelp";

const CategoryItemEdit = lazy(() => import("@/components/Catalog/CategoryItemEdit/CategoryItemEdit"));

interface ICatalogProps {
    className?: string;
    searchTerm?: string;
    editMode?: boolean;
    pricingToolMode: boolean;
    ignoreHidden?: boolean;
    ignoreSalesProducts?: boolean;
    discountId?: string;
    discountType?: DiscountType | null;
    categoryId?: string | null;
    onBreadCrumbChange?(items: BreadCrumbItem[]): void;
    onCategoryDiscountChangeAsync?(category: CategoryModel): Promise<void>;
    onProductDiscountChangeAsync?(product: ProductModel): Promise<void>;
}

interface ICatalogState extends IBaseAsyncComponentState<CatalogDataModel> {
    searchTerm: string;
    forceSearchTooltipOpen: boolean;
    editMode: boolean;
    alertModel: AlertModel | null;
    selectedCategoryAttributes: CategoryAttributeKeyModel[];
    selectedCo2Definitions: Co2DefinitionModel[];
    isNewCategoryModalOpen: boolean;
    isNewProductModalOpen: boolean;
    isEditCo2DefinitionsModalOpen: boolean;
    isEditAttributesModalOpen: boolean;
    isConfirmationModalOpen: boolean;
}

export default class Catalog extends BaseAsyncComponent<ICatalogProps, ICatalogState, {}> {
    public static MIN_ITEM_PRIORITY = 0;
    public static MAX_ITEM_PRIORITY = 99;

    public state: ICatalogState = {
        isLoading: false,
        data: null,
        searchTerm: this.props.searchTerm ?? "",
        forceSearchTooltipOpen: false,
        editMode: Boolean(this.props.editMode) ?? false,
        alertModel: null,
        selectedCategoryAttributes: [],
        selectedCo2Definitions: [],
        isNewProductModalOpen: false,
        isNewCategoryModalOpen: false,
        isEditCo2DefinitionsModalOpen: false,
        isEditAttributesModalOpen: false,
        isConfirmationModalOpen: false,
    };

    private readonly _editCo2DefinitionsModalRef: React.RefObject<Co2DefinitionModal> = React.createRef();
    private readonly _confirmationModalRef: React.RefObject<ProductConfirmationModal> = React.createRef();

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public async componentDidUpdate(prevProps: Readonly<ICatalogProps>): Promise<void> {
        if (this.props.pricingToolMode && (this.props.ignoreHidden !== prevProps.ignoreHidden || this.props.ignoreSalesProducts !== prevProps.ignoreSalesProducts)) {
            await this.getCategoryDataAsync(this.state.data?.currentCategoryId ?? this.props.categoryId ?? "");
        }
    }

    public isAsync(): boolean {
        return true;
    }

    private get canAddProduct(): boolean {
        return this.state.editMode
            && !!this.state.data
            && !!this.data.categories
            && this.data.categories.length === 0;
    }

    private get canAddCategory(): boolean {
        return this.state.editMode
            && !!this.state.data
            && (this.data.products === null || this.data.products.length === 0);
    }

    private get canAddCategoryKey(): boolean {
        return this.state.editMode
            && !!this.state.data
            && (!!this.data.currentCategoryId);
    }

    public canMoveUp(category?: CategoryModel | null, product?: ProductModel | null): boolean {
        if (category)
            return (category.priority !== undefined && (category.priority > Catalog.MIN_ITEM_PRIORITY));
        if (product)
            return (product.priority !== undefined && (product.priority > Catalog.MIN_ITEM_PRIORITY));
        return false;
    }

    private get maxProductPriority(): number {
        return this.data.products?.length ? this.data.products.length - 1 : Catalog.MAX_ITEM_PRIORITY;
    }

    private get maxCategoryPriority(): number {
        return this.data.categories?.length ? this.data.categories.length - 1 : Catalog.MAX_ITEM_PRIORITY;
    }

    public canMoveDown(category?: CategoryModel | null, product?: ProductModel | null): boolean {
        if (category)
            return (category.priority !== undefined && (category.priority < this.maxCategoryPriority));
        if (product)
            return (product.priority !== undefined && (product.priority < this.maxProductPriority));
        return false;
    }

    private get data(): CatalogDataModel {
        if (this.state.data) {
            return this.state.data;
        }

        return {} as CatalogDataModel;
    }

    private get currentCategory(): CategoryModel | null {
        if (!this.state.data) {
            return null;
        }
        if (!this.state.searchTerm) {
            if (this.state.data.products?.length > 0 && this.state.data.products[0].product) {
                return this.state.data.products[0].product.category;
            }
            if (this.state.data.categories?.length > 0) {
                return this.state.data.categories[0].parent;
            }
        }
        return null;
    }

    protected async fetchDataAsync(): Promise<CatalogDataModel> {
        if (this.props.categoryId === RentaEasyConstants.favoritePageId) {
            return await fetchFavoriteProductsAsync(this.state.editMode || false, this.props.discountId);
        }
        if (this.props.categoryId === RentaEasyConstants.rentaFuturePageId) {
            return await fetchRentaFutureProductsAsync(this.state.editMode || false, this.props.discountId);
        }

        let identifier: string | undefined = this.getIdFromUrl();
        if (this.props.categoryId !== undefined) {
            identifier = this.props.categoryId ?? "";
        }
        const request: CatalogRequest = {
            identifier: identifier,
            searchTerm: this.props.searchTerm || "",
            favorites: false,
            isRentaFuture: false,
            editMode: Boolean(this.state.editMode) || false,
            pricingToolMode: this.props.pricingToolMode,
            ignoreSalesProducts: this.props.pricingToolMode && this.props.ignoreSalesProducts,
            ignoreHidden: this.props.pricingToolMode && this.props.ignoreHidden,
            discountId: this.props.discountId
        };
        return await fetchDataAsync(request);
    }

    /**
     * TODO: getEndpoint should be optional - data fetching part should be separated from the component logic and as a long term goal all related code should be removed from base components
     */
    protected getEndpoint(): string {
        return "/api/Catalog/CategoriesData";
    }

    private get hasFavoriteProducts(): boolean {
        return (!!this.userContext)
            && (!!this.userContext.user?.favoriteProductsIds)
            && (this.userContext.user!.favoriteProductsIds.length > 0);
    }

    private get isCategoriesRoot(): boolean {
        return this.hasCategories && this.data.categories[0].parent === null;
    }


    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private get title(): string {
        return this.currentCategory?.name ? `${Localizer.rentButtonText} - ${this.currentCategory?.name}` : Localizer.rentButtonText;
    }

    private get description(): string {
        return "";
    }

    private get keywords(): string {
        const keywords = [];
        this.currentCategory?.name && keywords.push(this.currentCategory?.name);
        this.state.data?.categories && keywords.push(...this.state.data?.categories?.map(cat => cat.name) ?? []);
        this.state.data?.products && keywords.push(...this.state.data?.products?.map(prod => prod.product?.name) ?? []);
        return keywords.join(", ");
    }

    private get customHeaders(): CustomHtmlHeaders {
        const headers = {} as CustomHtmlHeaders;

        if (this.currentCategory?.url) {
            headers["canonical"] = {type: "link", value: `${window.location.origin}/${Localizer.pageRoutesRent}/${this.currentCategory.url}`};
        }

        return headers;
    }

    private get depotId(): string | null {
        return (!!this.userContext && this.userContext.user)
            ? this.userContext.user.favoriteDepotId
            : null;
    }

    private get depotExternalId(): string | null {
        return (!!this.userContext && this.userContext.user)
            ? this.userContext.user.favoriteDepotExternalId
            : null;
    }

    private get useCustomerPrices(): boolean {
        return (!!this.userContext) && (!this.userContext.isPrivateUser);
    }

    private get depotName(): string | null {
        return (!!this.userContext && this.userContext.user)
            ? this.userContext.user.favoriteDepotName
            : null;
    }

    private get showSearchResults(): boolean {
        return (!!this.state.data) && (!!this.state.data.searchTerm) && (this.state.data.searchTerm !== "");
    }

    private get hasProducts(): boolean {
        return !!this.state.data && (this.state.data?.products?.length > 0);
    }

    private get hasCategories(): boolean {
        return !!this.state.data && (this.state?.data?.categories?.length > 0);
    }

    private get categoryKeys(): CategoryAttributeKeyModel[] {
        return !!this.state.data && (this.state?.data?.categoryKeys?.length > 0) ? this.state.data.categoryKeys : [];
    }

    private async changeEditModeAsync(editMode: boolean): Promise<void> {
        if (this.state.data) {
            const newPage: PageRoute = PageDefinitions.rent.route({
                id: this.props.categoryId ?? "",
                params: {
                    editMode: editMode ?? undefined,
                    keyword: this.state.searchTerm ?? undefined,
                }
            });

            await PageRouteProvider.redirectAsync(newPage);
        }
    }

    private async onItemChanged(category: CategoryModel | ShoppingCartProductModel) {
        await this.reloadAsync();
    }

    private async onCategoryClickAsync(item: CategoryModel, e?: React.MouseEvent, preventInEditMode = true): Promise<void> {
        e?.preventDefault();
        e?.stopPropagation();

        if (preventInEditMode && this.state.editMode) {
            return;
        }

        if (this.isLoading) {
            return;
        }

        if (e?.currentTarget.id === "saveDiscount") {
            return;
        }

        if (e?.currentTarget.id === "editDiscount") {
            item.editDiscount = true;
            await this.reRenderAsync();
            return;
        }

        await this.openCategoryAsync(item);
    }

    private getIdFromUrl(): string {
        const splitPath: string[] = window.location.pathname.split("/");

        return (splitPath.length > (this.props.discountId ? 3 : 2))
            ? splitPath[splitPath.length - 1]
            : "";
    }

    private async openCategoryAsync(item: CategoryModel): Promise<void> {
        if (this.props.pricingToolMode) {
            const newPage: PageRoute = PageDefinitions.pricingToolCatalog.route({
                params: {discountId: this.props.discountId!, discountType: this.props.discountType!, categoryId: item.url},
            });
            await PageRouteProvider.redirectAsync(newPage);
        } else {
            const newPage: PageRoute = PageDefinitions.rent.route({
                id: item.url,
                params: this.state.editMode ? {editMode: this.state.editMode} : undefined,
            });

            await PageRouteProvider.redirectAsync(newPage);
        }
    }

    private async getCategoryDataAsync(item: string | null): Promise<void> {
        const data: CatalogDataModel = await fetchCategoryDataAsync(
            item, this.state.editMode, this.props.pricingToolMode, this.props.ignoreSalesProducts, this.props.ignoreHidden, this.props.discountId || undefined);
        this.setState({data});
    }

    private async handleBreadCrumbClickAsync(item: BreadCrumbItem): Promise<void> {
        if (this.props.pricingToolMode) {
            const newPage: PageRoute = PageDefinitions.pricingToolCatalog.route({
                params: {discountId: this.props.discountId!, discountType: this.props.discountType!, categoryId: item.id},
            });
            await PageRouteProvider.redirectAsync(newPage);
        } else {
            const newPage: PageRoute = PageDefinitions.rent.route({
                id: item.pageParameter,
                params: this.state.editMode ? {editMode: this.state.editMode} : undefined,
            });
            await PageRouteProvider.redirectAsync(newPage);
        }
    }

    private async searchProductsAsync(): Promise<void> {
        const newPage: PageRoute = PageDefinitions.rent.route({
            params: {
                keyword: this.state.searchTerm.trim(),
                editMode: this.state.editMode ?? undefined,
            }
        });
        await PageRouteProvider.redirectAsync(newPage);
    }

    private async onChangeSearchTermAsync(value: string): Promise<void> {
        if (this.state.searchTerm !== value) {
            this.setState({searchTerm: value});
            this.setState({forceSearchTooltipOpen: (value.length > 0 && value.length < 3) || false});
        }
        else {
            await this.searchProductsAsync();
        }
    }

    private static getTitle(categoryName: string | null): string {
        return (categoryName) ? `Renta Easy - ${categoryName!}` : Localizer.rentButtonText;
    }

    private async showFavoriteProductsAsync(): Promise<void> {
        const newPage: PageRoute = PageDefinitions.rent.route({
            id: RentaEasyConstants.favoritePageId,
            params: this.state.editMode ? {editMode: this.state.editMode} : undefined,
        });

        await PageRouteProvider.redirectAsync(newPage);
    }

    private async showRentaFutureProductsAsync(): Promise<void> {
        const newPage: PageRoute = PageDefinitions.rent.route({
            id: RentaEasyConstants.rentaFuturePageId,
            params: this.state.editMode ? {editMode: this.state.editMode} : undefined,
        });

        await PageRouteProvider.redirectAsync(newPage);
    }

    private async onUpdateShoppingCartAsync(productId: string, newCount: number, productName: string | null): Promise<void> {
        if (this.isSpinning()) {
            throw new Error("Cannot call onUpdateShoppingCart when another update is already in process");
        }

        const request: SaveProductToCartRequest = {
            productId: productId,
            productName: productName,
            count: newCount,

            // TODO: if the customer has specified a preferred product in ProductDetails, this will erase that information.

            preferredModel: "",
            preferredModelName: null,
        };

        await RentaEasyController.saveProductToShoppingCartAsync(request, this);

        await this.updateProductCounts(productId, newCount);

        await this.reRenderAsync();
    }

    private async updateProductCounts(productId: string, newCount: number): Promise<void> {
        if (!this.hasProducts) {
            return;
        }

        this.data.products.forEach((product: ShoppingCartProductModel) => {
            if ((product.product) && (product.product.id === productId)) {
                product.count = newCount;
            }

            if ((product.relatedProducts) && (product.relatedProducts.length > 0)) {
                product.relatedProducts.forEach((relatedProduct: ShoppingCartProductModel) => {
                    if ((relatedProduct.product) && (relatedProduct.product.id === productId)) {
                        relatedProduct.count = newCount;
                    }
                });
            }
        });
    }

    private async openCreateProductModalAsync(): Promise<void> {
        this.setState({isNewProductModalOpen: true});
    }

    private async closeCreateProductModalAsync(status: boolean): Promise<void> {
        this.setState({isNewProductModalOpen: false});
        if (status) {
            await this.reloadAsync();
        }
    }

    private async openCreateCategoryModalAsync(): Promise<void> {
        this.setState({isNewCategoryModalOpen: true});
    }

    private async closeCreateCategoryModalAsync(status: boolean): Promise<void> {
        this.setState({isNewCategoryModalOpen: false});
        if (status) {
            await this.reloadAsync();
        }
    }

    // TODO: rework this same way as other modals
    private async openCo2DefinitionsModalAsync(): Promise<void> {
        if (this._editCo2DefinitionsModalRef.current) {
            await this._editCo2DefinitionsModalRef.current.openAsync();
        }
    }

    private async openEditAttributesModalAsync(): Promise<void> {
        this.setState({isEditAttributesModalOpen: true});
    }

    private async closeEditAttributesModalAsync(status: boolean): Promise<void> {
        this.setState({isEditAttributesModalOpen: false});
        if (status) {
            await this.reloadAsync();
        }
    }

    private async downAsync(item: ShoppingCartProductModel): Promise<void> {
        if ((item.product?.priority !== undefined) && (item.product!.priority + 1 <= this.maxProductPriority)) {
            await moveProductPriorityUpAsync(item.product!.id!);
            await this.reloadAsync();
        }
    }

    private async upAsync(item: ShoppingCartProductModel): Promise<void> {
        if ((item.product?.priority !== undefined) && (item.product!.priority - 1 >= Catalog.MIN_ITEM_PRIORITY)) {
            await moveProductPriorityDownAsync(item.product!.id!);
            await this.reloadAsync();
        }
    }

    private async categoryDownAsync(item: CategoryModel): Promise<void> {
        if (item.priority + 1 <= this.maxCategoryPriority) {
            await moveCategoryPriorityUpAsync(item.id!);
            await this.reloadAsync();
        }
    }

    private async categoryUpAsync(item: CategoryModel): Promise<void> {
        if (item.priority - 1 >= Catalog.MIN_ITEM_PRIORITY) {
            await moveCategoryPriorityDownAsync(item.id!);
            await this.reloadAsync();
        }
    }

    private async onProductMovedAsync(value: ShoppingCartProductModel) {
        await this.reloadAsync();
    }

    private async onProductDeletedAsync(value: ShoppingCartProductModel) {
        await this.reloadAsync();
    }

    private async onProductDiscountChangeAsync(product: ProductModel): Promise<void> {
        if (this.props.onProductDiscountChangeAsync) {
            await this.props.onProductDiscountChangeAsync(product);
        }
    }

    private renderCategoriesOrProducts(data: CatalogDataModel): React.ReactNode {
        if ((!this.hasProducts) && (this.hasCategories) && (!this.showSearchResults)) {
            return (
                <div id="categories-list" className={this.css(styles.categoryList, this.state.editMode ? styles.editMode : undefined)}>
                    {(this.hasFavoriteProducts && !this.props.pricingToolMode) && (
                        <div key={this.id + "_favorite"}
                             className={this.css(styles.item)}
                             onClick={() => this.showFavoriteProductsAsync()}
                        >
                            <FavoriteCategoryItem item={Catalog.favoriteCategory}/>
                        </div>
                    )}

                    {(this.isCategoriesRoot && !this.props.pricingToolMode) && (
                        <FeatureSwitch flagName={RentaEasyConstants.featureFlagRentaFutureCategory}>
                            <div key={this.id + "_rentaFuture"}
                                 className={this.css(styles.item)}
                                 onClick={() => this.showRentaFutureProductsAsync()}
                            >
                                <RentaFutureCategoryItem item={Catalog.rentaFutureCategory}/>
                            </div>
                        </FeatureSwitch>
                    )}

                    {data.categories.map((category, index) => (
                        <div className={styles.item}
                             key={`category_${index}`}
                             id={`category-item-${index}`}>
                            {(this.state.editMode || this.props.pricingToolMode) ? (
                                <Suspense fallback={<div className={`${styles.loading}`}>{Localizer.componentListLoading}</div>}>
                                    <CategoryItemEdit
                                        item={category}
                                        index={index}
                                        pricingToolMode={this.props.pricingToolMode}
                                        discountId={this.props.discountId}
                                        isAdminWithAdminRole={this.userContext.isAdminWithAdminRole}
                                        canMoveUp={this.canMoveUp(category)}
                                        canMoveDown={this.canMoveDown(category)}
                                        onMoveUpClicked={async (category) => await this.categoryUpAsync(category)}
                                        onMoveDownClicked={async (category) => await this.categoryDownAsync(category)}
                                        onOpenClicked={async (category, event) => {
                                            await this.onCategoryClickAsync(category, event, false)}
                                        }
                                        onItemChange={async (category) => await this.onItemChanged(category)}
                                    />
                                </Suspense>
                            ) : (
                                <CategoryItem
                                    item={category}
                                    index={index}
                                    onOpenClicked={async (category, event) => {
                                        await this.onCategoryClickAsync(category, event, false)}
                                    }
                                />
                            )}
                        </div>
                    ))}
                </div>
            );
        }

        if ((!this.hasProducts) && (this.showSearchResults)) {
            return (
                <div className="search-results">
                    <div className="search-title">
                        <h3>
                            {Localizer.categoriesSearchResults}: {this.data.searchTerm}
                        </h3>
                        <p>
                            {Localizer.categoriesSearchNoResults}
                        </p>
                    </div>
                </div>
            );
        }

        return (
            <>
                {(this.showSearchResults) && (
                    <div className="search-results">
                        <div className="search-title">
                            <h3>
                                {Localizer.categoriesSearchResults}: {this.state.data?.searchTerm}
                            </h3>
                            <p>
                                {Localizer.get(Localizer.categoriesSearchResultsCount, this.data.products.length)}
                            </p>
                        </div>
                    </div>
                )}

                <div className="items-list">
                    {this.data.products.map((product, index) => (
                        <div key={`${this.id}_product_editor_${index}`}>
                            {(this.state.editMode) && (
                                <div className={styles.prioritySettings}>
                                        <Button type={ButtonType.Default}
                                                className={"move-up"}
                                                icon={{name: "arrow-up", size: IconSize.Normal}}
                                                onClick={async () => this.upAsync(product)}
                                                disabled={!this.canMoveUp(null, product.product)}
                                        />
                                    <div  className={styles.priority}>
                                        {product.product?.priority}
                                    </div>
                                        <Button type={ButtonType.Default}
                                                className={"move-down"}
                                                icon={{name: "arrow-down", size: IconSize.Normal}}
                                                onClick={async () => this.downAsync(product)}
                                                disabled={!this.canMoveDown(null, product.product)}
                                        />
                                </div>
                            )}

                            <Product pricingToolMode={this.props.pricingToolMode}
                                     discountId={this.props.discountId}
                                     key={`${this.id}_product_${index}`}
                                     product={product}
                                     categoryKeys={this.categoryKeys}
                                     depotId={this.depotId}
                                     depotExternalId={this.depotExternalId}
                                     depotName={this.depotName}
                                     customerPrices={this.useCustomerPrices}
                                     confirmation={this._confirmationModalRef.current!}
                                     editMode={this.state.editMode}
                                     onProductMoved={async (value) => await this.onProductMovedAsync(value)}
                                     onProductDeleted={async (value) => await this.onProductDeletedAsync(value)}
                                     onProductDiscountChangeAsync={async (product) => await this.onProductDiscountChangeAsync(product)}
                                     onItemChange={async (item) => await this.onItemChanged(item)}
                            />
                        </div>
                    ))}
                </div>
            </>
        );
    }

    private renderEditButtons(): React.ReactNode {
        return (
            <div className={styles.editButtonsContainer}>
                {!this.props.pricingToolMode &&
                    <Switch inline
                        id={"rent_edit"}
                        label={Localizer.genericEdit}
                        inlineType={InlineType.Left}
                        value={this.state.editMode}
                        onChange={async (_sender, checked) => await this.changeEditModeAsync(checked)}
                        className={styles.editModeSwitch}
                    />
                }
                {this.canAddProduct && (
                    <Button label={Localizer.catalogEditProductNewProduct}
                            id={"add_new_product"}
                            icon={{name: "plus", size: IconSize.Normal}}
                            onClick={async () => this.openCreateProductModalAsync()}
                    />
                )}

                {this.canAddCategory && (
                    <Button label={Localizer.catalogEditCategoryNewCategory}
                            id={"rentAddCategory"}
                            icon={{name: "plus", size: IconSize.Normal}}
                            onClick={async () => this.openCreateCategoryModalAsync()}
                    />
                )}

                {(this.canAddCategoryKey) && (
                        <Button label={Localizer.catalogEditAttributes}
                                id={"edit_attributes"}
                                icon={{name: "pen", size: IconSize.Normal}}
                                onClick={async () => this.openEditAttributesModalAsync()}
                        />
                )}

                {(this.canAddCategoryKey) && (
                        <Button label={Localizer.catalogDefaultEmissionButtonLabel}
                                icon={{name: "smog", size: IconSize.Normal}}
                                onClick={async () => this.openCo2DefinitionsModalAsync()}
                        />
                )}

                {(this.state.editMode && !this.props.pricingToolMode && this.data?.categories?.length > 0) && (
                    <Tooltip content={<CategoryEditHelp />} className={styles.helpIcon} position={TooltipPosition.LEFT} shift={TooltipPosition.BOTTOM}>
                        <Icon name="far question-circle"
                              size={IconSize.X2}
                        />
                    </Tooltip>
                )}
            </div>
        );
    }

    private renderCo2DefinitionsModal(): React.ReactNode {
        if (this.currentCategory?.id) {
            return (
                <Co2DefinitionModal categoryId={this.currentCategory.id!}
                                    entityName={this.currentCategory.name ?? ""}
                                    ref={this._editCo2DefinitionsModalRef}
                />
            );
        }
    }

    public render(): React.ReactNode {
        return (
            <>
                {HtmlHeadHelper.renderHead(this.title, this.keywords, this.description, this.customHeaders)}
                <div className={this.css(styles.searchBar, "container")}>
                    <Tooltip
                        forceOpen={this.state.forceSearchTooltipOpen}
                        text={Localizer.categoriesSearchTooltip}
                        className={this.css(styles.searchBarTooltip)}
                    >
                        <TextInput placeholder={Localizer.categoriesSearchByName}
                                   id={"catalog_search_product"}
                                   noAutoComplete
                                   className={this.css(styles.searchBarInput)}
                                   value={this.state.searchTerm}
                                   onChange={(_sender: TextInput, value: string) => this.onChangeSearchTermAsync(value)}
                                   append={
                                       <Icon name="far search" onClick={() => this.searchProductsAsync()} />
                                   }
                        />
                    </Tooltip>
                </div>

                <div className="py-2">
                    <CatalogBreadCrumb pricingTool={this.props.pricingToolMode!}
                                       editMode={this.state.editMode}
                                       currentCategory={this.currentCategory}
                                       isFavorites={this.state.data?.isFavorites ?? false}
                                       isRentaFuture={this.state.data?.isRentaFuture ?? false}
                                       onBreadCrumbClick={async (item) => {
                                           await this.handleBreadCrumbClickAsync(item);
                                       }}
                                       onBreadCrumbChange={this.props.onBreadCrumbChange}
                    />
                </div>

                {this.userContext.isAdminWithAdminRole && this.renderEditButtons()}

                {(!!this.state.data) && (
                    <div>
                        {this.renderCategoriesOrProducts(this.data)}
                    </div>
                )}

                <ProductConfirmationModal id={"productConfirmationModal"}
                                          ref={this._confirmationModalRef}
                                          vat={PriceHelper.userVat}
                                          readonly={this.isSpinning()}
                                          onUpdateProductCount={async (productId, newCount, productName) =>
                                              await this.onUpdateShoppingCartAsync(productId, newCount, productName)}
                />

                {this.state.editMode && this.userContext.isAdminWithAdminRole && (
                    <>
                        {this.renderCo2DefinitionsModal()}

                        <NewProductModal isOpen={this.state.isNewProductModalOpen}
                                         currentCategoryId={this.state.data?.currentCategoryId!}
                                         onClose={(success) => this.closeCreateProductModalAsync(success)}
                        />

                        <NewCategoryModal isOpen={this.state.isNewCategoryModalOpen}
                                          currentCategoryId={this.state.data?.currentCategoryId!}
                                          onClose={(success) => this.closeCreateCategoryModalAsync(success)}
                        />

                        <EditAttributesModal isOpen={this.state.isEditAttributesModalOpen}
                                             currentCategoryId={this.state.data?.currentCategoryId!}
                                             onClose={(success) => this.closeEditAttributesModalAsync(success)}
                        />
                    </>
                )}
            </>
        );
    }

    private static get favoriteCategory(): CategoryModel {
        const favorite = new CategoryModel();
        favorite.name = Localizer.genericFavorite;
        return favorite;
    }

    private static get rentaFutureCategory(): CategoryModel {
        const rentaFuture = new CategoryModel();
        rentaFuture.name = Localizer.catalogRentaFuture;
        return rentaFuture;
    }
}