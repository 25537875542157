//Autogenerated

import {BaseEnumProvider, SortDirection} from "@renta-apps/athenaeum-toolkit";
import {WebApplicationType, PasswordValidationError} from "@renta-apps/athenaeum-react-common";
import {SelectListItem} from "@renta-apps/athenaeum-react-components";
import {AlarmMediaType, AlarmType, AuthType, CacheEnums, ConstructionSiteFilterRented, ConstructionSiteFilterRentedOrReturned, ConstructionSitePermission, ConstructionSiteSchedules, ContractOrdering, CustomerEventQueueOperationType, DailyRentalType, DayOfWeek, EmissionFilterTimeFrame, EmissionType, EnvironmentDeviceType, EquipmentSortByType, EquipmentStatus, FuelSensorType, IdentityProvider, InfoScreenType, InputType, InvitationStatus, InvitationType, InvoiceRowType, InvoiceType, LoginResultStatus, MinimumRentalDays, NewSsoBindStatus, OrderStatus, OrderType, OrganizationContractLevel, PlanStatus, PlanType, PriceType, PricingToolDiscountStatus, ProductType, RentType, RoleType, SaveOrderRequestStatus, SavePasswordResultStatus, ScheduleAction, SendRequestTo, ServiceRequestStatus, ServiceType, UserAgreementType} from "@/models/Enums";

class EnumProvider extends BaseEnumProvider<SelectListItem> {

    // #region Private
    
    private readonly _types: string[] = ["AlarmMediaType", "AlarmType", "AuthType", "CacheEnums", "ConstructionSiteFilterRented", "ConstructionSiteFilterRentedOrReturned", "ConstructionSitePermission", "ConstructionSiteSchedules", "ContractOrdering", "CustomerEventQueueOperationType", "DailyRentalType", "DayOfWeek", "EmissionFilterTimeFrame", "EmissionType", "EnvironmentDeviceType", "EquipmentSortByType", "EquipmentStatus", "FuelSensorType", "IdentityProvider", "InfoScreenType", "InputType", "InvitationStatus", "InvitationType", "InvoiceRowType", "InvoiceType", "LoginResultStatus", "MinimumRentalDays", "NewSsoBindStatus", "OrderStatus", "OrderType", "OrganizationContractLevel", "PlanStatus", "PlanType", "PriceType", "PricingToolDiscountStatus", "ProductType", "RentType", "RoleType", "SaveOrderRequestStatus", "SavePasswordResultStatus", "ScheduleAction", "SendRequestTo", "ServiceRequestStatus", "ServiceType", "SortDirection", "UserAgreementType"];

    protected get types(): readonly string[] {
        return this._types;
    }

    protected createSelectListItem(value: string, text: string, subtext: string): SelectListItem {
        return new SelectListItem(value, text, subtext);
    }
    
    // #endregion
    
    constructor() {
        super();
    }

    // #region AlarmMediaType

    public getAlarmMediaTypeItem(value: AlarmMediaType): SelectListItem {
        return this.transform(AlarmMediaType, "AlarmMediaType", value);
    }

    public getAlarmMediaTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AlarmMediaType, "AlarmMediaType", reverse);
    }

    public getAlarmMediaTypeName(value: AlarmMediaType): string {
        return this.getAlarmMediaTypeItem(value).text;
    }

    public getAlarmMediaTypeText(value: AlarmMediaType): string {
        return this.localizer.get(this.getAlarmMediaTypeName(value));
    }

    public getAlarmMediaTypeDescription(value: AlarmMediaType): string {
        return this.getAlarmMediaTypeItem(value).subtext;
    }

    // #endregion

    // #region AlarmType

    public getAlarmTypeItem(value: AlarmType): SelectListItem {
        return this.transform(AlarmType, "AlarmType", value);
    }

    public getAlarmTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AlarmType, "AlarmType", reverse);
    }

    public getAlarmTypeName(value: AlarmType): string {
        return this.getAlarmTypeItem(value).text;
    }

    public getAlarmTypeText(value: AlarmType): string {
        return this.localizer.get(this.getAlarmTypeName(value));
    }

    public getAlarmTypeDescription(value: AlarmType): string {
        return this.getAlarmTypeItem(value).subtext;
    }

    // #endregion

    // #region AuthType

    public getAuthTypeItem(value: AuthType): SelectListItem {
        return this.transform(AuthType, "AuthType", value);
    }

    public getAuthTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AuthType, "AuthType", reverse);
    }

    public getAuthTypeName(value: AuthType): string {
        return this.getAuthTypeItem(value).text;
    }

    public getAuthTypeText(value: AuthType): string {
        return this.localizer.get(this.getAuthTypeName(value));
    }

    public getAuthTypeDescription(value: AuthType): string {
        return this.getAuthTypeItem(value).subtext;
    }

    // #endregion

    // #region CacheEnums

    public getCacheEnumsItem(value: CacheEnums): SelectListItem {
        return this.transform(CacheEnums, "CacheEnums", value);
    }

    public getCacheEnumsItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(CacheEnums, "CacheEnums", reverse);
    }

    public getCacheEnumsName(value: CacheEnums): string {
        return this.getCacheEnumsItem(value).text;
    }

    public getCacheEnumsText(value: CacheEnums): string {
        return this.localizer.get(this.getCacheEnumsName(value));
    }

    public getCacheEnumsDescription(value: CacheEnums): string {
        return this.getCacheEnumsItem(value).subtext;
    }

    // #endregion

    // #region ConstructionSiteFilterRented

    public getConstructionSiteFilterRentedItem(value: ConstructionSiteFilterRented): SelectListItem {
        return this.transform(ConstructionSiteFilterRented, "ConstructionSiteFilterRented", value);
    }

    public getConstructionSiteFilterRentedItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ConstructionSiteFilterRented, "ConstructionSiteFilterRented", reverse);
    }

    public getConstructionSiteFilterRentedName(value: ConstructionSiteFilterRented): string {
        return this.getConstructionSiteFilterRentedItem(value).text;
    }

    public getConstructionSiteFilterRentedText(value: ConstructionSiteFilterRented): string {
        return this.localizer.get(this.getConstructionSiteFilterRentedName(value));
    }

    public getConstructionSiteFilterRentedDescription(value: ConstructionSiteFilterRented): string {
        return this.getConstructionSiteFilterRentedItem(value).subtext;
    }

    // #endregion

    // #region ConstructionSiteFilterRentedOrReturned

    public getConstructionSiteFilterRentedOrReturnedItem(value: ConstructionSiteFilterRentedOrReturned): SelectListItem {
        return this.transform(ConstructionSiteFilterRentedOrReturned, "ConstructionSiteFilterRentedOrReturned", value);
    }

    public getConstructionSiteFilterRentedOrReturnedItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ConstructionSiteFilterRentedOrReturned, "ConstructionSiteFilterRentedOrReturned", reverse);
    }

    public getConstructionSiteFilterRentedOrReturnedName(value: ConstructionSiteFilterRentedOrReturned): string {
        return this.getConstructionSiteFilterRentedOrReturnedItem(value).text;
    }

    public getConstructionSiteFilterRentedOrReturnedText(value: ConstructionSiteFilterRentedOrReturned): string {
        return this.localizer.get(this.getConstructionSiteFilterRentedOrReturnedName(value));
    }

    public getConstructionSiteFilterRentedOrReturnedDescription(value: ConstructionSiteFilterRentedOrReturned): string {
        return this.getConstructionSiteFilterRentedOrReturnedItem(value).subtext;
    }

    // #endregion

    // #region ConstructionSitePermission

    public getConstructionSitePermissionItem(value: ConstructionSitePermission): SelectListItem {
        return this.transform(ConstructionSitePermission, "ConstructionSitePermission", value);
    }

    public getConstructionSitePermissionItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ConstructionSitePermission, "ConstructionSitePermission", reverse);
    }

    public getConstructionSitePermissionName(value: ConstructionSitePermission): string {
        return this.getConstructionSitePermissionItem(value).text;
    }

    public getConstructionSitePermissionText(value: ConstructionSitePermission): string {
        return this.localizer.get(this.getConstructionSitePermissionName(value));
    }

    public getConstructionSitePermissionDescription(value: ConstructionSitePermission): string {
        return this.getConstructionSitePermissionItem(value).subtext;
    }

    // #endregion

    // #region ConstructionSiteSchedules

    public getConstructionSiteSchedulesItem(value: ConstructionSiteSchedules): SelectListItem {
        return this.transform(ConstructionSiteSchedules, "ConstructionSiteSchedules", value);
    }

    public getConstructionSiteSchedulesItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ConstructionSiteSchedules, "ConstructionSiteSchedules", reverse);
    }

    public getConstructionSiteSchedulesName(value: ConstructionSiteSchedules): string {
        return this.getConstructionSiteSchedulesItem(value).text;
    }

    public getConstructionSiteSchedulesText(value: ConstructionSiteSchedules): string {
        return this.localizer.get(this.getConstructionSiteSchedulesName(value));
    }

    public getConstructionSiteSchedulesDescription(value: ConstructionSiteSchedules): string {
        return this.getConstructionSiteSchedulesItem(value).subtext;
    }

    // #endregion

    // #region ContractOrdering

    public getContractOrderingItem(value: ContractOrdering): SelectListItem {
        return this.transform(ContractOrdering, "ContractOrdering", value);
    }

    public getContractOrderingItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ContractOrdering, "ContractOrdering", reverse);
    }

    public getContractOrderingName(value: ContractOrdering): string {
        return this.getContractOrderingItem(value).text;
    }

    public getContractOrderingText(value: ContractOrdering): string {
        return this.localizer.get(this.getContractOrderingName(value));
    }

    public getContractOrderingDescription(value: ContractOrdering): string {
        return this.getContractOrderingItem(value).subtext;
    }

    // #endregion

    // #region CustomerEventQueueOperationType

    public getCustomerEventQueueOperationTypeItem(value: CustomerEventQueueOperationType): SelectListItem {
        return this.transform(CustomerEventQueueOperationType, "CustomerEventQueueOperationType", value);
    }

    public getCustomerEventQueueOperationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(CustomerEventQueueOperationType, "CustomerEventQueueOperationType", reverse);
    }

    public getCustomerEventQueueOperationTypeName(value: CustomerEventQueueOperationType): string {
        return this.getCustomerEventQueueOperationTypeItem(value).text;
    }

    public getCustomerEventQueueOperationTypeText(value: CustomerEventQueueOperationType): string {
        return this.localizer.get(this.getCustomerEventQueueOperationTypeName(value));
    }

    public getCustomerEventQueueOperationTypeDescription(value: CustomerEventQueueOperationType): string {
        return this.getCustomerEventQueueOperationTypeItem(value).subtext;
    }

    // #endregion

    // #region DailyRentalType

    public getDailyRentalTypeItem(value: DailyRentalType): SelectListItem {
        return this.transform(DailyRentalType, "DailyRentalType", value);
    }

    public getDailyRentalTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DailyRentalType, "DailyRentalType", reverse);
    }

    public getDailyRentalTypeName(value: DailyRentalType): string {
        return this.getDailyRentalTypeItem(value).text;
    }

    public getDailyRentalTypeText(value: DailyRentalType): string {
        return this.localizer.get(this.getDailyRentalTypeName(value));
    }

    public getDailyRentalTypeDescription(value: DailyRentalType): string {
        return this.getDailyRentalTypeItem(value).subtext;
    }

    // #endregion

    // #region DayOfWeek

    public getDayOfWeekItem(value: DayOfWeek): SelectListItem {
        return this.transform(DayOfWeek, "DayOfWeek", value);
    }

    public getDayOfWeekItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DayOfWeek, "DayOfWeek", reverse);
    }

    public getDayOfWeekName(value: DayOfWeek): string {
        return this.getDayOfWeekItem(value).text;
    }

    public getDayOfWeekText(value: DayOfWeek): string {
        return this.localizer.get(this.getDayOfWeekName(value));
    }

    public getDayOfWeekDescription(value: DayOfWeek): string {
        return this.getDayOfWeekItem(value).subtext;
    }

    // #endregion

    // #region EmissionFilterTimeFrame

    public getEmissionFilterTimeFrameItem(value: EmissionFilterTimeFrame): SelectListItem {
        return this.transform(EmissionFilterTimeFrame, "EmissionFilterTimeFrame", value);
    }

    public getEmissionFilterTimeFrameItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(EmissionFilterTimeFrame, "EmissionFilterTimeFrame", reverse);
    }

    public getEmissionFilterTimeFrameName(value: EmissionFilterTimeFrame): string {
        return this.getEmissionFilterTimeFrameItem(value).text;
    }

    public getEmissionFilterTimeFrameText(value: EmissionFilterTimeFrame): string {
        return this.localizer.get(this.getEmissionFilterTimeFrameName(value));
    }

    public getEmissionFilterTimeFrameDescription(value: EmissionFilterTimeFrame): string {
        return this.getEmissionFilterTimeFrameItem(value).subtext;
    }

    // #endregion

    // #region EmissionType

    public getEmissionTypeItem(value: EmissionType): SelectListItem {
        return this.transform(EmissionType, "EmissionType", value);
    }

    public getEmissionTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(EmissionType, "EmissionType", reverse);
    }

    public getEmissionTypeName(value: EmissionType): string {
        return this.getEmissionTypeItem(value).text;
    }

    public getEmissionTypeText(value: EmissionType): string {
        return this.localizer.get(this.getEmissionTypeName(value));
    }

    public getEmissionTypeDescription(value: EmissionType): string {
        return this.getEmissionTypeItem(value).subtext;
    }

    // #endregion

    // #region EnvironmentDeviceType

    public getEnvironmentDeviceTypeItem(value: EnvironmentDeviceType): SelectListItem {
        return this.transform(EnvironmentDeviceType, "EnvironmentDeviceType", value);
    }

    public getEnvironmentDeviceTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(EnvironmentDeviceType, "EnvironmentDeviceType", reverse);
    }

    public getEnvironmentDeviceTypeName(value: EnvironmentDeviceType): string {
        return this.getEnvironmentDeviceTypeItem(value).text;
    }

    public getEnvironmentDeviceTypeText(value: EnvironmentDeviceType): string {
        return this.localizer.get(this.getEnvironmentDeviceTypeName(value));
    }

    public getEnvironmentDeviceTypeDescription(value: EnvironmentDeviceType): string {
        return this.getEnvironmentDeviceTypeItem(value).subtext;
    }

    // #endregion

    // #region EquipmentSortByType

    public getEquipmentSortByTypeItem(value: EquipmentSortByType): SelectListItem {
        return this.transform(EquipmentSortByType, "EquipmentSortByType", value);
    }

    public getEquipmentSortByTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(EquipmentSortByType, "EquipmentSortByType", reverse);
    }

    public getEquipmentSortByTypeName(value: EquipmentSortByType): string {
        return this.getEquipmentSortByTypeItem(value).text;
    }

    public getEquipmentSortByTypeText(value: EquipmentSortByType): string {
        return this.localizer.get(this.getEquipmentSortByTypeName(value));
    }

    public getEquipmentSortByTypeDescription(value: EquipmentSortByType): string {
        return this.getEquipmentSortByTypeItem(value).subtext;
    }

    // #endregion

    // #region EquipmentStatus

    public getEquipmentStatusItem(value: EquipmentStatus): SelectListItem {
        return this.transform(EquipmentStatus, "EquipmentStatus", value);
    }

    public getEquipmentStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(EquipmentStatus, "EquipmentStatus", reverse);
    }

    public getEquipmentStatusName(value: EquipmentStatus): string {
        return this.getEquipmentStatusItem(value).text;
    }

    public getEquipmentStatusText(value: EquipmentStatus): string {
        return this.localizer.get(this.getEquipmentStatusName(value));
    }

    public getEquipmentStatusDescription(value: EquipmentStatus): string {
        return this.getEquipmentStatusItem(value).subtext;
    }

    // #endregion

    // #region FuelSensorType

    public getFuelSensorTypeItem(value: FuelSensorType): SelectListItem {
        return this.transform(FuelSensorType, "FuelSensorType", value);
    }

    public getFuelSensorTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(FuelSensorType, "FuelSensorType", reverse);
    }

    public getFuelSensorTypeName(value: FuelSensorType): string {
        return this.getFuelSensorTypeItem(value).text;
    }

    public getFuelSensorTypeText(value: FuelSensorType): string {
        return this.localizer.get(this.getFuelSensorTypeName(value));
    }

    public getFuelSensorTypeDescription(value: FuelSensorType): string {
        return this.getFuelSensorTypeItem(value).subtext;
    }

    // #endregion

    // #region IdentityProvider

    public getIdentityProviderItem(value: IdentityProvider): SelectListItem {
        return this.transform(IdentityProvider, "IdentityProvider", value);
    }

    public getIdentityProviderItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(IdentityProvider, "IdentityProvider", reverse);
    }

    public getIdentityProviderName(value: IdentityProvider): string {
        return this.getIdentityProviderItem(value).text;
    }

    public getIdentityProviderText(value: IdentityProvider): string {
        return this.localizer.get(this.getIdentityProviderName(value));
    }

    public getIdentityProviderDescription(value: IdentityProvider): string {
        return this.getIdentityProviderItem(value).subtext;
    }

    // #endregion

    // #region InfoScreenType

    public getInfoScreenTypeItem(value: InfoScreenType): SelectListItem {
        return this.transform(InfoScreenType, "InfoScreenType", value);
    }

    public getInfoScreenTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InfoScreenType, "InfoScreenType", reverse);
    }

    public getInfoScreenTypeName(value: InfoScreenType): string {
        return this.getInfoScreenTypeItem(value).text;
    }

    public getInfoScreenTypeText(value: InfoScreenType): string {
        return this.localizer.get(this.getInfoScreenTypeName(value));
    }

    public getInfoScreenTypeDescription(value: InfoScreenType): string {
        return this.getInfoScreenTypeItem(value).subtext;
    }

    // #endregion

    // #region InputType

    public getInputTypeItem(value: InputType): SelectListItem {
        return this.transform(InputType, "InputType", value);
    }

    public getInputTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InputType, "InputType", reverse);
    }

    public getInputTypeName(value: InputType): string {
        return this.getInputTypeItem(value).text;
    }

    public getInputTypeText(value: InputType): string {
        return this.localizer.get(this.getInputTypeName(value));
    }

    public getInputTypeDescription(value: InputType): string {
        return this.getInputTypeItem(value).subtext;
    }

    // #endregion

    // #region InvitationStatus

    public getInvitationStatusItem(value: InvitationStatus): SelectListItem {
        return this.transform(InvitationStatus, "InvitationStatus", value);
    }

    public getInvitationStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InvitationStatus, "InvitationStatus", reverse);
    }

    public getInvitationStatusName(value: InvitationStatus): string {
        return this.getInvitationStatusItem(value).text;
    }

    public getInvitationStatusText(value: InvitationStatus): string {
        return this.localizer.get(this.getInvitationStatusName(value));
    }

    public getInvitationStatusDescription(value: InvitationStatus): string {
        return this.getInvitationStatusItem(value).subtext;
    }

    // #endregion

    // #region InvitationType

    public getInvitationTypeItem(value: InvitationType): SelectListItem {
        return this.transform(InvitationType, "InvitationType", value);
    }

    public getInvitationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InvitationType, "InvitationType", reverse);
    }

    public getInvitationTypeName(value: InvitationType): string {
        return this.getInvitationTypeItem(value).text;
    }

    public getInvitationTypeText(value: InvitationType): string {
        return this.localizer.get(this.getInvitationTypeName(value));
    }

    public getInvitationTypeDescription(value: InvitationType): string {
        return this.getInvitationTypeItem(value).subtext;
    }

    // #endregion

    // #region InvoiceRowType

    public getInvoiceRowTypeItem(value: InvoiceRowType): SelectListItem {
        return this.transform(InvoiceRowType, "InvoiceRowType", value);
    }

    public getInvoiceRowTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InvoiceRowType, "InvoiceRowType", reverse);
    }

    public getInvoiceRowTypeName(value: InvoiceRowType): string {
        return this.getInvoiceRowTypeItem(value).text;
    }

    public getInvoiceRowTypeText(value: InvoiceRowType): string {
        return this.localizer.get(this.getInvoiceRowTypeName(value));
    }

    public getInvoiceRowTypeDescription(value: InvoiceRowType): string {
        return this.getInvoiceRowTypeItem(value).subtext;
    }

    // #endregion

    // #region InvoiceType

    public getInvoiceTypeItem(value: InvoiceType): SelectListItem {
        return this.transform(InvoiceType, "InvoiceType", value);
    }

    public getInvoiceTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InvoiceType, "InvoiceType", reverse);
    }

    public getInvoiceTypeName(value: InvoiceType): string {
        return this.getInvoiceTypeItem(value).text;
    }

    public getInvoiceTypeText(value: InvoiceType): string {
        return this.localizer.get(this.getInvoiceTypeName(value));
    }

    public getInvoiceTypeDescription(value: InvoiceType): string {
        return this.getInvoiceTypeItem(value).subtext;
    }

    // #endregion

    // #region LoginResultStatus

    public getLoginResultStatusItem(value: LoginResultStatus): SelectListItem {
        return this.transform(LoginResultStatus, "LoginResultStatus", value);
    }

    public getLoginResultStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(LoginResultStatus, "LoginResultStatus", reverse);
    }

    public getLoginResultStatusName(value: LoginResultStatus): string {
        return this.getLoginResultStatusItem(value).text;
    }

    public getLoginResultStatusText(value: LoginResultStatus): string {
        return this.localizer.get(this.getLoginResultStatusName(value));
    }

    public getLoginResultStatusDescription(value: LoginResultStatus): string {
        return this.getLoginResultStatusItem(value).subtext;
    }

    // #endregion

    // #region MinimumRentalDays

    public getMinimumRentalDaysItem(value: MinimumRentalDays): SelectListItem {
        return this.transform(MinimumRentalDays, "MinimumRentalDays", value);
    }

    public getMinimumRentalDaysItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(MinimumRentalDays, "MinimumRentalDays", reverse);
    }

    public getMinimumRentalDaysName(value: MinimumRentalDays): string {
        return this.getMinimumRentalDaysItem(value).text;
    }

    public getMinimumRentalDaysText(value: MinimumRentalDays): string {
        return this.localizer.get(this.getMinimumRentalDaysName(value));
    }

    public getMinimumRentalDaysDescription(value: MinimumRentalDays): string {
        return this.getMinimumRentalDaysItem(value).subtext;
    }

    // #endregion

    // #region NewSsoBindStatus

    public getNewSsoBindStatusItem(value: NewSsoBindStatus): SelectListItem {
        return this.transform(NewSsoBindStatus, "NewSsoBindStatus", value);
    }

    public getNewSsoBindStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(NewSsoBindStatus, "NewSsoBindStatus", reverse);
    }

    public getNewSsoBindStatusName(value: NewSsoBindStatus): string {
        return this.getNewSsoBindStatusItem(value).text;
    }

    public getNewSsoBindStatusText(value: NewSsoBindStatus): string {
        return this.localizer.get(this.getNewSsoBindStatusName(value));
    }

    public getNewSsoBindStatusDescription(value: NewSsoBindStatus): string {
        return this.getNewSsoBindStatusItem(value).subtext;
    }

    // #endregion

    // #region OrderStatus

    public getOrderStatusItem(value: OrderStatus): SelectListItem {
        return this.transform(OrderStatus, "OrderStatus", value);
    }

    public getOrderStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(OrderStatus, "OrderStatus", reverse);
    }

    public getOrderStatusName(value: OrderStatus): string {
        return this.getOrderStatusItem(value).text;
    }

    public getOrderStatusText(value: OrderStatus): string {
        return this.localizer.get(this.getOrderStatusName(value));
    }

    public getOrderStatusDescription(value: OrderStatus): string {
        return this.getOrderStatusItem(value).subtext;
    }

    // #endregion

    // #region OrderType

    public getOrderTypeItem(value: OrderType): SelectListItem {
        return this.transform(OrderType, "OrderType", value);
    }

    public getOrderTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(OrderType, "OrderType", reverse);
    }

    public getOrderTypeName(value: OrderType): string {
        return this.getOrderTypeItem(value).text;
    }

    public getOrderTypeText(value: OrderType): string {
        return this.localizer.get(this.getOrderTypeName(value));
    }

    public getOrderTypeDescription(value: OrderType): string {
        return this.getOrderTypeItem(value).subtext;
    }

    // #endregion

    // #region OrganizationContractLevel

    public getOrganizationContractLevelItem(value: OrganizationContractLevel): SelectListItem {
        return this.transform(OrganizationContractLevel, "OrganizationContractLevel", value);
    }

    public getOrganizationContractLevelItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(OrganizationContractLevel, "OrganizationContractLevel", reverse);
    }

    public getOrganizationContractLevelName(value: OrganizationContractLevel): string {
        return this.getOrganizationContractLevelItem(value).text;
    }

    public getOrganizationContractLevelText(value: OrganizationContractLevel): string {
        return this.localizer.get(this.getOrganizationContractLevelName(value));
    }

    public getOrganizationContractLevelDescription(value: OrganizationContractLevel): string {
        return this.getOrganizationContractLevelItem(value).subtext;
    }

    // #endregion

    // #region PlanStatus

    public getPlanStatusItem(value: PlanStatus): SelectListItem {
        return this.transform(PlanStatus, "PlanStatus", value);
    }

    public getPlanStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(PlanStatus, "PlanStatus", reverse);
    }

    public getPlanStatusName(value: PlanStatus): string {
        return this.getPlanStatusItem(value).text;
    }

    public getPlanStatusText(value: PlanStatus): string {
        return this.localizer.get(this.getPlanStatusName(value));
    }

    public getPlanStatusDescription(value: PlanStatus): string {
        return this.getPlanStatusItem(value).subtext;
    }

    // #endregion

    // #region PlanType

    public getPlanTypeItem(value: PlanType): SelectListItem {
        return this.transform(PlanType, "PlanType", value);
    }

    public getPlanTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(PlanType, "PlanType", reverse);
    }

    public getPlanTypeName(value: PlanType): string {
        return this.getPlanTypeItem(value).text;
    }

    public getPlanTypeText(value: PlanType): string {
        return this.localizer.get(this.getPlanTypeName(value));
    }

    public getPlanTypeDescription(value: PlanType): string {
        return this.getPlanTypeItem(value).subtext;
    }

    // #endregion

    // #region PriceType

    public getPriceTypeItem(value: PriceType): SelectListItem {
        return this.transform(PriceType, "PriceType", value);
    }

    public getPriceTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(PriceType, "PriceType", reverse);
    }

    public getPriceTypeName(value: PriceType): string {
        return this.getPriceTypeItem(value).text;
    }

    public getPriceTypeText(value: PriceType): string {
        return this.localizer.get(this.getPriceTypeName(value));
    }

    public getPriceTypeDescription(value: PriceType): string {
        return this.getPriceTypeItem(value).subtext;
    }

    // #endregion

    // #region PricingToolDiscountStatus

    public getPricingToolDiscountStatusItem(value: PricingToolDiscountStatus): SelectListItem {
        return this.transform(PricingToolDiscountStatus, "PricingToolDiscountStatus", value);
    }

    public getPricingToolDiscountStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(PricingToolDiscountStatus, "PricingToolDiscountStatus", reverse);
    }

    public getPricingToolDiscountStatusName(value: PricingToolDiscountStatus): string {
        return this.getPricingToolDiscountStatusItem(value).text;
    }

    public getPricingToolDiscountStatusText(value: PricingToolDiscountStatus): string {
        return this.localizer.get(this.getPricingToolDiscountStatusName(value));
    }

    public getPricingToolDiscountStatusDescription(value: PricingToolDiscountStatus): string {
        return this.getPricingToolDiscountStatusItem(value).subtext;
    }

    // #endregion

    // #region ProductType

    public getProductTypeItem(value: ProductType): SelectListItem {
        return this.transform(ProductType, "ProductType", value);
    }

    public getProductTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductType, "ProductType", reverse);
    }

    public getProductTypeName(value: ProductType): string {
        return this.getProductTypeItem(value).text;
    }

    public getProductTypeText(value: ProductType): string {
        return this.localizer.get(this.getProductTypeName(value));
    }

    public getProductTypeDescription(value: ProductType): string {
        return this.getProductTypeItem(value).subtext;
    }

    // #endregion

    // #region RentType

    public getRentTypeItem(value: RentType): SelectListItem {
        return this.transform(RentType, "RentType", value);
    }

    public getRentTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(RentType, "RentType", reverse);
    }

    public getRentTypeName(value: RentType): string {
        return this.getRentTypeItem(value).text;
    }

    public getRentTypeText(value: RentType): string {
        return this.localizer.get(this.getRentTypeName(value));
    }

    public getRentTypeDescription(value: RentType): string {
        return this.getRentTypeItem(value).subtext;
    }

    // #endregion

    // #region RoleType

    public getRoleTypeItem(value: RoleType): SelectListItem {
        return this.transform(RoleType, "RoleType", value);
    }

    public getRoleTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(RoleType, "RoleType", reverse);
    }

    public getRoleTypeName(value: RoleType): string {
        return this.getRoleTypeItem(value).text;
    }

    public getRoleTypeText(value: RoleType): string {
        return this.localizer.get(this.getRoleTypeName(value));
    }

    public getRoleTypeDescription(value: RoleType): string {
        return this.getRoleTypeItem(value).subtext;
    }

    // #endregion

    // #region SaveOrderRequestStatus

    public getSaveOrderRequestStatusItem(value: SaveOrderRequestStatus): SelectListItem {
        return this.transform(SaveOrderRequestStatus, "SaveOrderRequestStatus", value);
    }

    public getSaveOrderRequestStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SaveOrderRequestStatus, "SaveOrderRequestStatus", reverse);
    }

    public getSaveOrderRequestStatusName(value: SaveOrderRequestStatus): string {
        return this.getSaveOrderRequestStatusItem(value).text;
    }

    public getSaveOrderRequestStatusText(value: SaveOrderRequestStatus): string {
        return this.localizer.get(this.getSaveOrderRequestStatusName(value));
    }

    public getSaveOrderRequestStatusDescription(value: SaveOrderRequestStatus): string {
        return this.getSaveOrderRequestStatusItem(value).subtext;
    }

    // #endregion

    // #region SavePasswordResultStatus

    public getSavePasswordResultStatusItem(value: SavePasswordResultStatus): SelectListItem {
        return this.transform(SavePasswordResultStatus, "SavePasswordResultStatus", value);
    }

    public getSavePasswordResultStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SavePasswordResultStatus, "SavePasswordResultStatus", reverse);
    }

    public getSavePasswordResultStatusName(value: SavePasswordResultStatus): string {
        return this.getSavePasswordResultStatusItem(value).text;
    }

    public getSavePasswordResultStatusText(value: SavePasswordResultStatus): string {
        return this.localizer.get(this.getSavePasswordResultStatusName(value));
    }

    public getSavePasswordResultStatusDescription(value: SavePasswordResultStatus): string {
        return this.getSavePasswordResultStatusItem(value).subtext;
    }

    // #endregion

    // #region ScheduleAction

    public getScheduleActionItem(value: ScheduleAction): SelectListItem {
        return this.transform(ScheduleAction, "ScheduleAction", value);
    }

    public getScheduleActionItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ScheduleAction, "ScheduleAction", reverse);
    }

    public getScheduleActionName(value: ScheduleAction): string {
        return this.getScheduleActionItem(value).text;
    }

    public getScheduleActionText(value: ScheduleAction): string {
        return this.localizer.get(this.getScheduleActionName(value));
    }

    public getScheduleActionDescription(value: ScheduleAction): string {
        return this.getScheduleActionItem(value).subtext;
    }

    // #endregion

    // #region SendRequestTo

    public getSendRequestToItem(value: SendRequestTo): SelectListItem {
        return this.transform(SendRequestTo, "SendRequestTo", value);
    }

    public getSendRequestToItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SendRequestTo, "SendRequestTo", reverse);
    }

    public getSendRequestToName(value: SendRequestTo): string {
        return this.getSendRequestToItem(value).text;
    }

    public getSendRequestToText(value: SendRequestTo): string {
        return this.localizer.get(this.getSendRequestToName(value));
    }

    public getSendRequestToDescription(value: SendRequestTo): string {
        return this.getSendRequestToItem(value).subtext;
    }

    // #endregion

    // #region ServiceRequestStatus

    public getServiceRequestStatusItem(value: ServiceRequestStatus): SelectListItem {
        return this.transform(ServiceRequestStatus, "ServiceRequestStatus", value);
    }

    public getServiceRequestStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ServiceRequestStatus, "ServiceRequestStatus", reverse);
    }

    public getServiceRequestStatusName(value: ServiceRequestStatus): string {
        return this.getServiceRequestStatusItem(value).text;
    }

    public getServiceRequestStatusText(value: ServiceRequestStatus): string {
        return this.localizer.get(this.getServiceRequestStatusName(value));
    }

    public getServiceRequestStatusDescription(value: ServiceRequestStatus): string {
        return this.getServiceRequestStatusItem(value).subtext;
    }

    // #endregion

    // #region ServiceType

    public getServiceTypeItem(value: ServiceType): SelectListItem {
        return this.transform(ServiceType, "ServiceType", value);
    }

    public getServiceTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ServiceType, "ServiceType", reverse);
    }

    public getServiceTypeName(value: ServiceType): string {
        return this.getServiceTypeItem(value).text;
    }

    public getServiceTypeText(value: ServiceType): string {
        return this.localizer.get(this.getServiceTypeName(value));
    }

    public getServiceTypeDescription(value: ServiceType): string {
        return this.getServiceTypeItem(value).subtext;
    }

    // #endregion

    // #region SortDirection

    public getSortDirectionItem(value: SortDirection): SelectListItem {
        return this.transform(SortDirection, "SortDirection", value);
    }

    public getSortDirectionItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SortDirection, "SortDirection", reverse);
    }

    public getSortDirectionName(value: SortDirection): string {
        return this.getSortDirectionItem(value).text;
    }

    public getSortDirectionText(value: SortDirection): string {
        return this.localizer.get(this.getSortDirectionName(value));
    }

    public getSortDirectionDescription(value: SortDirection): string {
        return this.getSortDirectionItem(value).subtext;
    }

    // #endregion

    // #region UserAgreementType

    public getUserAgreementTypeItem(value: UserAgreementType): SelectListItem {
        return this.transform(UserAgreementType, "UserAgreementType", value);
    }

    public getUserAgreementTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(UserAgreementType, "UserAgreementType", reverse);
    }

    public getUserAgreementTypeName(value: UserAgreementType): string {
        return this.getUserAgreementTypeItem(value).text;
    }

    public getUserAgreementTypeText(value: UserAgreementType): string {
        return this.localizer.get(this.getUserAgreementTypeName(value));
    }

    public getUserAgreementTypeDescription(value: UserAgreementType): string {
        return this.getUserAgreementTypeItem(value).subtext;
    }

    // #endregion
}

//Singleton
export default new EnumProvider();