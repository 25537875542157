import Localizer from "../../../localization/Localizer";
import {onRedirect, pageData} from "@/helpers/CypressHelper";
import GridHelper from "@/helpers/CypressGridHelper";

describe('Desktop - Admin user can open user management', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});

        cy.visit('/');
    });

    it('Page opens from menu navigation', () => {

        onRedirect(() => cy.visit(Localizer.pageRoutesAdmin));

        pageData().admin.links.users().click();

        assertUserManagementPageContent();

    });

    it('Page opens from url', () => {
        onRedirect(() => cy.visit(Localizer.pageRoutesAdminUserManagement));
        assertUserManagementPageContent();
    });

    it('User debug info', () => {
        onRedirect(() => cy.visit(Localizer.pageRoutesAdminUserManagement));
        assertUserManagementPageContent();

        GridHelper.getGridContentByRowAndCell("#table_users_grid", 0, 6).click()

        cy.get("#user_debug_container");

        cy.get("#user_debug_content")
            .invoke('text')
            .then((text) => {
                expect(text.length).to.above(0);
            });
        cy.get("#close_user_debug").click();

        cy.get("#user_debug_container").should('not.exist');

    });

    it('Should display unregistered users in the users grid when the filter is applied', () => {
        const expectedTotalItemCount = 8;

        onRedirect(() => cy.visit(Localizer.pageRoutesAdminUserManagement));
        assertUserManagementPageContent();

        cy.intercept('POST', '/api/Companies/GetUsersPagedList').as('postRequest');

        pageData().admin.users.filters.showOnlyUnregisteredUsersCheckBox().click();

        cy.wait('@postRequest').then(({ request, response }) => {
            // Assert that the request body contains the changed value
            expect(request.body).to.have.property('notProcessedUsers', true);

            assertResponseHasExpectedData(response, expectedTotalItemCount);
        });

    });

    it('Should display registered users in the invitation grid when the filter is applied', () => {
        onRedirect(() => cy.visit(Localizer.pageRoutesAdminUserManagement));
        assertUserManagementPageContent();

        const expectedInvitationsCount = 5;

        pageData().admin.users.filters.showInvitationsSentCheckBox().click();

        pageData().admin.users.filters.showLinkOpenedCheckBox().click();

        cy.get('#table_invitations_grid')
            .find('tbody')
            .not('[class*=athenaeum-grid-spinner]')
            .first()
            .find('tr')
            .not('[class*=athenaeum-grid-details]')
            .should("have.length.at.least", expectedInvitationsCount);
    });

    it('Should display users that opened the link invitation in the invitation grid', () => {
        onRedirect(() => cy.visit(Localizer.pageRoutesAdminUserManagement));
        assertUserManagementPageContent();

        const expectedInvitationsCount = 2;

        pageData().admin.users.filters.showInvitationsSentCheckBox().click();

        pageData().admin.users.filters.showRegisteredCheckBox().click();

        cy.get('#table_invitations_grid')
            .find('tbody')
            .not('[class*=athenaeum-grid-spinner]')
            .first()
            .find('tr')
            .not('[class*=athenaeum-grid-details]')
            .should("have.length.at.least", expectedInvitationsCount);
    });

    it('Should display users that received invitation and did not do any further action', () => {
        onRedirect(() => cy.visit(Localizer.pageRoutesAdminUserManagement));
        assertUserManagementPageContent();

        const expectedInvitationsCount = 1;

        pageData().admin.users.filters.showLinkOpenedCheckBox().click();

        pageData().admin.users.filters.showRegisteredCheckBox().click();

        cy.get('#table_invitations_grid')
            .find('tbody')
            .not('[class*=athenaeum-grid-spinner]')
            .first()
            .find('tr')
            .not('[class*=athenaeum-grid-details]')
            .should("have.length.at.least", expectedInvitationsCount);
    });

    function assertUserManagementPageContent(): void {
        const expectedUsersCount = 10;
        const expectedInvitationsCount = 8;

        cy.get("h1")
            .should('contain', Localizer.adminPageRentaAdminManager);

        cy.get("#table_users_grid")
            .find('tbody')
            .not('[class*=athenaeum-grid-spinner]')
            .first()
            .find('tr')
            .not('[class*=athenaeum-grid-details]')
            .should('have.length', expectedUsersCount);

        pageData().admin.users.usersGrid()
            .should('contain', Localizer.adminUserManagementSelectRole);

        pageData().admin.users.usersGrid()
            .should('contain', Localizer.ordersPageSearch);

        pageData().admin.users.usersGrid()
            .should('contain', Localizer.adminUserManagementFilterNotProcessed);

        cy.get("h3")
            .first()
            .should('contain', Localizer.adminUserManagementGenerateMailingList);

        cy.get("h3")
            .eq(1)
            .should('contain', Localizer.adminUserManagementInviteUser);

        cy.get("h4")
            .should('contain', Localizer.adminUserManagementPendingInvitationsTitle);

        pageData().admin.users.usersInvitationGrid()
            .should('contain', Localizer.ordersPageSearch);

        pageData().admin.users.usersInvitationGrid()
            .should('contain', Localizer.invitationsGridInvitationSent);

        pageData().admin.users.usersInvitationGrid()
            .should('contain', Localizer.invitationsGridLinkOpened);

        pageData().admin.users.usersInvitationGrid()
            .should('contain', Localizer.invitationsGridRegistered);

        cy.get('#table_invitations_grid')
            .find('tbody')
            .not('[class*=athenaeum-grid-spinner]')
            .first()
            .find('tr')
            .not('[class*=athenaeum-grid-details]')
            .should("have.length.at.least", expectedInvitationsCount);
    }

    function assertResponseHasExpectedData(response: any | undefined, expectedTotalItemCount: number) {
        expect(response?.statusCode, 'Response status code should be 200').to.equal(200);
        expect(response?.body.value, 'Response should have a "value" property').to.have.property('items');
        expect(response?.body.value.items, '"items" should be an array').to.be.an('array');
        expect(response?.body.value.pageCount, 'Page count should be 1').to.equal(1);
        expect(response?.body.value.pageNumber, 'Page number should be 1').to.equal(1);
        expect(response?.body.value.pageSize, 'Page size should be 10').to.equal(10);
        expect(response?.body.value.totalItemCount, 'Total item count should match expected').to.be.at.least(expectedTotalItemCount);
    }
});

export default {};
