import {SortDirection} from "@renta-apps/athenaeum-toolkit";
import Localizer from "@/localization/Localizer";
import DropdownHelper from "@/helpers/CypressDropdownHelper";
import {pageData} from "@/helpers/CypressHelper";
import DeviceGridHelper from "@/helpers/DeviceGridHelper";

describe('Desktop - Fleet Monitoring - Return requests filters tests', () => {
    before(() => {
        cy.clearAllLocalStorage();
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

    });

    it('should display view controls', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', {fixture: 'fleetGetReturnsPagedList.json'});

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', {fixture: 'constructionSiteInfos.json'});

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        DeviceGridHelper.getReturnRequestsGrid()
            .should('exist');

        // The filter has five visible elements.
        DeviceGridHelper.getViewControl()
            .children('div')
            .filter(':visible')
            .should('have.length', 3);

        DeviceGridHelper.getDropdown("filter-by-construction-site-dropdown")
            .get('[data-cy="dropdownLabel"]')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsConstructionSite);

        // Open the dropdown.
        DropdownHelper.toggleDropdown(pageData().fleetMonitoring.filters.sortByFilter.dropdown());

        pageData().fleetMonitoring.filters.sortByFilter.dropdown()
            .find('[data-cy="dropdownItem"]')
            .should('have.length', 5)
            .each(($option, index) => {
                switch (index) {
                    case 0:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByPickupTime);
                        break;
                    case 1:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByStatus);
                        break;
                    case 2:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByCompany);
                        break;
                    case 3:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByConstructionSite);
                        break;
                    case 4:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByDepot);
                        break;
                    default:
                        throw new Error('Unexpected option index');
                }
            });

        // Close it.
        DropdownHelper.toggleDropdown(pageData().fleetMonitoring.filters.sortByFilter.dropdown());

        // Open the dropdown.
        DropdownHelper.toggleDropdown(pageData().fleetMonitoring.filters.sortOrderFilter.dropdown());

        pageData().fleetMonitoring.filters.sortOrderFilter.dropdown()
            .find('[data-cy="dropdownItem"]')
            .should('have.length', 2)
            .each(($option, index) => {
                switch (index) {
                    case 0:
                        expect($option).to.contain(Localizer.enumSortDirectionDesc);
                        break;
                    case 1:
                        expect($option).to.contain(Localizer.enumSortDirectionAsc);
                        break;
                    default:
                        throw new Error('Unexpected option index');
                }
            });

        // Close it.
        DropdownHelper.toggleDropdown(pageData().fleetMonitoring.filters.sortOrderFilter.dropdown());

    });

    it('should call API when sort by filter is changed', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', req => {
            if (req.body.sortColumnName !== 'PickupTime') {
                req.alias = 'postRequestChanged';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetReturnsPagedList.json'});
        });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        cy.wait('@postRequest', {timeout: 20_000}).then(({request}) => {
            // Assert the initial request body
            expect(request.body).to.have.property('sortColumnName', 'PickupTime');

            // Select the Company option from the dropdown
            DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.filters.sortByFilter.dropdown(), 2);

            // Wait for the second intercepted POST request to the API endpoint
            cy.wait('@postRequestChanged').then(({request}) => {
                // Assert that the request body contains the changed value
                expect(request.body).to.have.property('sortColumnName', 'CustomerName');

                cy.url().should('include', `pageNumber=1&pageSize=25&sortBy=CustomerName&sortOrder=Asc`);
            });
        });

    });

    it('should call API when sort order filter is changed', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', req => {
            if (req.body.sortDirection !== SortDirection.Asc) {
                req.alias = 'postRequestChanged';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetReturnsPagedList.json'});
        });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        cy.wait('@postRequest', {timeout: 20_000}).then(({request}) => {
            // Assert the initial request body
            expect(request.body).to.have.property('sortDirection', SortDirection.Asc);

            // Select the Ascending option from the dropdown
            DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.filters.sortOrderFilter.dropdown(), 0);

            // Wait for the second intercepted POST request to the API endpoint
            cy.wait('@postRequestChanged').then(({request}) => {
                // Assert that the request body contains the changed value
                expect(request.body).to.have.property('sortDirection', SortDirection.Desc);

                cy.url().should('include', `pageNumber=1&pageSize=25&sortBy=PickupTime&sortOrder=Desc`);
            });
        });

    });

    it('should call API when construction site filter is changed', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', req => {
            if (req.body.constructionSites?.length) {
                req.alias = 'postRequestChanged';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetReturnsPagedList.json'});
        });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        // First call to get devices
        cy.wait('@postRequest', {timeout: 20_000}).then(({request}) => {
            expect(request.body).to.have.property('contractId', '31eaa6c0-42fa-40c2-a891-bcd16c367409');
            expect(request.body).to.have.property('constructionSiteId', null);
            expect(request.body).to.have.property('constructionSites');
            expect(request.body.constructionSites).to.have.length(0);

            // Select a construction site
            DeviceGridHelper.getDropdownInput('filter-by-construction-site-dropdown').should("exist").should("be.visible").click();
            DeviceGridHelper.getDropdownList('filter-by-construction-site-dropdown').find('[data-cy="dropdownItem"]').should('have.length', 14);
            DeviceGridHelper.typeDropdownInput('filter-by-construction-site-dropdown', 'test');
            DeviceGridHelper.getDropdownList('filter-by-construction-site-dropdown').find('[data-cy="dropdownItem"]').should('have.length', 9);
            DeviceGridHelper.clickDropdownListItem('filter-by-construction-site-dropdown', 4);

            // Double check the options are set
            DeviceGridHelper.checkTags(['SAMMUTUKSEN TESTAUS']);

            // The request after the first construction site filter change
            cy.wait('@postRequestChanged').then(({request}) => {
                expect(request.body).to.have.nested.property('constructionSites');
                expect(request.body.constructionSites).to.have.length(1);
                expect(request.body).to.have.nested.property('constructionSites[0].id', '8'); // external id
                expect(request.body).to.have.nested.property('constructionSites[0].name', 'SAMMUTUKSEN TESTAUS');

                // Deselect the construction site
                DeviceGridHelper.deleteTag(0);

                // The request after the second construction site filter change
                cy.wait('@postRequest').then(({request}) => {
                    expect(request.body).to.have.property('constructionSites');
                    expect(request.body.constructionSites).to.have.length(0);

                    DeviceGridHelper.checkTags([]);
                });
            });
        });
    });

    it('should set input fields with the default values correctly', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', {fixture: 'fleetGetDevicesPagedListEmpty.json'}).as('postRequest');

        cy.visit(`/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t&constructionSites=${JSON.stringify([{"name": "TESTITILAUS RENTA EASY", "id": "f7422461-9c46-416d-b500-7ab6ae1ee591"}])}&sortBy=Status&sortOrder=Asc`);

        cy.wait('@postRequest').then(() => {
            DeviceGridHelper.checkDropdownValue("filter-by-construction-site-dropdown", "TESTITILAUS RENTA EASY");

            DeviceGridHelper.checkTags(["TESTITILAUS RENTA EASY"]);

            pageData().fleetMonitoring.filters.sortByFilter.title()
                .should('have.text', Localizer.fleetMonitoringPageFiltersSortByStatus);

            pageData().fleetMonitoring.filters.sortOrderFilter.title()
                .should('have.text', Localizer.enumSortDirectionAsc);

            cy.visit(`/fleet-monitoring?constructionSites=${JSON.stringify([{"name": "TESTITILAUS RENTA EASY 1"}])}&sortOrder=Desc&sortBy=Depot&selectedTab=palautuspyynn%C3%B6t`);

            cy.wait('@postRequest').then(() => {
                DeviceGridHelper.checkDropdownValue("filter-by-construction-site-dropdown", "TESTITILAUS RENTA EASY 1");

                DeviceGridHelper.checkTags(['TESTITILAUS RENTA EASY 1']);

                pageData().fleetMonitoring.filters.sortByFilter.title()
                    .should('have.text', Localizer.fleetMonitoringPageFiltersSortByDepot);

                pageData().fleetMonitoring.filters.sortOrderFilter.title()
                    .should('have.text', Localizer.enumSortDirectionDesc);
            });
        });
    });

    it('should remember user\'s previous selections when the user returns to fleet monitoring', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', {fixture: 'fleetGetReturnsPagedList.json'}).as('postRequest');

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t&constructionSites=[]');

        cy.wait('@postRequest').then(() => {
            DeviceGridHelper.checkTags([]);

            DeviceGridHelper.getDropdownInput('filter-by-construction-site-dropdown').should("exist").should("be.visible").click();
            DeviceGridHelper.getDropdownList('filter-by-construction-site-dropdown').find('[data-cy="dropdownItem"]').should('have.length', 14);
            DeviceGridHelper.typeDropdownInput('filter-by-construction-site-dropdown', 'test');
            DeviceGridHelper.getDropdownList('filter-by-construction-site-dropdown').find('[data-cy="dropdownItem"]').should('have.length', 9);
            DeviceGridHelper.clickDropdownListItem('filter-by-construction-site-dropdown', 4);

            // Select the Name option from the dropdown
            DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.filters.sortByFilter.dropdown(), 4);

            // Select the Ascending option from the dropdown
            DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.filters.sortOrderFilter.dropdown(), 1);

            // Double-check the options are set
            DeviceGridHelper.checkTags(['SAMMUTUKSEN TESTAUS']);

            pageData().fleetMonitoring.filters.sortByFilter.title()
                .should('have.text', Localizer.fleetMonitoringPageFiltersSortByDepot);

            pageData().fleetMonitoring.filters.sortOrderFilter.title()
                .should('have.text', Localizer.enumSortDirectionAsc);

            // Leave the page
            cy.visit('/rent');

            // Go back to the page
            cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

            // Options are remembered
            DeviceGridHelper.checkTags(['SAMMUTUKSEN TESTAUS']);

            pageData().fleetMonitoring.filters.sortByFilter.title()
                .should('have.text', Localizer.fleetMonitoringPageFiltersSortByDepot);

            pageData().fleetMonitoring.filters.sortOrderFilter.title()
                .should('have.text', Localizer.enumSortDirectionAsc);
        });
    });
});