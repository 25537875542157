import React, {useEffect, useMemo, useRef, useState} from "react";
import {Modal, ModalSize} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import styles from "./RequestReturnModal.module.scss";
import {Button, ButtonSize, ButtonType, Icon} from "@renta-apps/renta-react-components";
import ContentBox from "@/pages/FleetMonitoring/Modals/RequestReturnModal/ContentBox";
import DevicesTable from "@/pages/FleetMonitoring/Modals/RequestReturnModal/DevicesTable";
import {getConstructionSiteEquipmentAsync} from "@/services/FleetService";
import RetrievalAddress from "@/pages/FleetMonitoring/Modals/RequestReturnModal/RetrievalAddress";
import ContactInformation from "@/pages/FleetMonitoring/Modals/RequestReturnModal/ContactInformation";
import {ch} from "@renta-apps/athenaeum-react-common";
import UserContext from "@/models/server/UserContext";
import EarliestFetchTime from "@/pages/FleetMonitoring/Modals/RequestReturnModal/EarliestFetchTime";
import AdditionalInformation from "@/pages/FleetMonitoring/Modals/RequestReturnModal/AdditionalInformation";
import ReturnModel from "@/models/server/ReturnModel";
import {DeviceReturnModel} from "@/models/server/DeviceReturnModel";
import ConstructionSiteReturn from "@/models/server/ConstructionSiteReturn";
import {getSiteUsers, postReturnProduct, resolveSiteLocation} from "@/services/ConstructionSiteService";
import {EquipmentGroupReturnModel} from "@/models/server/EquipmentGroupReturnModel";
import LocationModel from "@/models/server/LocationModel";
import CustomerInformation from "@/pages/FleetMonitoring/Modals/RequestReturnModal/CustomerInformation";
import ConstructionSiteUserModel from "@/models/server/ConstructionSiteUserModel";
import InternalNotes from "@/pages/FleetMonitoring/Modals/RequestReturnModal/InternalNotes";
import {DeviceModel} from "@/models/server/DeviceModel";

type RequestReturnModalProps = {
    isOpen: boolean;
    isEasyPlusUser: boolean;
    selectedDevices: DeviceModel[] | null;
    onClose(): void;
}

export type DeviceReturnGridModel = {
    rentaId: string;
    deviceName: string;
    contractNumber: string;
    idleDays: number | null;
    rentedAmount: number;
    returnAmount: number;
    selected: boolean;
}

export type RetrievalAddressModel = {
    streetAddress: string;
    postalCode: string;
    city: string;
}

export type FetchContactInformationModel = {
    contactPersonEmail: string;
    contactPersonPhone: string;
}

export type CustomerContactInformationModel = FetchContactInformationModel & {
    contactPersonFirstName: string;
    contactPersonLastName: string;
    useFreeTextUser: boolean;
    userId: string | null;
}

export type FetchTimeModel = {
    availableNow: boolean;
    datetime: Date;
}

const RequestReturnModal: React.FC<RequestReturnModalProps> = ({isOpen, isEasyPlusUser, onClose, selectedDevices}) => {
    const modalRef = useRef<Modal>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const [constructionSiteId, setConstructionSiteId] = useState<string | null>(null);
    const [contractId, setContractId] = useState<string | null>(null);
    const [gridDevices, setGridDevices] = useState<DeviceReturnGridModel[] | null>(null);
    const [users, setUsers] = useState<ConstructionSiteUserModel[] | null>(null);
    const [devices, setDevices] = useState<{ [key: string]: DeviceReturnModel } | null>(null);
    const [address, setAddress] = useState<RetrievalAddressModel | null>(null);
    const [fetchTime, setFetchTime] = useState<FetchTimeModel | null>(null);
    const [additionalInformation, setAdditionalInformation] = useState<string | null>(null);
    const [internalNotes, setInternalNotes] = useState<string | null>(null);
    const [hasFormErrors, setHasFormErrors] = useState(false);
    const [sendingRequest, setSendingRequest] = useState(false);
    const [requestError, setRequestError] = useState(false);
    const [addressLoading, setAddressLoading] = useState(false);
    const loadingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const {userEmail, userPhone, adminUser} = useMemo(() => {
        const userContext = ch.getContext() as UserContext;
        return {
            userEmail: userContext.user?.email ?? '',
            userPhone: userContext.user?.phoneNumber ?? '',
            adminUser: userContext.isAdmin,
        };
    }, []);
    const [customerContactInformation, setCustomerContactInformation] = useState<CustomerContactInformationModel>({
        contactPersonFirstName: "",
        contactPersonLastName: "",
        contactPersonEmail: "",
        contactPersonPhone: "",
        useFreeTextUser: false,
        userId: null,
    });

    const [fetchContactInformation, setFetchContactInformation] = useState<FetchContactInformationModel>({
        contactPersonEmail: "",
        contactPersonPhone: "",
    });

    useEffect(() => {
        if (isOpen) {
            modalRef.current?.openAsync();
        }
        else {
            modalRef.current?.closeAsync();
        }
    }, [modalRef, isOpen]);

    useEffect(() => {
        if (!isOpen || !selectedDevices?.length) {
            return;
        }
        // for now, we only handle single device and single construction site
        const constructionSiteId = selectedDevices[0].constructionSiteId;
        const contractId = selectedDevices[0].customerId;
        if (!constructionSiteId) {
            setGridDevices([]);
            return;
        }
        setConstructionSiteId(constructionSiteId);
        setContractId(contractId ?? null);
        getEquipment(constructionSiteId).catch(() => setGridDevices([]));
        if (adminUser) {
            getUsers(constructionSiteId).catch(() => setUsers([]));
        }
    }, [selectedDevices, isOpen]);

    const forceFreeTextUser = useMemo(() => {
        return customerContactInformation.useFreeTextUser && (!customerContactInformation.contactPersonEmail || !customerContactInformation.contactPersonPhone);
    }, [customerContactInformation]);

    const getUsers = async (constructionSiteId: string) => {
        const dropDownUsers: ConstructionSiteUserModel[] = await getSiteUsers(constructionSiteId, true);
        setUsers(dropDownUsers);
    };

    const getEquipment = async (constructionSiteId: string) => {
        setAddressLoading(true);
        const response = await getConstructionSiteEquipmentAsync(constructionSiteId);
        if (!response?.devices) {
            setGridDevices([]);
            return;
        }

        const devices: DeviceReturnGridModel[] = [];
        for (const device of response.devices) {
            const selected = selectedDevices!.some(d => d.rentaId === device.rentaId);
            devices.push({
                rentaId: device.rentaId,
                deviceName: device.deviceName ?? "",
                contractNumber: device.contractNumber ?? "",
                idleDays: device.idleDays,
                rentedAmount: device.combinedAmount!,
                returnAmount: selected ? device.combinedAmount! : 0,
                selected: selected,
            });
        }

        if (loadingTimeout.current) {
            clearTimeout(loadingTimeout.current);
        }

        // Prepare to set address after loading completes
        let pendingAddress: RetrievalAddressModel | null = null;

        if (response.constructionSiteAddress) {
            pendingAddress = {
                streetAddress: response.constructionSiteAddress ?? "",
                postalCode: response.constructionSitePostalCode ?? "",
                city: response.constructionSiteCity ?? "",
            };
        } else if (response.constructionSiteLongitude && response.constructionSiteLatitude) {
            try {
                const resolvedLocation: LocationModel | null = await resolveSiteLocation({
                    latitude: response.constructionSiteLatitude!,
                    longitude: response.constructionSiteLongitude!
                });
                pendingAddress = {
                    streetAddress: resolvedLocation?.address ?? "",
                    postalCode: resolvedLocation?.postalCode ?? "",
                    city: resolvedLocation?.city ?? "",
                };
            } catch {
                setAddressLoading(false); // Stop loading if API call fails
                return;
            }
        }

        setDevices(response.devices.reduce((acc, device) => ({
            ...acc,
            [`${device.rentaId}_${device.deviceName ?? ""}_${device.contractNumber}`]: device,
        }), {}));
        setGridDevices(devices.sort(sortDevices));

        loadingTimeout.current = setTimeout(() => {
            setAddressLoading(false);
            if (pendingAddress) {
                setAddress(pendingAddress);
            }
        }, 5000);

        setFetchTime({
            availableNow: true,
            datetime: new Date(),
        });
        setAdditionalInformation("");
        setInternalNotes("");
    };

    const sortDevices = (device1: DeviceReturnGridModel, device2: DeviceReturnGridModel): number => {
        if (device1.selected && !device2.selected) {
            return -1;
        }

        if (!device1.selected && device2.selected) {
            return 1;
        }

        if (device1.idleDays !== null && device2.idleDays !== null && device1.idleDays !== device2.idleDays) {
            return device2.idleDays - device1.idleDays;
        }

        if (device1.idleDays === null && device2.idleDays !== null) {
            return 1;
        }

        if (device1.idleDays !== null && device2.idleDays === null) {
            return -1;
        }

        return device1.deviceName.localeCompare(device2.deviceName);
    };
    
    const checkCustomerSelectionMissing = (): boolean => {
        if (!adminUser || customerContactInformation.useFreeTextUser) {
            return false;
        }

        return !customerContactInformation.userId;
    };

    const onSendReturnRequest = async () => {
        setRequestError(false);
        if (!formRef.current?.checkValidity() || !gridDevices?.some(device => device.selected && device.returnAmount > 0) || checkCustomerSelectionMissing()) {
            setHasFormErrors(true);
            return;
        }

        setSendingRequest(true);

        const returnModel: ReturnModel = new ReturnModel();
        returnModel.products = [];

        // Customer contact info
        returnModel.contactEmail = fetchContactInformation.contactPersonEmail;
        returnModel.contactPhone = fetchContactInformation.contactPersonPhone;
        
        if (adminUser) {
            if (customerContactInformation.useFreeTextUser) {
                returnModel.nonUserFirstName = customerContactInformation.contactPersonFirstName || null;
                returnModel.nonUserLastName = customerContactInformation.contactPersonLastName || null;
                returnModel.nonUserEmail = customerContactInformation.contactPersonEmail || null;
                returnModel.nonUserPhone = customerContactInformation.contactPersonPhone || null;
            } else {
                returnModel.returnedOnBehalf = customerContactInformation.userId;
            }
        }

        returnModel.comment = additionalInformation!;
        returnModel.internalNotes = internalNotes!;
        returnModel.address = address!.streetAddress;
        returnModel.city = address!.city;
        returnModel.postalCode = address!.postalCode;
        returnModel.availableForPickup = fetchTime!.availableNow;
        returnModel.pickupTime = fetchTime!.datetime;
        returnModel.constructionSiteId = constructionSiteId!;
        returnModel.organizationId = contractId;

        const selectedDevices = gridDevices.filter(device => device.selected);
        for (const selectedDevice of selectedDevices) {
            const device = devices![`${selectedDevice.rentaId}_${selectedDevice.deviceName}_${selectedDevice.contractNumber}`];
            if (!device) {
                continue;
            }

            const returnDevice: ConstructionSiteReturn = {
                name: device.deviceName,
                count: selectedDevice.returnAmount,
                rentaId: device.rentaId,
                rentalObjectId: device.rentalObjectId,
                rentalObjectNumber: device.rentalObjectNumber,
                inventoryItemDisplayNumber: device.inventoryItemDisplayNumber,
                contractNumber: device.contractNumber,
                depotExternalId: device.depotExternalId,
                orderLineId: device.orderLineId,
            };

            if (!device.massProduct) {
                returnModel.products.push(returnDevice);
                continue;
            }

            const deviceRentedAmount = device.rentedAmount ?? 0;
            if (selectedDevice.returnAmount <= deviceRentedAmount) {
                returnModel.products.push(returnDevice);
                continue;
            }

            let overFlow = selectedDevice.returnAmount - deviceRentedAmount;
            returnDevice.count = deviceRentedAmount;
            returnModel.products.push(returnDevice);

            let index: number = 0;
            while (overFlow >= 0) {
                if (!device.equipmentGroup) {
                    break;
                }

                let equipmentGroup: EquipmentGroupReturnModel | undefined = device.equipmentGroup[index++];
                if (!equipmentGroup) {
                    break;
                }

                const equipmentCount = equipmentGroup.rentedAmount ?? 0;
                let clone: ConstructionSiteReturn = {
                    ...returnDevice,
                    contractNumber: equipmentGroup.contractNumber,
                    count: equipmentCount > overFlow ? overFlow : equipmentCount,
                    depotExternalId: equipmentGroup.depotExternalId,
                    orderLineId: equipmentGroup.orderLineId,
                };
                returnModel.products.push(clone);

                overFlow -= equipmentCount;
            }
        }

        try {
            await postReturnProduct(returnModel);
        } catch (e) {
            setRequestError(true);
            setSendingRequest(false);
            return;
        }

        handleClose();
    };

    const handleClose = () => {
        // reset all values
        setGridDevices(null);
        setDevices(null);
        setAddress(null);
        setCustomerContactInformation({
            contactPersonFirstName: "",
            contactPersonLastName: "",
            contactPersonEmail: "",
            contactPersonPhone: "",
            useFreeTextUser: false,
            userId: null,
        });
        setFetchContactInformation({
            contactPersonEmail: "",
            contactPersonPhone: "",
        });
        setFetchTime(null);
        setAdditionalInformation(null);
        setInternalNotes(null);
        setConstructionSiteId(null);
        setContractId(null);
        setHasFormErrors(false);
        setRequestError(false);
        setSendingRequest(false);
        setUsers(null);
        onClose();
    };

    const handleCheckboxChange = (device: DeviceReturnGridModel, value: boolean) => {
        setGridDevices(current => current?.map(item => {
            if (item.rentaId === device.rentaId && item.contractNumber === device.contractNumber && item.deviceName === device.deviceName) {
                return {
                    ...item,
                    selected: value,
                    returnAmount: value ? item.rentedAmount : 0,
                };
            }
            return item;
        }) ?? null);
    };

    const handleReturnAmountChange = (rentaId: string, value: number) => {
        setGridDevices(current => current?.map(device => {
            if (device.rentaId === rentaId) {
                return {
                    ...device,
                    returnAmount: value,
                };
            }
            return device;
        }) ?? null);
    };

    const handleHeaderCheckboxChange = (value: boolean) => {
        setGridDevices(current => current?.map(device => ({
            ...device,
            selected: value,
            returnAmount: value ? device.rentedAmount : 0,
        })) ?? null);
    };

    const handleAddressChange = (key: keyof RetrievalAddressModel, value: string) => {
        setAddress(current => ({
            ...current!,
            [key]: value,
        }));
    };

    const handleCustomerContactInfoChange = (
        customerContactInformationModel: CustomerContactInformationModel
    ) => {
        setCustomerContactInformation(customerContactInformationModel);
    };

    const handleFetchContactInformationChange = (newModel: FetchContactInformationModel) => {
        setFetchContactInformation(prevModel => {
            // Only update state if there are changes
            if (prevModel.contactPersonEmail !== newModel.contactPersonEmail ||
                prevModel.contactPersonPhone !== newModel.contactPersonPhone) {
                return newModel;
            }
            return prevModel; // Return previous state if there are no changes
        });
    };

    const handleFetchTimeChange = (datetime: Date) => {
        setFetchTime({
            availableNow: false,
            datetime,
        });
    };

    const handleAvailableNowChange = (availableNow: boolean) => {
        setFetchTime({
            availableNow: availableNow,
            datetime: new Date(),
        });
    };

    const handleFocus = () => {
        // Cancel loading if user interacts with the input
        setAddressLoading(false);
        if (loadingTimeout.current) {
            clearTimeout(loadingTimeout.current);
            loadingTimeout.current = null;
        }
    };

    return (
        isOpen ? (
            <Modal
                preventEsc preventClosingOnOutsideClick preventClosingOnInsideClick
                ref={modalRef}
                id="requestReturnModal"
                size={ModalSize.ExtraLarge}
                title={Localizer.returnRequestModalTitle}
                className={styles.modal}
                onClose={async () => handleClose()}
            >
                <form ref={formRef} className={`${styles.mainContainer} ${hasFormErrors ? styles.hasErrors : ''}`}>
                    <ContentBox title={Localizer.returnRequestModalHeader}
                                subTitle={Localizer.returnRequestModalSubheader}
                                className={styles.mainBox}
                                children={null}
                    />
                    <DevicesTable devices={gridDevices}
                                  isEasyPlusUser={isEasyPlusUser}
                                  onCheckboxChange={handleCheckboxChange}
                                  onReturnAmountChange={handleReturnAmountChange}
                                  onHeaderCheckboxChange={handleHeaderCheckboxChange}
                    />
                    <RetrievalAddress address={address}
                                      onChange={handleAddressChange}
                                      loading={addressLoading}
                                      onFocus={handleFocus}
                    />
                    {adminUser && (
                        <CustomerInformation users={users ?? []}
                                             onUserDetailsChange={handleCustomerContactInfoChange}
                        />
                    )}
                    <ContactInformation onFetchContactChange={handleFetchContactInformationChange}
                                        forceFreeText={forceFreeTextUser}
                                        initialEmail={adminUser ? customerContactInformation.contactPersonEmail : userEmail}
                                        initialPhone={adminUser ? customerContactInformation.contactPersonPhone : userPhone}
                                        adminMode={adminUser}
                    />
                    <EarliestFetchTime model={fetchTime}
                                       onDateTimeChange={handleFetchTimeChange}
                                       onAvailableNowChange={handleAvailableNowChange}
                    />
                    <AdditionalInformation additionalInformation={additionalInformation}
                                           onChange={setAdditionalInformation}
                    />
                    {adminUser && (
                        <InternalNotes internalNotes={internalNotes}
                                       onChange={setInternalNotes}
                        />
                    )}
                    <div className={styles.bottomContainer}>
                        {requestError && (
                            <div className={styles.errorContainer}>
                                {Localizer.returnRequestModalSendingFailed}
                            </div>
                        )}
                        <div className={styles.actionButtons} data-cy="return-modal-action-buttons">
                            {sendingRequest ? (
                                <>
                                    <div>
                                        <Icon name="fa-solid fa-circle-notch fa-spin" size={24} color="#fe5000"/>
                                    </div>
                                    <div>
                                        {Localizer.fleetMonitoringPageSubscribeToAlertsModalPleaseWait}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Button onClick={() => handleClose()}
                                            size={ButtonSize.Default}
                                            type={ButtonType.Secondary}
                                            className={styles.button}
                                            disabled={gridDevices === null}
                                    >
                                        {Localizer.formCancel}
                                    </Button>
                                    <Button onClick={() => onSendReturnRequest()}
                                            size={ButtonSize.Default}
                                            type={ButtonType.Primary}
                                            className={styles.button}
                                            disabled={gridDevices === null}
                                    >
                                        {Localizer.returnRequestModalSendReturnRequest}
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                </form>
            </Modal>
        ) : null
    );
};

export default RequestReturnModal;