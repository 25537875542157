import React, {useEffect, useMemo, useRef, useState} from "react";
import {ConstructionSiteModel} from "@/models/server/ConstructionSiteModel";
import Equipments from "@/pages/ConstructionSiteDetails/Equipments/Equipments";
import ConstructionSiteEquipmentMap from "@/pages/ConstructionSiteDetails/ConstructionSiteEquipmentMap/ConstructionSiteEquipmentMap";
import {Grid} from "@renta-apps/athenaeum-react-components";
import {PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {Tab, Tabs} from "@renta-apps/renta-react-components";
import Invoices from "@/pages/ConstructionSiteDetails/Invoices/Invoices";
import EnvironmentControl from "@/pages/ConstructionSiteDetails/EnvironmentControl/EnvironmentControl";
import ConstructionSiteUsers from "@/pages/ConstructionSiteDetails/ConstructionSiteUsers/ConstructionSiteUsers";
import ConstructionSiteServices from "@/pages/ConstructionSiteDetails/ConstructionSiteServices/ConstructionSiteServices";
import ConstructionSiteDetailsHeader from "@/pages/ConstructionSiteDetails/ConstructionSiteDetailsHeader/ConstructionSiteDetailsHeader";
import ConstructionSiteServiceRequestModel from "@/models/server/ConstructionSiteServiceRequestModel";
import ConstructionSiteEmissions from "@/pages/ConstructionSiteDetails/Emissions/ConstructionSiteEmissions";
import Localizer from "@/localization/Localizer";
import styles from './ConstructionSiteDetailsNew.module.scss';
import {GeoLocation} from "@renta-apps/athenaeum-toolkit";
import StatusPageContainer from "@/pages/ConstructionSiteDetails/Status/StatusPageContainer";
import PowerPageContainer from "@/pages/ConstructionSiteDetails/Power/PowerPageContainer";

interface ConstructionSiteDetailsProps {
    constructionSite: ConstructionSiteModel | null;
    constructionSiteId: string;
    renderPriceAlert: () => React.ReactNode;
    co2FeatureEnabled: boolean;
    statusPageEnabled: boolean;
    powerPageEnabled: boolean;
    environmentControlFeatureEnabled: boolean;
    canSeeInvoices: boolean;
    canManageUsers: boolean;
    selectedTab: string | undefined;
    route: PageRoute;
}

const ConstructionSiteDetailsNew: React.FC<ConstructionSiteDetailsProps> = (props) => {
    const servicesGrid = useRef<Grid<ConstructionSiteServiceRequestModel>>(null);
    const [constructionSite, setConstructionSite] = useState(props.constructionSite);

    useEffect(() => {
        setConstructionSite(props.constructionSite);
    }, [props.constructionSite]);

    const visibleTabs = useMemo(() => {
        return [
            {
                id: "tab_statusTab",
                title: Localizer.constructionSiteDetailsStatusTab,
                isVisible: props.statusPageEnabled,
                render: () => <StatusPageContainer constructionSiteId={props.constructionSiteId} />,
            }, {
                id: "tab_productsTab",
                title: Localizer.constructionSiteDetailsEquipmentTab,
                isVisible: true,
                render: () => <Equipments constructionSiteId={props.constructionSiteId} serviceCreated={onServiceCreated}/>,
            }, {
                id: "tab_productMapTab",
                title: Localizer.constructionSiteDetailsEquipmentMap,
                isVisible: true,
                render: () => (
                    <ConstructionSiteEquipmentMap constructionSiteId={props.constructionSiteId}
                                                  onConstructionSiteLocationChanged={onConstructionSiteLocationChanged}
                    />
                ),
            }, {
                id: "tab_invoicesTab",
                title: Localizer.constructionSiteDetailsInvoiceTab,
                isVisible: props.canSeeInvoices,
                render: () => <Invoices constructionSiteId={props.constructionSiteId}/>,
            }, {
                id: "tab_environmental",
                title: Localizer.constructionSiteDetailsEnvironmentControlTab,
                isVisible: props.environmentControlFeatureEnabled,
                render: () => <EnvironmentControl constructionSiteId={props.constructionSiteId}/>,
            }, {
                id: "tab_usersTab",
                title: Localizer.constructionSiteUsers,
                isVisible: true,
                render: () => (props.constructionSite) && (
                    <ConstructionSiteUsers constructionSiteId={props.constructionSiteId}
                                           constructionSiteOwnerId={props.constructionSite!.ownerId}
                                           canManageUsers={props.canManageUsers}
                    />
                ),
            }, {
                id: "tab_serviceRequestsTab",
                title: Localizer.constructionSiteDetailsServicesTab,
                isVisible: true,
                render: () => (props.constructionSite) && (
                    <ConstructionSiteServices constructionSiteId={props.constructionSiteId}
                                              _servicesGrid={servicesGrid}
                    />
                ),
            }, {
                id: "tab_emissions2",
                title: Localizer.constructionSiteDetailsCo2Emissions,
                route: "Co2 Emissions",
                isVisible: props.co2FeatureEnabled,
                render: () => <ConstructionSiteEmissions constructionSiteId={props.constructionSiteId} />,
            }, {
                id: "tab_powerTab",
                title: Localizer.constructionSiteDetailsPowerTab,
                route: "Power",
                isVisible: props.powerPageEnabled,
                render: () => <PowerPageContainer constructionSiteId={props.constructionSiteId} />,
            },
        ].filter(tab => tab.isVisible);
    }, [props.statusPageEnabled, props.co2FeatureEnabled, props.powerPageEnabled, props.canSeeInvoices, props.environmentControlFeatureEnabled, props.constructionSite, props.constructionSiteId, props.canManageUsers]);

    const onConstructionSiteLocationChanged = (location: GeoLocation) => {
        setConstructionSite(prev => prev ? {...prev, location: location} : null);
    };

    const onServiceCreated = async () => {
        await servicesGrid.current?.reloadAsync();
    };

    const getCurrentRouteWithTabNameAdded = (selectedTab: string): PageRoute =>  {
        const route = PageRoute.clone(props.route);
        (route.parameters! as {selectedTab?: string}).selectedTab = selectedTab.toLowerCase();
        return route;
    };

    const addTabNameToRoute = async (tabName: string): Promise<void> => {
        try {
            await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(getCurrentRouteWithTabNameAdded(tabName), false);
        } catch (error) {
            console.error("Failed to change tab:", error);
        }
    };

    return (
        <div className={styles.constructionSiteDetails}>
            {props.renderPriceAlert()}
            <ConstructionSiteDetailsHeader constructionSite={constructionSite} />
            <Tabs tabHeaderContainerClassName={styles.tabHeaderContainer}
                  tabHeaderWrapperClassName={styles.tabHeaderWrapper}
                  tabHeaderListClassName={styles.tabHeaderList}
                  tabContentWrapperClassName={styles.tabContentWrapper}
                  activeTabByLabelOrRoute={props.selectedTab}
                  onSelect={(_tabId: string, label: string, route: string | undefined) => addTabNameToRoute(route ?? label)}
            >
                {visibleTabs.map(tab => (
                    <Tab id={tab.id}
                         label={tab.title}
                         route={tab.route}
                         key={tab.title}
                    >
                        {tab.render()}
                    </Tab>
                ))}
            </Tabs>
        </div>
    );
};

export default ConstructionSiteDetailsNew;