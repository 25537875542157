import React, {useEffect, useMemo, useState} from 'react';
import {Box, Checkbox, LoadingDecorator, RentaPlusDecorator, RentaPlusDecoratorPosition} from '@renta-apps/renta-react-components';
import Localizer from "@/localization/Localizer";
import {DeviceModel} from "@/models/server/DeviceModel";
import {DeviceDetails} from '@/models/server/DeviceDetails';
import styles from './DeviceGrid.module.scss';
import DeviceDetailsRequestModel from "@/models/server/Requests/DeviceDetailsRequestModel";
import {requestContact} from "@/services/EasyPlusService";
import EasyPlusInfoModal from "@/components/EasyPlus/EasyPlusInfoModal/EasyPlusInfoModal";
import DeviceGridRow from "@/pages/FleetMonitoring/DeviceGrid/DeviceGridRow";
import {DeviceGridModel, getItemKey} from "@/pages/FleetMonitoring/FleetMonitoringContainer";

interface IDeviceGridProps {
    data: DeviceGridModel[];
    deviceDetails: (item: DeviceModel) => Promise<DeviceDetails>;
    devicesDetails: (devices: DeviceDetailsRequestModel[]) => Promise<DeviceDetails[]>;
    onGearButtonClick: (item: DeviceModel) => void;
    onReturnButtonClick: (item: DeviceModel) => void;
    onDebugClick?: (assetId: string, rentaId: string) => Promise<void>;
    onRowCheckboxClick: (selectedDevices: string[]) => void;
    selectedItemKeys: Set<string>;
    isEasyPlusUser: boolean;
    isLoading: boolean;
    gridRef: React.RefObject<HTMLDivElement>;
}

const DeviceGrid: React.FC<IDeviceGridProps> = ({
    data,
    deviceDetails,
    devicesDetails,
    onDebugClick,
    onGearButtonClick,
    onReturnButtonClick,
    onRowCheckboxClick,
    selectedItemKeys,
    isEasyPlusUser,
    isLoading,
    gridRef,
}) => {
    const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());
    const [deviceDetailsMap, setDeviceDetailsMap] = useState<{ [key: string]: DeviceDetails | null }>({});

    const [isEasyPlusModalOpen, setIsEasyPlusModalOpen] = useState(false);

    useEffect(() => {
        // Reset expanded items when data changes
        setExpandedItems(new Set());
    }, [data]);

    const headerCheckboxChecked = useMemo(() => {
        const selectedCount = selectedItemKeys.size;

        return selectedCount === 0 ? false : selectedCount === data.length ? true : 'partial';
    }, [data, selectedItemKeys]);

    const handleAccordionButtonClick = async (item: DeviceGridModel) => {
        const key = item.itemKey;
        setExpandedItems(prevExpandedItems => {
            const newExpandedItems = new Set(prevExpandedItems);
            if (newExpandedItems.has(key)) {
                newExpandedItems.delete(key);
            } else {
                newExpandedItems.add(key);
            }
            return newExpandedItems;
        });

        if (!deviceDetailsMap[key]) {
            try {
                const details = await deviceDetails(item);
                setDeviceDetailsMap(prevDetails => ({...prevDetails, [key]: details}));
            } catch (error) {
                console.error('Error loading device details:', error);
                setDeviceDetailsMap(prevDetails => ({...prevDetails, [key]: null}));
            }
        }
    };

    const handleHeaderAccordionClick = async () => {
        const allExpanded = expandedItems.size === data.length;

        if (allExpanded) {
            // Collapse all items
            setExpandedItems(new Set());
        } else {
            // Expand all items
            const newExpandedItems = new Set(data.map(item => item.itemKey));
            setExpandedItems(newExpandedItems);

            // Fetch device details for all items that are not already in the map
            const fetchRequest = data
                .filter((item) => !deviceDetailsMap[item.itemKey])
                .map(({assetId, rentaId, contractNumber, orderLineId}) => ({assetId, rentaId, contractNumber, orderLineId}));

            if (!fetchRequest.length) {
                return;
            }

            try {
                const details = await devicesDetails(fetchRequest);
                const detailsMap = details.reduce((map, detail) => {
                    map[getItemKey(detail)] = detail;
                    return map;
                }, {} as { [key: string]: DeviceDetails });
                setDeviceDetailsMap(prevDetails => ({...prevDetails, ...detailsMap}));
            } catch (error) {
                console.error('Error loading devices details:', error);
                const detailsMap = fetchRequest.reduce((map, detail) => {
                    map[getItemKey(detail)] = null;
                    return map;
                }, {} as { [key: string]: DeviceDetails | null });
                setDeviceDetailsMap(prevDetails => ({...prevDetails, ...detailsMap}));
            }
        }
    };

    const handleHeaderCheckboxClick = () => {
        // If all checkboxes are selected, deselect them. Otherwise, select all checkboxes.
        const selectedDevices = selectedItemKeys.size === data.length ? [] : data.map(item => item.itemKey);

        // Update parent component what items are selected.
        onRowCheckboxClick(selectedDevices);
    };

    const handleRowCheckboxClick = (itemKey: string) => {
        const selectedDevices = selectedItemKeys.has(itemKey)
            ? [...selectedItemKeys].filter(key => key !== itemKey)
            : [...selectedItemKeys, itemKey];

        onRowCheckboxClick(selectedDevices);
    };

    return (
        <>
            <div className={styles.dataTable} ref={gridRef} data-cy="fleet-monitoring-grid">
                {/* Header Row */}
                <div className={`${styles.gridRow} ${styles.dataRow} ${styles.header}`}>
                    <div className={styles.name}>
                        <div className={styles.checkboxCell}>
                            <Checkbox
                                checked={headerCheckboxChecked}
                                onChange={handleHeaderCheckboxClick}
                            />
                        </div>
                        <div>{Localizer.fleetMonitoringPageGridDeviceName}</div>
                    </div>
                    <div className={styles.rentaId}>{Localizer.fleetMonitoringPageGridId}</div>
                    <div className={styles.alerts}>
                        <RentaPlusDecorator tooltipContent={Localizer.easyPlusDecoratorTooltip}
                                            position={RentaPlusDecoratorPosition.OUTSIDE_TOP}
                                            onClick={() => setIsEasyPlusModalOpen(true)}
                                            disabled={isEasyPlusUser}>
                            {Localizer.fleetMonitoringPageGridAlerts}
                        </RentaPlusDecorator>
                    </div>
                    <div className={styles.idle}>
                        <RentaPlusDecorator tooltipContent={Localizer.easyPlusDecoratorTooltip}
                                            position={RentaPlusDecoratorPosition.OUTSIDE_TOP}
                                            onClick={() => setIsEasyPlusModalOpen(true)}
                                            disabled={isEasyPlusUser}>
                            {Localizer.fleetMonitoringPageGridIdle}
                        </RentaPlusDecorator>
                    </div>
                    <div className={styles.battery}>
                        <RentaPlusDecorator tooltipContent={Localizer.easyPlusDecoratorTooltip}
                                            position={RentaPlusDecoratorPosition.OUTSIDE_TOP}
                                            onClick={() => setIsEasyPlusModalOpen(true)}
                                            disabled={isEasyPlusUser}>
                            {Localizer.fleetMonitoringPageGridBattery}
                        </RentaPlusDecorator>
                    </div>
                    <div className={styles.fluid}>
                        <RentaPlusDecorator tooltipContent={Localizer.easyPlusDecoratorTooltip}
                                            position={RentaPlusDecoratorPosition.OUTSIDE_TOP}
                                            onClick={() => setIsEasyPlusModalOpen(true)}
                                            disabled={isEasyPlusUser}>
                            {Localizer.fleetMonitoringPageGridFuel}
                        </RentaPlusDecorator>
                    </div>
                    <div className={`${styles.gridItem} ${styles.actions}`}>
                        <div className={styles.accordionButton}
                             onClick={() => handleHeaderAccordionClick()}>
                            <i className={`fas fa-angle-down ${(data.length > 0 && expandedItems.size === data.length) ? styles.rotated : ''}`}></i>
                        </div>
                    </div>
                </div>

                {/* Loading Rows */}
                {[...Array(4)].map((_, index) => (
                    <LoadingDecorator key={index} className={styles.loaderContainer} hideConentOnLoading={false} isLoading={isLoading}/>
                ))}

                {/* Display information about no data */}
                {!isLoading && data.length === 0 &&
                    <Box display="flex" alignItems="center" justifyContent="center" className={styles.loaderContainer}>
                        {Localizer.fleetMonitoringPageGridEmpty}
                    </Box>
                }

                {/* Data Rows */}
                {!isLoading && data.map((item, index) => (
                    <DeviceGridRow key={item.itemKey}
                                   index={index}
                                   item={item}
                                   selected={selectedItemKeys.has(item.itemKey)}
                                   expanded={expandedItems.has(item.itemKey)}
                                   deviceDetails={deviceDetailsMap[item.itemKey]}
                                   easyPlusUser={isEasyPlusUser}
                                   onRowCheckboxClick={() => handleRowCheckboxClick(item.itemKey)}
                                   onAccordionButtonClick={() => handleAccordionButtonClick(item)}
                                   onGearButtonClick={() => onGearButtonClick(item)}
                                   onReturnButtonClick={() => onReturnButtonClick(item)}
                                   onDebugClick={onDebugClick}
                    />
                ))}
            </div>

            <EasyPlusInfoModal isOpen={isEasyPlusModalOpen}
                               onClose={() => setIsEasyPlusModalOpen(false)}
                               onContactMeClick={async () => await requestContact()}
            />
        </>
    );
};

export default DeviceGrid;