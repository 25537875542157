import React from 'react';
import DailyConsumption from "@/pages/ConstructionSiteDetails/Power/Charts/DailyConsumption";
import styles from './PowerPageContainer.module.scss';
import Localizer from "@/localization/Localizer";

interface StatusPageContainerProps {
    constructionSiteId: string;
}

const PowerPageContainer: React.FC<StatusPageContainerProps> = ({constructionSiteId}) => {
    return (
        <div className={styles.chartsContainer}>
            <div className={styles.chart}>
                <h2>{Localizer.constructionSiteDetailsPowerConsumptionTitle}</h2>
                <DailyConsumption constructionSiteId={constructionSiteId}/>
            </div>
            <div className={styles.chart}>
                {
                    // placeholder for the next chart
                }
            </div>
        </div>
    );
};

export default PowerPageContainer;