import Localizer from "@/localization/Localizer";
import DeviceGridHelper from "@/helpers/DeviceGridHelper";

describe('Desktop - Fleet Monitoring - Filters tests - product group', () => {
    before(() => {
        cy.clearAllLocalStorage()
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});
    });

    it('should display product group search input', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', req => {
            console.log(req.body);
            if (req.body.productGroups?.length) {
                req.alias = 'postRequestFiltered';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetDevicesPagedListEmpty.json'});
        });

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', { fixture: 'constructionSiteInfos.json' });

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDropdown("filter-by-product-group-dropdown")
            .get('[data-cy="dropdownLabel"]')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsProductGroup);
        
        DeviceGridHelper.getDropdown("filter-by-product-group-dropdown").click();
        
        DeviceGridHelper.typeDropdownInput("filter-by-product-group-dropdown", "vaihtolava");

        DeviceGridHelper.getViewControlFilter(2)
            .find('#filter-by-product-group-dropdown [data-cy="dropdownList"]')
            .children('div')
            .eq(2)
            .click();

        DeviceGridHelper.checkDropdownValue("filter-by-product-group-dropdown", Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 1));
        
        DeviceGridHelper.checkTags(['Vaihtolava 25m (77667)']);
        
        cy.wait('@postRequestFiltered').then(({ request }) => {
            // Assert that the request body contains the changed value
            expect(request.body).to.have.nested.property('productGroups');
            expect(request.body.productGroups).to.have.length(1);
            expect(request.body.productGroups[0].id === '77667');
            expect(request.body.productGroups[0].name === 'Vaihtolava 25m (77667)');
        });
    });

    it('should set product group input with the value obtain from URL', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', req => {
            console.log(req.body);
            if (req.body.productGroups?.length) {
                req.alias = 'postRequestFiltered';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetDevicesPagedListEmpty.json'});
        });

        cy.visit(`/fleet-monitoring?productGroups=${JSON.stringify([{name:"vaihtolava"}])}`);

        DeviceGridHelper.checkTags([]);
        
        DeviceGridHelper.checkDropdownValue("filter-by-product-group-dropdown", Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 1));
        
        DeviceGridHelper.checkTags(['vaihtolava']);
        
        cy.wait('@postRequestFiltered').then(({ request }) => {
            console.log(request.body);
            expect(request.body).to.have.nested.property('productGroups');
            expect(request.body.productGroups).to.have.length(1);
            expect(request.body.productGroups[0].name === 'vaihtolava');
        });
    });
});