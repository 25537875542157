// Add export to fix compilation error TS1208

import {executeWithIntercept, pageData, RouteData} from "@/helpers/CypressHelper";
import Localizer from "@/localization/Localizer";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

export {};

describe('Desktop - Admin ProductDetails tests', () => {
    
    const saveProductResponseContainer = {
        "value": null,
        "context": null,
        "newVersionAvailable": false,
        "redirect": null,
        "error": null,
        "alert": {
            "message": "Generic.Save.Success",
            "messageParams": [],
            "alertType": 0,
            "dismissible": true,
            "autoClose": true,
            "autoCloseDelay": 5000,
            "flyout": true
        },
        "unauthorized": false,
        "isResponseContainer": true
    }
    
    before(() => {
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});

        cy.intercept('POST', 'api/Product/ProductData', {fixture: 'product'}).as('data');

        cy.intercept('POST', 'api/Product/GetProductAvailability', {fixture: 'productAvailability'}).as('data');

        cy.visit('tuote/mock-url');

        cy.get('#product_details_edit')
            .click();
    });

    it('Opens ProductDetails and tries to save an image exceeding 4MB in size', () => {
        cy.get('input[type=file]')
            .first()
            .selectFile({
                contents: 'cypress/fixtures/test_picture_large.png',
                fileName: 'test_picture_large.png',
                mimeType: 'data:image/png',
                lastModified: Date.now(),
            }, {force: true});

        cy.get('.athenaeum-alert-message');
    });

    it('Opens ProductDetails and tries to save small image and verifies image is rendered', () => {
        cy.get('input[type=file]')
            .first()
            .selectFile({
                contents: 'cypress/fixtures/test_picture_small.png',
                fileName: 'test_picture_small.png',
                mimeType: 'data:image/png',
                lastModified: Date.now(),
            }, {force: true});

            cy.get('[alt="test_picture_small.png"]');
    });

    it('Opens ProductDetails and tries to select/unselect product model images', () => {
        pageData().rent.product.firstProductModel().click();

        // selected image (initial state)
        verifyImageSelection(true);

        // unselect image
        pageData().rent.product.firstProductModelImage().click();
        verifyImageSelection(false);

        // select image again
        pageData().rent.product.firstProductModelImage().click();
        verifyImageSelection(true);
    });

    it('Opens ProductDetails and blocks the machine document upload when a document is not pdf', () => {
        pageData().rent.product.firstProductModel().click();

        pageData().rent.product.firstProductModelTechnicalDocumentationTab().click();

        pageData().rent.product.firstProductModelDocumentUploadInput()
            .selectFile({
                contents: 'cypress/fixtures/test_picture_large.png',
                fileName: 'test_picture_large.png',
                mimeType: 'data:image/png',
                lastModified: Date.now(),
            }, {force: true});

        pageData().rent.product.firstProductModelDocumentUploadButton().click();

        cy.get('.athenaeum-alert-message').first()
            .should('contain', Localizer.productDetailsExpectedFormatOfUploadedModelDocuments);
    });

    it('Opens ProductDetails and blocks the machine document upload when a document is too large', () => {
        pageData().rent.product.firstProductModel().click();

        pageData().rent.product.firstProductModelTechnicalDocumentationTab().click();

        pageData().rent.product.firstProductModelDocumentUploadInput()
            .selectFile({
                contents: 'cypress/fixtures/test_large_pdf_file.pdf',
                fileName: 'test_large_pdf_file.pdf',
                mimeType: 'application/pdf',
                lastModified: Date.now(),
            }, {force: true});

        pageData().rent.product.firstProductModelDocumentUploadButton().click();

        const expectedMessage = Localizer.get(Localizer.productDetailsExpectedSizeOfUploadedModelDocuments, RentaEasyConstants.maxMachineDocumentUploadSizeinBytes / (1024 * 1024));

        cy.get('.athenaeum-alert-message').first()
            .should('contain', expectedMessage);
    });
    
    it('Opens ProductDetails and hides the product', () => {
        // hide product
        pageData().rent.product.hideProductCheckbox().click();

        // verify if the hide product checkbox is checked
        pageData().rent.product.hideProductCheckbox()
            .find(" .athenaeum-checkbox-checkbox").should("have.class", "athenaeum-checkbox-checked");
        
        // intercept save product successful response
        cy.intercept(pageData().rent.routes.saveProduct, {statusCode: 200, body: saveProductResponseContainer});

        // verify if the success message is presented
        executeWithIntercept(() => {
                cy.get("[id^=product_details]").first().click();
                cy.get('.athenaeum-alert-message')
                    .should('contain', Localizer.genericSaveSuccess);
            },
            [pageData().rent.routes.saveProduct as RouteData]);
    });

    it('should keep edit mode when navigating to catalog from product', () => {

        cy.url().should('not.include', `editMode`);

        cy.clickBreadCrumb(2);

        cy.url().should('include', `editMode`);
    });
    
    function verifyImageSelection(expectedSelectionState: boolean) {
        pageData().rent.product.firstProductModelImage().invoke('attr', 'data-is-selected').should('eq', expectedSelectionState.toString());
    }
});