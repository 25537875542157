import DeviceGridHelper from "@/helpers/DeviceGridHelper";
import Localizer from "@/localization/Localizer";

describe('Desktop - Fleet Monitoring - Device grid navigation tests', () => {
    before(() => {
        cy.clearAllLocalStorage()
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

    });

    it('should open construction site page when click on the construction site name', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', {fixture: 'fleetGetDevicesPagedList.json'}).as('getDevicesPagedList');

        cy.intercept('POST', '/api/Companies/GetContractData', {fixture: 'getContractDataWithChildren.json'}).as('getContractData');
        
        cy.intercept('POST', '/api/Fleet/GetDeviceDetails', {fixture: 'getDeviceDetails.json'});

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridAngleIcon(3)
            .click();

        DeviceGridHelper.getDeviceGridExpandedContentByRow(3)
            .find('[data-cy="constructionSiteLink"]')
            .first()
            .click();

        cy.url().should('include', `${Localizer.pageRoutesConstructionSiteDetails}?id=9727f2e0-74ef-4267-b505-ceb4ed8f8591`);
    });

    it('should open company page when click on the company name', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', {fixture: 'fleetGetDevicesPagedList.json'});

        cy.intercept('POST', '/api/Companies/GetContractData', {fixture: 'getContractDataWithChildren.json'}).as('getContractData');

        cy.intercept('POST', '/api/Fleet/GetDeviceDetails', {fixture: 'getDeviceDetails.json'});

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridAngleIcon(3)
            .click();

        DeviceGridHelper.getDeviceGridExpandedContentByRow(3)
            .find('[data-cy="customerLink"]')
            .first()
            .click();

        cy.url().should('include', `${Localizer.pageRoutesContractDetails}?id=9410d0d2-a86e-43c5-85b4-7cebf3ba0562`);
    });

    it('should open device details page from product name when device details flag enabled', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', {fixture: 'fleetGetDevicesPagedList.json'});

        cy.intercept('POST', '/api/Product/HasAccessToProduct', {fixture: 'hasAccessToProductTrue.json'});

        cy.intercept('POST', '/api/Fleet/GetDeviceExtendedDetails', {fixture: 'getDeviceExtendedDetails.json'});

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridContentByRowAndCell(3, 0, 1)
            .trigger('click');

        cy.url().should('include', Localizer.pageRoutesDeviceDetailsPage);
        cy.url().should('include', `rentaId=90193`);
        cy.url().should('not.include', `serialNumber`);
        cy.url().should('not.include', `rentaGroupId`);
        cy.url().should('not.include', `rentaGroup`);
    });
});