import React, {useEffect, useState} from "react";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb";
import BreadCrumbItem from "@/models/BreadCrumbItem";
import {BreadCrumbHelper} from "@/helpers/BreadCrumbHelper";
import ProductModel from "@/models/server/ProductModel";

interface IProductBreadCrumbProps {
    pricingTool: boolean;
    currentProduct: ProductModel | null;
    editMode: boolean;
    isFavorites: boolean;
    isRentaFuture: boolean;

    onBreadCrumbClick(sender: BreadCrumbItem): Promise<void>;

    onBreadCrumbChange?(items: BreadCrumbItem[]): void;
}

export const ProductBreadCrumb = ({pricingTool, isFavorites, isRentaFuture, currentProduct, onBreadCrumbClick, onBreadCrumbChange, editMode}: IProductBreadCrumbProps) => {
    const [items, setItems] = useState<BreadCrumbItem[]>([]);

    useEffect(() => {
        const newItems = BreadCrumbHelper.convertProductToBreadCrumbItems(currentProduct, isFavorites, isRentaFuture, pricingTool);
        setItems(newItems);
        if (onBreadCrumbChange) {
            onBreadCrumbChange(newItems);
        }
    }, [currentProduct, isFavorites, isRentaFuture, pricingTool]);

    return (
        <BreadCrumb key={"BreadCrumbs"}
                    items={items}
                    editMode={editMode}
                    onItemClick={async (sender) => await onBreadCrumbClick(sender)}
        />
    );
};
