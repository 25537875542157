import { onRedirect, pageData } from "@/helpers/CypressHelper";
import Localizer from "@/localization/Localizer";

describe("Desktop - Authenticated construction site details tests", () => {

    const constructionSiteId = 'f7422461-9c46-416d-b500-7ab6ae1ee591';
    const message = 'This is some sample text.';

    beforeEach(() => {
        cy.session(["company_role_session"], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });
    });

    it("should block 'send summary' and 'scheduled emails' buttons once the email is invalid", () => {

        cy.intercept('POST', '/api/ConstructionSites/Equipment', { fixture: 'constructionSiteDetailsEquipmentList.json' });

        onRedirect(() => pageData().constructionSite.visit(constructionSiteId));

        pageData().constructionSite.equipment.tab().click();

        pageData().constructionSite.equipment.inputs.receiverEmail().clear();  // lack of email address
        pageData().constructionSite.equipment.buttons.sendSummary().should("be.disabled");
        pageData().constructionSite.equipment.buttons.scheduleEmails().should("be.disabled");

        pageData().constructionSite.equipment.inputs.receiverEmail().type("test.@renta.fi");   // invalid email address
        pageData().constructionSite.equipment.buttons.sendSummary().should("be.disabled");
        pageData().constructionSite.equipment.buttons.scheduleEmails().should("be.disabled");

        pageData().constructionSite.equipment.inputs.receiverEmail().clear().type("test@renta.fi");   // valid email address
        pageData().constructionSite.equipment.buttons.sendSummary().should("not.be.disabled");
        pageData().constructionSite.equipment.buttons.scheduleEmails().should("not.be.disabled");


        const equipmentItemsBeforeSearch = pageData().constructionSite.equipment.items();
        equipmentItemsBeforeSearch.should("have.length", 17);

        pageData().constructionSite.equipment.searchInput().type("ruokap");

        const equipmentItemsAfterSearch = pageData().constructionSite.equipment.items();
        equipmentItemsAfterSearch.should("have.length", 1);
    });

    it("should open and close service request modal", () => {

        cy.intercept('POST', '/api/ConstructionSites/Equipment', { fixture: 'constructionSiteDetailsEquipmentList.json' });

        onRedirect(() => pageData().constructionSite.visit(constructionSiteId));

        pageData().constructionSite.equipment.tab()
            .click();

        pageData().constructionSite.equipment.toggleIcon()
            .click();

        pageData().constructionSite.equipment.buttons.requestMaintenance()
            .click();

        cy.get("#request_maintenance_actions")
            .find("span")
            .eq(0)
            .should("contain", Localizer.enumServiceTypeRefuel)
            .click();

        cy.get('.modal-dialog')
            .should('be.visible');

        cy.contains('div', Localizer.serviceRequestConfirmationInfo)
            .should('be.visible');

        cy.contains('div', "Urho Kekkonen")
            .should('be.visible');

        pageData().constructionSite.equipment.buttons.closeServiceRequest()
            .click();

        cy.get('.modal-dialog')
            .should('not.be.visible');
    });

    it("should send 'refuel request'", () => {

        cy.intercept('POST', '/api/ConstructionSites/Equipment', { fixture: 'constructionSiteDetailsEquipmentList.json' });

        cy.intercept('POST', '/api/Services/CreateConstructionSiteServiceRequest', { fixture: 'createConstructionSiteServiceRequest.json' }).as('serviceRequest');

        onRedirect(() => pageData().constructionSite.visit(constructionSiteId));

        pageData().constructionSite.equipment.tab().click();

        pageData().constructionSite.equipment.toggleIcon()
            .click();

        pageData().constructionSite.equipment.buttons.requestMaintenance()
            .click();

        cy.get("#request_maintenance_actions")
            .find("span")
            .eq(0)
            .should("contain", Localizer.enumServiceTypeRefuel)
            .click();

        cy.contains('h2', Localizer.enumServiceTypeRefuel)
            .should('be.visible');

        cy.contains('div', Localizer.serviceRequestConfirmationInfo)
            .should('be.visible');

        cy.contains('div', "Urho Kekkonen")
            .should('be.visible');

        pageData().constructionSite.equipment.inputs.serviceRequestMessage()
            .type(message);

        pageData().constructionSite.equipment.buttons.sendServiceRequest()
            .click();

        cy.wait('@serviceRequest').then(({ request }) => {
            expect(request.body).to.have.property('message', message);
            expect(request.body).to.have.property('type', 0);
        });
    });

    it("should send 'maintenance request'", () => {

        cy.intercept('POST', '/api/ConstructionSites/Equipment', { fixture: 'constructionSiteDetailsEquipmentList.json' });

        cy.intercept('POST', '/api/Services/CreateConstructionSiteServiceRequest', { fixture: 'createConstructionSiteServiceRequest.json' }).as('serviceRequest');

        onRedirect(() => pageData().constructionSite.visit(constructionSiteId));

        pageData().constructionSite.equipment.tab().click();

        pageData().constructionSite.equipment.toggleIcon()
            .click();

        pageData().constructionSite.equipment.buttons.requestMaintenance()
            .click();

        cy.get("#request_maintenance_actions")
            .find("span")
            .eq(1)
            .should("contain", Localizer.enumServiceTypeMaintenance)
            .click();

        cy.contains('h2', Localizer.enumServiceTypeMaintenance)
            .should('be.visible');

        cy.contains('div', Localizer.serviceRequestConfirmationInfo)
            .should('be.visible');

        cy.contains('div', "Urho Kekkonen")
            .should('be.visible');

        pageData().constructionSite.equipment.inputs.serviceRequestMessage()
            .type(message);

        pageData().constructionSite.equipment.buttons.sendServiceRequest()
            .click();

        cy.wait('@serviceRequest').then(({ request }) => {
            expect(request.body).to.have.property('message', message);
            expect(request.body).to.have.property('type', 1);
        });
    });

    it("should send 'charging request'", () => {

        cy.intercept('POST', '/api/ConstructionSites/Equipment', { fixture: 'constructionSiteDetailsEquipmentList.json' });

        cy.intercept('POST', '/api/Services/CreateConstructionSiteServiceRequest', { fixture: 'createConstructionSiteServiceRequest.json' }).as('serviceRequest');

        onRedirect(() => pageData().constructionSite.visit(constructionSiteId));

        pageData().constructionSite.equipment.tab().click();

        pageData().constructionSite.equipment.toggleIcon()
            .click();

        pageData().constructionSite.equipment.buttons.requestMaintenance()
            .click();

        cy.get("#request_maintenance_actions")
            .find("span")
            .eq(2)
            .should("contain", Localizer.enumServiceTypeCharging)
            .click();

        cy.contains('h2', Localizer.enumServiceTypeCharging)
            .should('be.visible');

        cy.contains('div', Localizer.serviceRequestConfirmationInfo)
            .should('be.visible');

        cy.contains('div', "Urho Kekkonen")
            .should('be.visible');

        pageData().constructionSite.equipment.inputs.serviceRequestMessage()
            .type(message);

        pageData().constructionSite.equipment.buttons.sendServiceRequest()
            .click();

        cy.wait('@serviceRequest').then(({ request }) => {
            expect(request.body).to.have.property('message', message);
            expect(request.body).to.have.property('type', 2);
        });
    });

});