import { pageData } from "@/helpers/CypressHelper";
import DropdownHelper from "@/helpers/CypressDropdownHelper";
import DeviceGridHelper from "@/helpers/DeviceGridHelper";

describe('Desktop - Fleet Monitoring - Return requests pagination tests', () => {
    before(() => {
        cy.clearAllLocalStorage()
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

    });

    it('should change URL when fleet monitoring button is clicked and render empty grid with pagination', () => {

        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetDevicesPagedListEmpty.json' });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        DeviceGridHelper.getReturnRequestsGrid()
            .should('exist');

        DeviceGridHelper.getPagination()
            .children('div')
            .should('have.length', 2);

        DeviceGridHelper.getPaginationPageSelection()
            .children('div')
            .should('have.length', 3);

        DeviceGridHelper.getPaginationPageItem(0)
            .children('svg')
            .should('exist');

        DeviceGridHelper.getPaginationPageItem(1)
            .should('contain', 1);

        DeviceGridHelper.getPaginationPageItem(2)
            .children('svg')
            .should('exist');

        DeviceGridHelper.getPaginationItems()
            .children('div')
            .should('have.length', 2);

        DeviceGridHelper.getPaginationItems()
            .children('div')
            .eq(0)
            .find('[data-cy="dropdownInput"]')
            .should('exist');

        DeviceGridHelper.getPaginationItems()
            .children('div')
            .eq(0)
            .find('[data-cy="dropdownItem"]')
            .should('have.length', 3);

        DeviceGridHelper.getPaginationItems()
            .children('div')
            .eq(1)
            .should('contain', '0 / 0');
    });

    it('should render pagination correctly when there are devices', () => {

        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetReturnsPagedList.json' });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        DeviceGridHelper.getReturnRequestsGrid()
            .should('exist');

        DeviceGridHelper.getPagination()
            .children('div')
            .should('have.length', 2);

        DeviceGridHelper.getPaginationPageSelection()
            .children('div')
            .should('have.length', 6);

        DeviceGridHelper.getPaginationPageItem(0)
            .children('svg')
            .should('exist');

        DeviceGridHelper.getPaginationPageItem(1)
            .should('contain', 1)
            .should(($div) => {
                expect($div.attr('class')).to.include('active');
            });

        DeviceGridHelper.getPaginationPageItem(2)
            .should('contain', 2)
            .should(($div) => {
                expect($div.attr('class')).to.include('clickable');
            });

        DeviceGridHelper.getPaginationPageItem(3)
            .should('contain', 3);

        DeviceGridHelper.getPaginationPageItem(4)
            .should('contain', 4);

        DeviceGridHelper.getPaginationPageItem(5)
            .children('svg')
            .should('exist');

        DeviceGridHelper.getPaginationItems()
            .children('div')
            .should('have.length', 2);

        DeviceGridHelper.getPaginationItems()
            .children('div')
            .eq(0)
            .find('[data-cy="dropdownInput"]')
            .should('exist');

        DeviceGridHelper.getPaginationItems()
            .children('div')
            .eq(0)
            .find('[data-cy="dropdownItem"]')
            .should('have.length', 3);

        DeviceGridHelper.getPaginationItems()
            .children('div')
            .eq(1)
            .should('contain', '25 / 92');
    });

    it('should call API when the page size value is changed', () => {

        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', req => {
            if (req.body.pageSize > 25) {
                req.alias = 'postRequestChanged';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetReturnsPagedList.json'});
        });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        DeviceGridHelper.getReturnRequestsGrid()
            .should('exist');

        cy.wait('@postRequest').then(({ request }) => {
            // Assert the initial request body
            expect(request.body).to.have.property('pageSize', 25);
            expect(request.body).to.have.property('pageNumber', 1);

            // Select "50" option from the dropdown
            DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.pagination.dropdown(), 1);

            // Wait for the second intercepted POST request to the API endpoint
            cy.wait('@postRequestChanged').then(({ request }) => {
                // Assert that the request body contains the changed value
                expect(request.body).to.have.property('pageSize', 50);
                expect(request.body).to.have.property('pageNumber', 1);

                cy.url().should('include', `pageNumber=1&pageSize=50&sortBy=PickupTime&sortOrder=Asc`);

                DropdownHelper.getSelectedItem(pageData().fleetMonitoring.pagination.dropdown())
                    .should('contain.text', 50);
            });
        });

    });

    it('should call API when the page number is changed', () => {

        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', req => {
            if (req.body.pageNumber > 1) {
                req.alias = 'postRequestChanged';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetReturnsPagedList.json'});
        });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        pageData().common.waitTopNavRender();

        cy.wait('@postRequest').then(({ request }) => {
            // Assert the initial request body
            expect(request.body).to.have.property('pageSize', 25);
            expect(request.body).to.have.property('pageNumber', 1);

            DeviceGridHelper.getPaginationPageItem(3)
                .trigger('click');

            // Wait for the second intercepted POST request to the API endpoint
            cy.wait('@postRequestChanged').then(({ request }) => {
                // Assert that the request body contains the changed value
                expect(request.body).to.have.property('pageSize', 25);
                expect(request.body).to.have.property('pageNumber', 3);

                cy.url().should('include', `pageNumber=3&pageSize=25&sortBy=PickupTime&sortOrder=Asc`);

                DropdownHelper.getSelectedItem(pageData().fleetMonitoring.pagination.dropdown())
                    .should('contain.text', 25);
            });
        });

    });

    it('should set input fields with the default values correctly', () => {

        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetReturnsPagedList.json' }).as('postRequest');

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t&pageNumber=1&pageSize=100');

        DeviceGridHelper.getReturnRequestsGrid()
            .should('exist');

        cy.wait('@postRequest').then(() => {

            DeviceGridHelper.getPaginationPageItem(1)
                .should('contain', 1)
                .should(($div) => {
                    expect($div.attr('class')).to.include('active');
                });

            DropdownHelper.getSelectedItem(pageData().fleetMonitoring.pagination.dropdown())
                .should('contain.text', 100);

            cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t&pageNumber=1&pageSize=50&sortBy=Depot&sortOrder=Desc');

            cy.wait('@postRequest').then(() => {

                DeviceGridHelper.getPaginationPageItem(1)
                    .should('contain', 1)
                    .should(($div) => {
                        expect($div.attr('class')).to.include('active');
                    });

                DropdownHelper.getSelectedItem(pageData().fleetMonitoring.pagination.dropdown())
                    .should('contain.text', 50);
            });

        });

    });

    it('should remember user\'s previous selections when the user returns to fleet monitoring', () => {

        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', { fixture: 'fleetGetReturnsPagedList.json' }).as('postRequest');

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', { fixture: 'constructionSiteInfos.json' });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        pageData().common.waitTopNavRender();
        
        DeviceGridHelper.getReturnRequestsGrid()
            .should('exist');

        // Select "50" option from the dropdown
        DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.pagination.dropdown(), 1);

        // Double check the options are set
        DropdownHelper.getSelectedItem(pageData().fleetMonitoring.pagination.dropdown())
            .should('contain.text', 50);

        // Leave the page
        cy.visit('/rent');

        // Go back to the page
        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        // Options are remembered
        DropdownHelper.getSelectedItem(pageData().fleetMonitoring.pagination.dropdown())
            .should('contain.text', 50);

    });

});