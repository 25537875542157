import {pageData} from "@/helpers/CypressHelper";
import Localizer from "@/localization/Localizer";
import {Method} from "cypress/types/net-stubbing";
import DeviceGridHelper from "@/helpers/DeviceGridHelper";

describe('Desktop - Fleet Monitoring - Filters modal tests - admin user', () => {
    before(() => {
        cy.clearAllLocalStorage()
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});
    });

    it('should not display open modal button on wide screens', () => {
        const {routes: {getDevicesPagedList}, modals: {filters: filtersModal}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/fleet-monitoring');

        filtersModal.openModalButton().should('not.be.visible');
    });

    it('should display open modal button on narrow screens', () => {
        cy.viewport(575, 800);
        const {routes: {getDevicesPagedList}, modals: {filters: filtersModal}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/fleet-monitoring');

        filtersModal.openModalButton().should('exist');
    });

    it('should open filters modal button and fill all controls', () => {
        cy.viewport(575, 800);
        const {routes: {getDevicesPagedList}, modals: {filters: filtersModal}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit(`/fleet-monitoring?constructionSites=${JSON.stringify([{"name":"TESTITILAUS RENTA EASY", "id":"f7422461-9c46-416d-b500-7ab6ae1ee591"}])}&companies=${JSON.stringify([{"name":"TMI%20JUKKA%20WILSKA%20(29276)", "id":"31eaa6c0-42fa-40c2-a891-bcd16c367409"}])}&productGroups=${JSON.stringify([{"name":"test"}])}&deviceNames=["test"]&pageNumber=1&pageSize=25&sortBy=BatteryLevel&sortOrder=Asc`);

        filtersModal.openModalButton().click();
        
        filtersModal.modal().should('be.visible');

        filtersModal.filters.deviceNameFilter.input()
            .should('have.attr', 'placeholder', Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 1));
        
        DeviceGridHelper.checkDropdownValue('filters-modal_filter-by-product-group-dropdown', Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 1));
        
        DeviceGridHelper.checkDropdownValue('filters-modal_filter-by-company-dropdown', Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 1));
        
        DeviceGridHelper.checkDropdownValue('filters-modal_filter-by-construction-site-dropdown', Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 1));
        
        filtersModal.filters.sortByFilter.title()
            .should('have.text', Localizer.fleetMonitoringPageFiltersSortByBattery);

        filtersModal.filters.sortOrderFilter.title()
            .should('have.text', Localizer.enumSortDirectionAsc);
    });

    it('should get devices count when changing filters value, then close modal and reload devices when button clicked', () => {
        const {routes: {getDevicesPagedList, getDevicesCount}, modals: {filters: filtersModal}} = pageData().fleetMonitoring;
        cy.viewport(575, 800);
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, req => {
            if (req.body.deviceNames?.[0] === 'test') {
                req.alias = 'getDevicesPagedListFiltered';
            } else {
                req.alias = 'getDevicesPagedList';
            }
            req.reply({fixture: 'fleetGetDevicesPagedList.json'});
        });

        cy.intercept(getDevicesCount.method as Method, getDevicesCount.path, { fixture: 'fleetGetDevicesCount.json' }).as('getDevicesCount');

        cy.visit('/fleet-monitoring');

        filtersModal.openModalButton().click();

        filtersModal.modal().should('be.visible');

        filtersModal.filters.deviceNameFilter.input()
            .type('test').blur();

        cy.wait('@getDevicesCount').then(({ request }) => {
            // Assert that the request body contains the changed value
            expect(request.body).to.have.property('deviceNames');
            expect(request.body.deviceNames).to.have.length(1);
            expect(request.body.deviceNames[0] === 'test');

            filtersModal.showResultsButton()
                .children('span').eq(0)
                .should('contain.text', Localizer.fleetMonitoringPageSearchAndFilterModalShowResults)
                .should('contain.text', '(5)');

            filtersModal.showResultsButton().click();

            cy.wait('@getDevicesPagedListFiltered').then(({ request: request2 }) => {
                // Assert that the request body contains the changed value
                expect(request2.body).to.have.property('deviceNames');
                expect(request2.body.deviceNames).to.have.length(1);
                expect(request2.body.deviceNames[0] === 'test');

                filtersModal.modal().should('not.exist');
            });
        });
    });
});