import React from "react";
import {Button, ButtonType, Dropdown, OneColumn, PageContainer, PageHeader, SelectListItem, TwoColumns} from "@renta-apps/athenaeum-react-components";
import {OrderStatus, RequestState} from "@/models/Enums";
import ReturnDetailResponse from "@/models/server/Responses/ReturnDetailResponse";
import EnumProvider from "@/providers/EnumProvider";
import ProductCard, {ProductCardStyle} from "@/components/ProductCard/ProductCard";
import Localizer from "@/localization/Localizer";

import styles from './AdminReturnDetails.module.scss';
import ReturnDetailModel from "@/models/server/ReturnDetailModel";
import {AlertModel, BasePageParameters} from "@renta-apps/athenaeum-react-common";
import ReturnProductModel from "@/models/server/ReturnProductModel";
import UserModel from "@/models/server/UserModel";
import {ConstructionSiteModel} from "@/models/server/ConstructionSiteModel";
import {OrganizationContractModel} from "@/models/server/OrganizationContractModel";
import PriceHelper from "@/helpers/PriceHelper";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import DepotModel from "@/models/server/DepotModel";
import RentaPage from "@/models/base/RentaPage";
import RentaEasyController from "@/pages/RentaEasyController";

interface IProductCount {
    id: string,
    count: number
}

export interface IAdminReturnDetailsParams extends BasePageParameters {
}

interface IAdminReturnDetailsState {
    returnDetail: ReturnDetailModel | null;
    filterText: string;
    unAvailableProductIds: string[];
    unavailableProductsLoaded: RequestState;
    editMode: boolean;
    updatedProductCounts: IProductCount[];
    selectedOrderStatus: OrderStatus | null;
    depotNames: string[] | null;
}

export default class AdminReturnDetails extends RentaPage<IAdminReturnDetailsParams, IAdminReturnDetailsState> {

    public state: IAdminReturnDetailsState = {
        returnDetail: null,
        filterText: '',
        unAvailableProductIds: [],
        unavailableProductsLoaded: RequestState.NotSending,
        editMode: false,
        updatedProductCounts: [],
        selectedOrderStatus: null,
        depotNames: null,
    };

    private get selectedOrderQuery(): string | null {
        return this.routeId;
    }

    private get selectedReturn(): ReturnDetailModel | null {
        return this.state.returnDetail;
    }

    private get returnUser(): UserModel {
        return this.selectedReturn!.user ?? new UserModel();
    }

    private get constructionSite(): ConstructionSiteModel {
        return this.selectedReturn!.constructionSite ?? new ConstructionSiteModel();
    }

    private get contract(): OrganizationContractModel {
        return this.selectedReturn!.contract ?? new OrganizationContractModel();
    }

    private get depot(): DepotModel {
        return this.selectedReturn!.depot ?? new DepotModel();
    }

    private get depotNamesList(): string[] | null {
        return this.state.depotNames;
    }

    private get contractNumbers(): string {
        let contractNumbers = "";

        this.selectedReturn?.products.forEach((product) => {
            if (product.contractNumber) { //Return made before this change was added will have null as contractNumber
                contractNumbers += (contractNumbers === "") ? `${product.contractNumber}` : `, ${product.contractNumber}`;
            }
        });

        return contractNumbers;
    }

    private getDepotExternalIds(detail: ReturnDetailModel): number[] {
        let depotExternalIds: number[] = [];

        detail.products.forEach((product) => {
            if (product.depotExternalId) { //Return made before this change was added will have null as depotExternalId
                depotExternalIds.push(product.depotExternalId);
            }
        });

        return depotExternalIds.distinct(id => id);
    }

    private async getDepotNames(ids: number[]): Promise<string[]> {
        const depots = await RentaEasyController.getRentalOfficesAsync(this);
        let depotNames: string[] = [];

        depots.forEach((depot) => {
            const match = ids.some(id => id === depot.externalId);

            if (depot.name && match) {
                depotNames.push(depot.name);
            }
        });

        return depotNames;
    }

    private get editMode(): boolean {
        return (this.isAdmin && this.state.editMode);
    }

    private async fetchReturnDetailsAsync(): Promise<ReturnDetailResponse | null> {
        const orderId: string | null = this.selectedOrderQuery;

        if (!orderId) {
            return null;
        }

        return await this.postAsync(`/api/Product/ReturnDetails`, orderId);
    }

    private async setEditMode(): Promise<void> {
        await this.setState({editMode: !this.state.editMode, updatedProductCounts: []});
    }

    private async setSelectedOrderStatus(item: SelectListItem) {

        await this.setState({selectedOrderStatus: item?.value as unknown as OrderStatus ?? null});

    }

    private async handleSave(): Promise<void> {
        const request = {
            returnId: this.selectedReturn?.id,
            selectedState: this.state.selectedOrderStatus ?? this.selectedReturn?.status,
            updatedProductCounts: this.state.updatedProductCounts ?? null
        };

        const response: AlertModel = await this.postAsync("/api/Product/UpdateReturn", request);

        await this.initializeAsync(response);
    }

    private async handleProductCountChange(id: string, newCount: number) {

        const updatedCountIndex = this.state.updatedProductCounts.findIndex((product) => product.id === id);

        if (updatedCountIndex < 0) {
            const updatedCount: IProductCount = {id, count: newCount};

            await this.setState(({updatedProductCounts: this.state.updatedProductCounts.concat(updatedCount)}));

            return;
        }

        const copyOfState: IProductCount[] = Object.assign([], [...this.state.updatedProductCounts]);

        copyOfState[updatedCountIndex].count = newCount;

        await this.setState({updatedProductCounts: copyOfState});
    }

    private getVisibleCount(product: ReturnProductModel): number {

        const item = this.state.updatedProductCounts.find(item => item.id === product.product?.id);

        return item?.count ?? product.count;

    }

    public async fetchDataAsync(): Promise<ReturnDetailResponse | null> {
        return await this.fetchReturnDetailsAsync();
    }

    public async initializeAsync(alert?: AlertModel): Promise<void> {

        await super.initializeAsync();

        const response: ReturnDetailResponse | null = await this.fetchDataAsync();
        const depotExternalIds = (response?.returnDetail) ? this.getDepotExternalIds(response?.returnDetail) : null;
        const depotNames = (depotExternalIds) ? await this.getDepotNames(depotExternalIds) : null;

        await this.setState({
            returnDetail: response?.returnDetail ?? null,
            editMode: false,
            depotNames
        });

        if (alert) {
            this.alertAsync(alert);
        }
    }

    protected get title(): string {
        return Localizer.breadCrumbReturnDetails;
    }

    public renderDetail(name: string, value: string): JSX.Element {
        return (
            <div>
                <span>{name}</span>
                <span>{value}</span>
            </div>
        );
    }

    public renderEasyProducts(): JSX.Element | null {

        const items = this.selectedReturn?.products.filter(item => (item.product));

        if (!items || items.length < 1) {
            return null;
        }

        return (
            <OneColumn>
                <h3>Easy {Localizer.orderProductsText}:</h3>
                {

                    (
                        <div className={this.css(styles.productCardContainer)}>
                            {
                                (items) &&
                                items.map((returnItem, index) => {
                                        return ((returnItem.product) &&
                                            <div key={returnItem.product.id + `-${index}`}
                                                 className={this.css(styles.cardContainer)}
                                            >
                                                {(returnItem.contractNumber) &&
                                                    (
                                                        <small>
                                                            {`(${Localizer.genericContractNumber}: ${returnItem.contractNumber})`}
                                                        </small>
                                                    )
                                                }
                                                <ProductCard readonly={!this.editMode}
                                                             displayAddToCartButton={this.editMode}
                                                             className={styles.productCard}
                                                             style={ProductCardStyle.TwoColumns}
                                                             product={returnItem.product!}
                                                             count={this.getVisibleCount(returnItem)}
                                                             vat={PriceHelper.environmentVat}
                                                             onUpdateProductCount={async (productId, newCount) => {
                                                                 await this.handleProductCountChange(productId, newCount);
                                                             }}
                                                />
                                            </div>);

                                    }
                                )
                            }
                        </div>
                    )
                }

            </OneColumn>
        );
    }

    public renderNonEasyProducts(): JSX.Element | null {

        const items = this.selectedReturn?.products.filter(item => (!item.product));

        if (!items || items.length < 1) {
            return null;
        }

        return (
            <OneColumn>
                <h3>{Localizer.enumServiceTypeCustom}:</h3>
                <div className={this.css(styles.externalProductCardContainer)}>
                    {
                        items.map((returnItem, index) => {
                            return (
                                <div key={returnItem.contractNumber + `-${index}`}
                                     className={styles.innerContainer}
                                >
                                    {(returnItem.contractNumber) &&
                                        (
                                            <small>
                                                {`(${Localizer.genericContractNumber}: ${returnItem.contractNumber})`}
                                            </small>
                                        )
                                    }
                                    <div key={returnItem.externalId + `-${index}`}
                                         className={this.css(styles.box)}
                                    >
                                        <div className={styles.itemboxContent}>

                                            <h3>{returnItem.externalId}</h3>

                                            <p>{returnItem.count} {Localizer.orderAmountText}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </OneColumn>

        );
    }

    public renderSelectedOrderDetails(): JSX.Element | undefined {
        if (!this.selectedReturn) {
            return;
        }

        const orderDate: string = (this.selectedReturn)
            ? Utility.format("{0:D}", this.selectedReturn.orderDate)
            : '-';

        const startTime: string = (this.selectedReturn.startTime)
            ? Utility.format("{0:D}", this.selectedReturn.startTime)
            : "-";

        const endTime: string = (this.selectedReturn.endTime)
            ? Utility.format("{0:D}", this.selectedReturn.endTime)
            : "-";

        return (
            <div>
                <TwoColumns>

                    <div>
                        <h3>{Localizer.breadCrumbReturnDetails}</h3>

                        {
                            (!this.editMode) &&
                            (
                                <div>
                                    <b>{Localizer.orderStatusText}</b>

                                    <span className={this.css(styles.statusText,
                                        (this.selectedReturn.status === OrderStatus.Rejected)
                                            ? styles.rejected
                                            : (this.selectedReturn.status === OrderStatus.Delivered)
                                                ? styles.delivered
                                                : styles.inProgress)}> {EnumProvider.getOrderStatusText(this.selectedReturn.status)}
                                    </span>
                                </div>
                            )
                        }
                        {

                            (this.editMode) &&

                            (

                                <Dropdown id="changeState"
                                          label={Localizer.orderStatusText}
                                          nothingSelectedText={Localizer.orderDetailsChangeState}
                                          selectedItem={this.selectedReturn.status}
                                          items={EnumProvider.getOrderStatusItems()}
                                          onChange={async (_, value) => {
                                              await this.setSelectedOrderStatus(value as SelectListItem);
                                          }}
                                />
                            )
                        }

                        <div>
                            <b>{Localizer.orderClientText}</b>
                            <span> {this.contract.name}</span>
                        </div>

                        <div>
                            <b>{Localizer.orderPersonText}</b>
                            <span> {this.returnUser.firstName} {this.returnUser.lastName}</span>
                        </div>

                        <div>
                            <b>{Localizer.orderDoneText}</b>
                            <span> {orderDate}</span>
                        </div>

                        <div>
                            <b>{Localizer.adminReturndetailsContractNumber}:</b>
                            <span> {this.contractNumbers}</span>
                        </div>

                        {
                            (this.depotNamesList) &&
                            (
                                <div>
                                    <b>{Localizer.officesText}:</b>
                                    <span> {this.depotNamesList.join(", ")}</span>
                                </div>
                            )
                        }

                    </div>

                    <div className={this.css(styles.shippingAndCustomerInfo)}>
                        <h3>{Localizer.orderShippingAndCustomerInfo}</h3>

                        <div>
                            <b>{Localizer.orderSiteNameText}</b>
                            <span> {this.constructionSite.displayName}</span>
                        </div>

                        <div>
                            <b>{Localizer.orderDeliveryMethodText}</b>
                            <span> {this.selectedReturn.deliveryMethod}</span>
                        </div>

                        <br/>

                        <div>
                            <b>{Localizer.orderStartTimeText}</b>
                            <span> {startTime}</span>
                        </div>

                        <div>
                            <b>{Localizer.orderEndTimeText}</b>
                            <span> {endTime}</span>
                        </div>

                        <br/>

                        <div>
                            <b>{Localizer.orderLocationText}</b>
                            <span> {this.depot.name}</span>
                        </div>

                        <div>
                            <b>{Localizer.orderAddressText}</b>
                            <span> {this.selectedReturn.address}</span>
                        </div>

                        <div>
                            <b>{Localizer.orderCityText}</b>
                            <span> {this.selectedReturn.city}</span>
                        </div>

                        <div>
                            <b>{Localizer.orderPostalCodeText}</b>
                            <span> {this.selectedReturn.postalCode}</span>
                        </div>

                        <br/>

                        <div>
                            <b>{Localizer.orderFirstNameText}</b>
                            <span> {this.returnUser.firstName} {this.returnUser.lastName}</span>
                        </div>

                        <div>
                            <b>{Localizer.orderPhoneNumberText}</b>
                            <a href={`tel:${this.selectedReturn.contactPhone}`}> {this.selectedReturn.contactPhone}</a>
                        </div>

                        <div>
                            <b>{Localizer.orderEmailText}</b>
                            <a href={`mailto:${this.selectedReturn.contactEmail}`}> {this.selectedReturn.contactEmail}</a>
                        </div>
                    </div>
                </TwoColumns>

            </div>
        );
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer className={styles.adminReturnDetails}>
                <PageHeader title={Localizer.adminPageRentaAdminManager}
                            subtitle={this.title}
                />

                {
                    (this.isAdmin) &&
                    (
                        <Button label={(this.state.editMode ? Localizer.formCancel : Localizer.catalogEditProductEditButton)}
                                onClick={async () => await this.setEditMode()}
                        />
                    )
                }
                {
                    (this.editMode) &&
                    (
                        <Button type={ButtonType.Orange}
                                label={Localizer.genericSave}
                                onClick={async () => {
                                    await this.handleSave();
                                }}
                        />
                    )
                }
                {
                    this.renderSelectedOrderDetails()
                }
                {
                    this.renderEasyProducts()
                }

                {
                    this.renderNonEasyProducts()
                }
            </PageContainer>
        );
    }

}