import React, {useState, useContext, useMemo} from "react";
import styles from "../DeviceDetails.module.scss";

import {ch} from "@renta-apps/athenaeum-react-common";
import {Link} from "@renta-apps/athenaeum-react-components";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {Box, ButtonSize, ButtonType, Icon, LoadingDecorator, Text, SimpleTable, SimpleTableRowData} from "@renta-apps/renta-react-components";
import Localizer from "@/localization/Localizer";
import DeviceDetailsContext from "@/pages/DeviceDetailsPage/DeviceDetailsContext";
import EasyPlusFeatureButton from "@/components/EasyPlus/EasyPlusFeatureButton/EasyPlusFeatureButton";
import SubscribeToAlertsModal from "@/pages/FleetMonitoring/Modals/SubscribeToAlertsModal";
import {IDeletedAlarm, INewAlarm} from "@/pages/FleetMonitoring/FleetMonitoringContainer";
import AlarmModel from "@/models/server/AlarmModel";
import UserContext from "@/models/server/UserContext";
import {translateAlertType} from "@/services/AlarmService";
import EasyPlusLink from "@/components/EasyPlus/EasyPlusLink/EasyPlusLink";

interface IDeviceDetailsAlertsProps {
    isLoading: boolean;
}

const DeviceDetailsAlerts: React.FC<IDeviceDetailsAlertsProps> = (props: IDeviceDetailsAlertsProps) => {
    const context = useContext(DeviceDetailsContext);

    const [isSubscribeToAlertsModalOpen, setIsSubscribeToAlertsModalOpen] = useState<boolean>(false);

    const handleAlertsSettingsClicked = () => {
        setIsSubscribeToAlertsModalOpen(true);
    };

    const tableHeaders = [
        {
            key: "alarmType", label: Localizer.genericDescription, render: (rowData: SimpleTableRowData) => {
                return translateAlertType(rowData.alarmType);
            }
        },
        {
            key: "latestOccurrence", label: Localizer.genericTime, render: (rowData: SimpleTableRowData) => {
                return Utility.formatValue(Utility.restoreDate(rowData.latestOccurrence), "dt");
            }
        },
    ];

    const data = useMemo(() => {
        return context.deviceDetails?.configuredAlerts.flatMap(alarm =>
            (alarm.occurrences.length > 0
                    ? alarm.occurrences.map<AlarmModel>(item => ({
                        ...alarm,
                        occurrences: [item],
                        latestOccurrence: item,
                    }))
                    : [{
                        ...alarm,
                        occurrences: [],
                        latestOccurrence: null,
                    }]
            )
        ).sort((a, b) => {
            // Sort by latestOccurrence, with null values last
            if (a.latestOccurrence === null) return 1;
            if (b.latestOccurrence === null) return -1;
            return new Date(b.latestOccurrence).getTime() - new Date(a.latestOccurrence).getTime();
        }).slice(0, 4);
    }, [context.deviceDetails?.configuredAlerts]);

    const easyPlusEnabled = useMemo(() => {
        return (ch.getContext() as UserContext)?.isEasyPlusUser ?? false;
    }, []);

    return (
        <div className={`${styles.deviceDetailsAlerts} ${styles.moduleWrapper}`}>
            <Box display="flex" flex-direction="row" justifyContent="space-between">
                <Text tag="h3" transform="uppercase" className={styles.moduleTitle}>
                    {Localizer.deviceDetailsAlertsTitle}
                </Text>
                {context.deviceDetails?.supportsAlerts && easyPlusEnabled &&
                    <EasyPlusFeatureButton onClick={() => handleAlertsSettingsClicked()} size={ButtonSize.Small} type={ButtonType.Icon} id={"alertsSettingsButton"}>
                        <Icon name="gear" />
                    </EasyPlusFeatureButton>
                }
            </Box>

            <Box className={styles.moduleContainer}>
                <LoadingDecorator className={styles.moduleEmptyContainer} hideConentOnLoading={true} isLoading={props.isLoading}>
                {easyPlusEnabled ? (
                    <Box className={styles.moduleContentContainer} display="flex" flexDirection="column" data-cy={"configuredAlerts"}>
                        {context.deviceDetails?.configuredAlerts.length ? (
                            <SimpleTable
                                headers={tableHeaders}
                                data={data}
                                rowGridClassName={styles.rowGrid}
                                noDataText={Localizer.genericNoData}
                            />
                        ) : (
                            <Box p={16}>
                                <Text tag="span" weight="bold">{Localizer.deviceDetailsAlertsNoAlerts}</Text>
                            </Box>
                        )}
                    </Box>
                ): (
                    <Box className={`${styles.moduleEmptyContainer} ${styles.moduleContentContainerHalfScreenWidth}`} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <span data-cy={"alertsRequireEasyPlus"}>{Localizer.deviceDetailsAlertsRequireEasyPlus}</span>
                        <EasyPlusLink>{Localizer.easyPlusInfoPanelReadMore}</EasyPlusLink>
                    </Box>
                )}
                </LoadingDecorator>
            </Box>

            <SubscribeToAlertsModal
                selectedDevices={context?.deviceDetails ? [context.deviceDetails] : []}
                saveNewAlarms={(newAlarms: INewAlarm[]) => context.saveNewAlarms(newAlarms)}
                removeDeletedAlarms={(deletedAlarms: IDeletedAlarm[]) => context.removeDeletedAlarms(deletedAlarms)}
                isOpen={isSubscribeToAlertsModalOpen}
                onClose={() => setIsSubscribeToAlertsModalOpen(false)}
                alertsConfig={context.alertsConfig}
            />
        </div>
    );
};
export default DeviceDetailsAlerts;