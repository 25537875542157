import React, {useEffect, useState} from "react";
import {ButtonType, Modal, ModalSize} from "@renta-apps/athenaeum-react-components";
import {FleetMonitoringFilters, ViewControlProps} from "@/pages/FleetMonitoring/ViewControl/ViewControl";
import FiltersForm from "@/pages/FleetMonitoring/FiltersForm/FiltersForm";
import {getDevicesCountAsync} from "@/services/FleetService";
import Localizer from "@/localization/Localizer";
import styles from "./FiltersModal.module.scss";
import ButtonWithSpinner from "@/components/ButtonWithSpinner/ButtonWithSpinner";
import isEqual from "lodash.isequal";
import SelectedFilter from "@/models/server/Requests/SelectedFilter";

interface FiltersModalProps extends ViewControlProps {
    isOpen: boolean;
    onClose(): void;
}

const FiltersModal: React.FC<FiltersModalProps> = ({
    isOpen,
    onClose,
    filters,
    onFilterAndSort,
    userRoleIsAdmin,
    userRoleContractId,
    userRoleConstructionSiteId,
    sortByItems,
    userCompaniesList,
}: FiltersModalProps) => {
    const _modalRef: React.RefObject<Modal> = React.createRef();

    const [internalFilters, setInternalFilters] = useState(filters);
    const [resultsCount, setResultsCount] = useState<number | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const [loadTimeout, setLoadTimeout] = useState<any>(null);

    useEffect(() => {
        if (isOpen) {
            _modalRef.current?.openAsync();
        } else {
            _modalRef.current?.closeAsync();
        }
    }, [_modalRef, isOpen]);

    useEffect(() => {
        setInternalFilters(filters);
    }, [filters]);

    useEffect(() => {
        if (!isOpen) {
            return;
        }
        
        debounceLoadCount(1000);
    }, [internalFilters, isOpen]);

    /**
     * Debounce mechanism for getting count to prevent multiple requests in case of quick changes in filtering or page settings
     * @param time - time in milliseconds to wait before submitting the request
     */
    const debounceLoadCount = (time: number) => {
        loadTimeout && clearTimeout(loadTimeout);
        
        setIsLoading(true);

        const newTimeout = setTimeout(() => {
            
            setLoadTimeout(null);
            internalFilters && getResultsCount(internalFilters!).catch();
        }, time);
        setLoadTimeout(newTimeout);

        setIsLoading(false);
    };
    
    const getResultsCount = async (filters: FleetMonitoringFilters) => {
        setIsLoading(true);
        try {
            // Admin can filter devices from every organization and construction site. Other users have a specific role, so their search is narrowed.
            let constructionSiteId = (userRoleIsAdmin || !userRoleConstructionSiteId) ? undefined : userRoleConstructionSiteId;
            let contractId = (userRoleIsAdmin || !userRoleContractId) ? undefined : userRoleContractId;

            const count = await getDevicesCountAsync(
                contractId,
                constructionSiteId,
                filters.deviceNames,
                filters.companies,
                filters.constructionSites,
                filters.depots,
                filters.productGroups,
                filters.statuses,
            );

            setResultsCount(count);
        } catch (error) {
            console.error('Error loading devices:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFiltersChange = (filters: FleetMonitoringFilters) => {
        if (isEqual(filters, internalFilters)) {
            return;
        }

        setInternalFilters(filters);
    };

    const handleButtonClick = () => {
        onFilterAndSort(internalFilters);
        handleClose();
    };

    const handleClose = () => {
        setResultsCount(undefined);
        onClose();
    };

    return isOpen ? (
        <Modal id="filters-modal"
               ref={_modalRef}
               preventEsc preventClosingOnOutsideClick preventClosingOnInsideClick
               size={ModalSize.Large}
               title={Localizer.fleetMonitoringPageSearchAndFilterModalTitle}
               className={styles.modal}
               onClose={async () => handleClose()}
        >
            <FiltersForm filters={internalFilters}
                         userRoleConstructionSiteId={userRoleConstructionSiteId}
                         userRoleContractId={userRoleContractId}
                         userRoleIsAdmin={userRoleIsAdmin}
                         onFilterAndSort={handleFiltersChange}
                         sortByItems={sortByItems}
                         idPrefix="filters-modal_"
                         vertical
                         userCompaniesList={userCompaniesList}
            />
            <ButtonWithSpinner className={styles.showResultsButton}
                               id={"show-results-button"}
                               spinning={isLoading}
                               onClick={async () => handleButtonClick()}
                               type={ButtonType.Orange}
                               label={`${Localizer.fleetMonitoringPageSearchAndFilterModalShowResults}${resultsCount !== undefined ? ` (${resultsCount})` : ""}`}
                               block
            />
        </Modal>
    ) : null;
};

export default FiltersModal;