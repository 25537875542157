import InvitationsGridToolbarModel from "./InvitationsGridToolbarModel";
import React from "react";
import {Checkbox, InlineType, JustifyContent, TextInput, ToolbarContainer, ToolbarRow} from "@renta-apps/athenaeum-react-components";
import styles from "./InvitationsGridToolbar.module.scss";
import Localizer from "@/localization/Localizer";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";

interface IInvitationsGridToolbarProps {
    model: InvitationsGridToolbarModel;

    onChange?(model: InvitationsGridToolbarModel): Promise<void>;
}

interface IInvitationsGridToolbarState {
    model: InvitationsGridToolbarModel;
}

export default class InvitationsGridToolbar extends BaseComponent<IInvitationsGridToolbarProps, IInvitationsGridToolbarState> {

    public state: IInvitationsGridToolbarState = {
        model: this.props.model
    };

    private timeout: number = 0;

    private get model(): InvitationsGridToolbarModel {
        return this.state.model;
    }

    public async initializeAsync(): Promise<void> {
    }

    private async processChangesWithTimerAsync(): Promise<void> {

        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = window.setTimeout(
            async () => {
                await this.props.onChange?.(this.model);
            },
            420);
    }

    private async setKeyWordAsync(keyword: string): Promise<void> {

        this.model.keyword = keyword;

        await this.processChangesWithTimerAsync();
    }

    private async setInvitationAsync(checked: boolean): Promise<void> {

        this.model.invitationSent = checked;

        await this.processChangesWithTimerAsync();
    }

    private async setLinkOpenAsync(checked: boolean): Promise<void> {

        this.model.linkOpened = checked;

        await this.processChangesWithTimerAsync();
    }

    private async setRegisteredAsync(checked: boolean): Promise<void> {

        this.model.registered = checked;

        await this.processChangesWithTimerAsync();
    }

    public render(): React.ReactNode {

        return (
            <ToolbarContainer className={styles.toolbarContainer}>
                <ToolbarRow justify={JustifyContent.Start} className={styles.toolBarRow}>
                    <TextInput inline
                               label={Localizer.ordersPageSearch}
                               width={"200px"}
                               value={this.model.keyword}
                               onChange={async (sender, value) => await this.setKeyWordAsync(value)}
                    />

                    <Checkbox inline inlineType={InlineType.Right}
                              id="invitations_invitation_sent"
                              label={Localizer.invitationsGridInvitationSent}
                              value={this.model.invitationSent}
                              onChange={async (_, checked) => await this.setInvitationAsync(checked)}
                    />

                    <Checkbox inline inlineType={InlineType.Right}
                              id="invitations_link_opened"
                              label={Localizer.invitationsGridLinkOpened}
                              value={this.model.linkOpened}
                              onChange={async (_, checked) => await this.setLinkOpenAsync(checked)}
                    />

                    <Checkbox inline inlineType={InlineType.Right}
                              id="invitations_registered"
                              label={Localizer.invitationsGridRegistered}
                              value={this.model.registered}
                              onChange={async (_, checked) => await this.setRegisteredAsync(checked)}
                    />
                </ToolbarRow>
            </ToolbarContainer>
        );
    }
}