import React, {useEffect, useMemo, useRef, useState} from "react";
import {ApplicationContext, ch} from "@renta-apps/athenaeum-react-common";
import {IGoogleApiSettings, Link} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import GoogleMapsLink from "@/components/GoogleMapsLink/GoogleMapsLink";
import PageDefinitions from "@/providers/PageDefinitions";
import styles from './ConstructionSiteDetailsHeader.module.scss';
import {ConstructionSiteModel} from "@/models/server/ConstructionSiteModel";
import queryString from "query-string";
import {capitalizeWords} from "@/helpers/StringHelper";
import {useResize} from "@/helpers/Hooks";
import UserContext from "@/models/server/UserContext";

interface ConstructionSiteDetailsHeaderProps {
    constructionSite: ConstructionSiteModel | null;
}

const ConstructionSiteDetailsHeader: React.FC<ConstructionSiteDetailsHeaderProps> = ({constructionSite}) => {
    const overlayRef = useRef<HTMLDivElement>(null);
    const [imageLeft, setImageLeft] = useState(0);
    const [imageWidth, setImageWidth] = useState(0);
    const [mapMarkerLeft, setMapMarkerLeft] = useState(0);
    const {windowWidth} = useResize();

    useEffect(() => {
        if (!overlayRef?.current) {
            return;
        }

        // the map image is positioned absolutely, and always starts at the same point as the overlay
        // which is positioned in a grid, so we can take its left offset
        const gridGap = 16;
        const gridColumnWidth = (overlayRef.current.offsetWidth - gridGap) / 2; // overlay is always 2 columns wide
        const gridLeft = overlayRef.current.parentElement!.offsetLeft + 20; // 20 - paddingLeft
        // the marker should be either at the center of column 10 or between columns 10 and 11 depending on the screen width
        const mapMarkerLeft = gridLeft + ((windowWidth < 768 || windowWidth >= 1200)
            ? (9.5 * gridColumnWidth) + (9 * gridGap)
            : (10 * gridColumnWidth) + (9.5 * gridGap));

        const imageOffsetLeft = overlayRef.current.offsetLeft;
        setImageLeft(imageOffsetLeft);
        setImageWidth(windowWidth - imageOffsetLeft);
        setMapMarkerLeft(mapMarkerLeft);
    }, [windowWidth, overlayRef]);

    const {name, additionalReference} = useMemo(() => {
        const externalReference = constructionSite?.externalReference ? capitalizeWords(constructionSite.externalReference) : null;

        return constructionSite?.name ? {
            name: capitalizeWords(constructionSite.name),
            additionalReference: externalReference,
        } : {
            name: externalReference,
        };
    }, [constructionSite]);

    const companyInfo = useMemo(() => {
        if (!constructionSite) {
            return '-';
        }

        const {ownerName, customerNumber, ownerAdditionalName} = constructionSite;
        const additionalData = [customerNumber, ownerAdditionalName].filter(Boolean).join(', ');
        if (!ownerName && !additionalData) {
            return '-';
        }

        const companyData = ownerName ? `${ownerName} ${additionalData ? `(${additionalData})` : ''}` : additionalData;

        return capitalizeWords(companyData);
    }, [constructionSite]);

    const googleStaticApiUrl = (lat: number, lng: number, width: number): string => {
        const context: ApplicationContext = ch.getContext();
        const settings = context.settings as IGoogleApiSettings;

        if ((!settings.googleMapApiKey) || (!settings.googleMapApiUrl)) {
            return '';
        }

        // we need to move the map center a little to the right so the marker is in the desired position
        const halfWidth = Math.ceil(width / 2);
        const imageCenterPx = halfWidth + imageLeft;
        // 0.000043 is a magic number that seemed to work best during testing
        const pxToLng = 0.000043;
        const moveCenter = (imageCenterPx - mapMarkerLeft) * pxToLng;

        const params = {
            key: settings.googleMapApiKey,
            scale: 2,
            // we use scale = 2 to get a higher resolution image, so the returned image is twice as big as the requested size
            size: `${halfWidth}x180`,
            format: "PNG",
            maptype: "roadmap",
            center: `${lat},${(lng + moveCenter).toFixed(7)}`,
            markers: `size:tiny|${lat},${lng}`,
            zoom: 14,
            language: Localizer.language || "en",
            map_id: '101cb4629f437a9a',
        };

        return `${settings.googleMapApiUrl}api/staticmap?${queryString.stringify(params)}`;
    };

    const showDebugInfo = useMemo(() => {
        return (ch.getContext() as UserContext)?.user?.showDebugInfo ?? false;
    }, []);

    const staticMapUrl: string = useMemo(() => {
        if (!constructionSite?.location || !imageWidth || !imageLeft || windowWidth < 576) {
            return '';
        }

        const {lat, lon} = constructionSite.location;
        if (!lat || !lon) {
            return '';
        }

        return googleStaticApiUrl(lat, lon, imageWidth);
    }, [constructionSite, imageLeft, imageWidth]);

    return (
        <div className={styles.headerContainer}>
            <div className={styles.headerGrid}>
                <div className={styles.headerLeft}>
                    <div className={styles.headerTitle}>{Localizer.genericConstructionSite}</div>
                    <div>
                        <div className={styles.constructionSiteName} data-cy="construction-site-name">
                            {name || Localizer.fleetMonitoringPageFiltersNoConstructionSiteName}
                        </div>
                        {additionalReference && (
                            <div className={styles.additionalReference} data-cy="construction-site-additional-reference">
                                {additionalReference}
                            </div>
                        )}
                        <div className={styles.constructionSiteInfo}>
                            {
                                showDebugInfo && (
                                    <div className={styles.infoRow}>
                                        <span className={styles.infoLabel}>{Localizer.constructionSiteDetailsHeaderId}:</span>
                                        <span data-cy="construction-site-external-id">{constructionSite?.externalId}</span>
                                    </div>
                                )
                            }
                            <div className={styles.infoRow}>
                                <span className={styles.infoLabel}>{Localizer.genericCompany}:</span>
                                {(companyInfo !== '-' && constructionSite?.ownerId) ? (
                                    <Link route={PageDefinitions.contractDetails.route({params: {id: constructionSite.ownerId}})}
                                          className={styles.link}
                                    >
                                        {companyInfo}
                                    </Link>
                                ) : (
                                    <span data-cy="construction-site-company">{companyInfo}</span>
                                )}
                            </div>
                            <div className={styles.infoRow}>
                                <span className={styles.infoLabel}>{Localizer.constructionSiteDetailsHeaderLocation}:</span>
                                <GoogleMapsLink location={constructionSite?.location ?? null} className={styles.link}></GoogleMapsLink>
                            </div>
                        </div>
                    </div>
                </div>
                {/* we render the elements when the page is opened, before we get the construction site, for all the calculation purposes */}
                {(!constructionSite || staticMapUrl) && (
                    <>
                        <div className={styles.headerMap} style={{left: imageLeft}}>
                            {staticMapUrl && <img src={staticMapUrl} alt="Map"/>}
                        </div>
                        <div ref={overlayRef} className={styles.headerMapOverlay}></div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ConstructionSiteDetailsHeader;