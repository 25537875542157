import Chainable = Cypress.Chainable;
import Localizer from "@/localization/Localizer";

export default class DeviceGridHelper {

    /**
     * @returns The Device Grid.
     */
    public static getDeviceGrid(): Chainable<JQuery<HTMLElement>> {
        return cy.get('[data-cy="fleet-monitoring-grid"]');
    }

    /**
     *
     * @param row is a row where content is expected.
     * @returns The Grid row of the Device Grid.
     */
    public static getDeviceGridExpandedContentByRow(row: number): Chainable<JQuery<HTMLElement>> {
        return cy.get('[data-cy="fleet-monitoring-grid"]')
            .children('div')
            .eq(row)
            .children('div')
            .eq(1);
    }

    /**
     *
     * @param row is a row where content is expected.
     * @param item is a column where content is expected.
     * @param child is a sub item of the item where content is expected.
     * @returns The Grid Cell of the Device Grid.
     */
    public static getDeviceGridContentByRowAndCell(row: number, item: number, child?: number): Chainable<JQuery<HTMLElement>> {
        let element = cy.get('[data-cy="fleet-monitoring-grid"]')
            .children('div')
            .eq(row);
        
        if (row > 0) {
            element = element.children('div').eq(0);
        }
        
        element = element.children('div').eq(item);
        
        return child !== undefined ? element.children('div').eq(child) : element;
    }

    public static getDeviceGridMeasurementValue(row: number, type: 'alerts' | 'idle' | 'battery' | 'fluid'): Chainable<JQuery<HTMLElement>> {
        const cell = type === 'alerts' ? 2 : type === 'idle' ? 3 : type === 'battery' ? 4 : 5;
        const element = DeviceGridHelper.getDeviceGridContentByRowAndCell(row, cell, 1);
        
        return element.children('div').eq(0).children('div').eq(0);
    }

    /**
     *
     * @param row is a row where the expandable icon is expected.
     * @returns The angle icon of the Device Grid.
     */
    public static getDeviceGridAngleIcon(row: number): Chainable<JQuery<HTMLElement>> {
        return cy.get('[data-cy="fleet-monitoring-grid"]')
            .children('div')
            .eq(row)
            .find('i.fas.fa-angle-down')
            .eq(0);
    }

    public static getDeviceGridRowDetails(row: number): Chainable<JQuery<HTMLElement>> {
        return cy.get('[data-cy="fleet-monitoring-grid"]')
            .children('div')
            .eq(row)
            .find('[data-cy="row-details"]');
    }

    /**
     *
     * @param row is a row where the checkbox is expected.
     * @returns The checkbox of the Device Grid.
     */
    public static getDeviceGridCheckbox(row: number): Chainable<JQuery<HTMLElement>> {
        if (row === 0) {
            return cy.get('[data-cy="fleet-monitoring-grid"]')
                .children('div')
                .eq(row)
                .children('div')
                .eq(0)
                .children('div')
                .eq(0)
                .children('div')
                .eq(0);
        }

        return cy.get('[data-cy="fleet-monitoring-grid"]')
            .children('div')
            .eq(row)
            .children('div')
            .eq(0)
            .children('div')
            .eq(0)
            .children('div')
            .eq(0)
            .children('div')
            .eq(0);
    }

    /**
     *
     * @param row is a row where the icon is expected.
     * @returns The gear icon of the Device Grid.
     */
    public static getDeviceGridGearIcon(row: number): Chainable<JQuery<HTMLElement>> {
        return cy.get('[data-cy="fleet-monitoring-grid"]')
            .children('div')
            .eq(row)
            .children('div')
            .eq(1)
            .find('.gearButton')
    }

    /**
     *
     * @param row is a row where the icon is expected.
     * @returns The return icon of the Device Grid.
     */
    public static getDeviceReturnIcon(row: number): Chainable<JQuery<HTMLElement>> {
        return cy.get('[data-cy="fleet-monitoring-grid"]')
            .children('div')
            .eq(row)
            .children('div')
            .eq(1)
            .find('.returnButton')
    }

    /**
     * @returns The Device Bottom Sheet.
     */
    public static getDeviceBottomSheet(): Chainable<JQuery<HTMLElement>> {
        return cy.get('#deviceBottomSheet');
    }

    public static openSubscribeToAlertsModalFromDeviceBottomSheet(): void {
        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(1)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        cy.wait(10);
    }

    /**
     * @returns The Pagination.
     */
    public static getPagination(): Chainable<JQuery<HTMLElement>> {
        return cy.get('#container')
            .children('div')
            .eq(1);
    }

    /**
     * @returns The navigation boxes.
     */
    public static getPaginationPageSelection(): Chainable<JQuery<HTMLElement>> {
        return cy.get('#container')
            .children('div')
            .eq(1)
            .children('div')
            .eq(0);
    }

    /**
     * Return the box of pagination.
     *
     * @param item
     * @returns
     */
    public static getPaginationPageItem(item: number): Chainable<JQuery<HTMLElement>> {
        return cy.get('#container')
            .children('div')
            .eq(1)
            .children('div')
            .eq(0)
            .children('div')
            .eq(item);
    }

    /**
     * @returns The dropdown and the counter
     */
    public static getPaginationItems(): Chainable<JQuery<HTMLElement>> {
        return cy.get('#container')
            .children('div')
            .eq(1)
            .children('div')
            .eq(1);
    }

    /**
     * @returns The Return Requests Grid.
     */
    public static getReturnRequestsGrid(): Chainable<JQuery<HTMLElement>> {
        return cy.get('[data-cy="return-request-grid"]');
    }

    /**
     *
     * @param row is a row where the expandable icon is expected.
     * @returns The angle icon of the Return Requests Grid.
     */
    public static getReturnRequestsGridAngleIcon(row: number): Chainable<JQuery<HTMLElement>> {
        return cy.get('[data-cy="return-request-grid"]')
            .children('div')
            .eq(row)
            .find('i.fas.fa-angle-down')
            .eq(0);
    }

    /**
     *
     * @param row is a row where content is expected.
     * @param item is a column where content is expected.
     * @param child is a sub item of the item where content is expected.
     * @returns The Grid Cell of the Return Requests Grid.
     */
    public static getReturnRequestsGridContentByRowAndCell(row: number, item: number, child?: number): Chainable<JQuery<HTMLElement>> {
        let element = cy.get('[data-cy="return-request-grid"]')
            .children('div')
            .eq(row);

        if (row > 0) {
            element = element.children('div').eq(0);
        }

        element = element.children('div').eq(item);

        return child !== undefined ? element.children('div').eq(child) : element;
    }

    /**
     *
     * @param row is a row where content is expected.
     * @returns The Grid row of the Return Requests Grid.
     */
    public static getReturnRequestsGridExpandedContentByRow(row: number): Chainable<JQuery<HTMLElement>> {
        return cy.get('[data-cy="return-request-grid"]')
            .children('div')
            .eq(row)
            .children('div')
            .eq(1);
    }

    /**
     * @returns The View Control.
     */
    public static getViewControl(): Chainable<JQuery<HTMLElement>> {
        return cy.get('#container')
            .children('form')
            .eq(0)
            .children('div')
            .eq(0);
    }

    /**
     *
     * @param item is a filter.
     * @returns The div element with a filter input.
     */
    public static getViewControlFilter(item: number): Chainable<JQuery<HTMLElement>> {
        return this.getViewControl()
            .children('div')
            .filter(':visible')
            .should('have.length.greaterThan', 0)
            .eq(item);
    }

    public static getDropdown(id: string): Chainable<JQuery<HTMLElement>> {
        return cy.get(`#${id} [data-cy="dropdownInput"]`).should('exist');
    }
    
    public static getDropdownInput(id: string): Chainable<JQuery<HTMLElement>> {
        return cy.get(`#${id} [data-cy="dropdownInput"]`).should('exist');
    }

    public static checkDropdownValue(id: string, value: string): void {
        cy.get(`#${id} [data-cy="dropdownInput"]`).should('contain.text', value);
    }
    
    public static checkDropdownInputValue(id: string, value: string): void {
        cy.get(`#${id} [data-cy="dropdownInput"]`).find("input").should('have.value', value);
    }
    
    public static typeDropdownInput(id: string, value: string): void {
        cy.get(`#${id} [data-cy="dropdownInput"]`).click();
        cy.get(`#${id} [data-cy="dropdownInput"]`).type(value);
    }
    
    public static getDropdownList(id: string): Chainable<JQuery<HTMLElement>> {
        return cy.get(`#${id} [data-cy="dropdownList"]`);
    }

    public static checkDropdownList(id: string, options: string[]): void {
        for (let i = 0; i < options.length; i++) {
            cy.get(`#${id} [data-cy="dropdownList"]`).should('contain.text', options[i]);
        }
    }

    public static clickDropdownListItem(id: string, itemIndex: number): void {
        cy.get(`#${id} [data-cy="dropdownList"]`).children('div').eq(itemIndex).click();
    }
    
    public static checkTags(tags: string[]): void {
        cy.get('#tags [data-cy="tagItem"]').should('have.length', tags.length);
        
        for (let i = 0; i < tags.length; i++) {
            cy.get('#tags [data-cy="tagItem"]').eq(i).should('contain.text', tags[i]);
        }
    }

    public static deleteTag(index: number): void {
        cy.get('#tags [data-cy="tagItem"]').eq(index).click();
    }
}