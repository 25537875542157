import React, {KeyboardEvent, useEffect, useRef, useState} from "react";
import styles from "@/pages/FleetMonitoring/Modals/RequestReturnModal/RequestReturnModal.module.scss";
import {Input} from "@renta-apps/renta-react-components";

type EditAmountOverlayProps = {
    amount: number;
    maxAmount: number;
    position: {top: number, left: number};
    onClose(amount: number): void;
}

export const EditAmountOverlay: React.FC<EditAmountOverlayProps> = ({amount, maxAmount, onClose, position}) => {
    const minAmount = 1;
    const [value, setValue] = useState<number | null>(amount);
    const overlayRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [value]);

    const handleClickOutside = (event: MouseEvent) => {
        if (overlayRef.current && !overlayRef.current.contains(event.target as Node)) {
            handleClose(value);
        }
    };

    const handleClose = (x: number | null) => {
        onClose(x === null || isNaN(x) ? amount : x);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Enter') {
            handleClose(value);
        } else if (event.code === 'Escape') {
            handleClose(amount);
        }
    };

    const handleOnChange = (value: string) => {
        if (value === '') {
            setValue(null);
            return;
        }

        const intValue = parseInt(value);
        if (intValue < minAmount) {
            setValue(minAmount);
        } else if (intValue > maxAmount) {
            setValue(maxAmount);
        } else {
            setValue(intValue);
        }
    };

    return (
        <div data-cy="return-modal-amount-overlay" className={styles.amountOverlay} style={{...position}} ref={overlayRef}>
            <Input type="number"
                   value={value ?? ''}
                   onChange={handleOnChange}
                   inputProperties={{
                       min: minAmount,
                       max: maxAmount,
                       autoFocus: true,
                       onKeyDown: handleKeyDown,
                   }}
            />
            / {maxAmount}
        </div>
    );
};